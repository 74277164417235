import CustomField from '~/models/customData/CustomField';

import { unique } from '~/utils/array';

export const getCustomFieldIds = (articles, customData) => {
  const customFieldIds = [];

  const entries = customData.getEntries();

  for (const entry of entries) {
    const customDataKey = entry[0];
    const customFieldId = CustomField.getIdFromKey(customDataKey);

    if (!customFieldId) {
      continue;
    }

    customFieldIds.push(customFieldId);
  }

  for (const article of articles) {
    const articleCustomData = article.customData;
    const articleEntries = articleCustomData.getEntries();

    for (const articleEntry of articleEntries) {
      const customDataKey = articleEntry[0];
      const customFieldId = CustomField.getIdFromKey(customDataKey);

      if (!customFieldId) {
        continue;
      }

      customFieldIds.push(customFieldId);
    }
  }

  return unique(customFieldIds);
};
