import camelcaseKeys from 'camelcase-keys';
import ky from 'ky';
import snakecaseKeys from 'snakecase-keys';

import { apiUrl, sgwUrl } from '~/constants/environment';

import AuthService from './auth.service';

const trailingSlashRegex = /\/+$/;
const startsWithUuidRegex = /^[\da-f]{8}(?:-[\da-f]{4}){3}-[\da-f]{12}/;

/**
 * Converts the keys of an object to camelCase. Keeps object keys that start with a UUID as they are.
 * @param data - The object to convert.
 * @returns The converted object with camelCase keys.
 */
export const camelcaseKeysFromApi = (data: any) =>
  camelcaseKeys(data, {
    deep: true,
    exclude: [startsWithUuidRegex], // exclude custom fields from conversion, which use `UUID_field_name` as key
  });

/**
 * Creates a ky instance with a base URL based on the provided API path and environment.
 * The instance is configured to
 * - include credentials in requests
 * - refresh the access token on 401 (unauthorized) responses
 * - remove trailing slashes from URLs
 * - convert request bodies to snake_case
 * - convert response data to camelCase (if case converter is enabled)
 * - disable default timeouts and retries as those are handled by react-query
 */
const createKyInstance = ({
  baseUrl = apiUrl,
  withCaseConverter = false,
  useIdToken = false,
}: {
  baseUrl?: string;
  withCaseConverter?: boolean;
  useIdToken?: boolean;
}) => {
  const kyInstance = ky.create({
    // Disable retries (managed by react-query)
    hooks: {
      afterResponse: [
        async (request, _options, response) => {
          if (response.status === 401) {
            try {
              // Attempt to refresh the token
              const newTokens = await AuthService.refreshTokens();
              localStorage.setItem('accessToken', newTokens.accessToken);

              // Retry the original request with the new token
              const retryHeaders = new Headers(request.headers);
              retryHeaders.set(
                'Authorization',
                `Bearer ${newTokens.accessToken}`,
              );
              const retryRequest = new Request(request, {
                headers: retryHeaders,
              });
              const retryResponse = await ky(retryRequest);

              return retryResponse;
            } catch (refreshError) {
              console.error('Token refresh error:', refreshError);

              throw response; // Re-throw the error to propagate the 401 error if refresh fails.
            }
          }

          // Convert the response data to camelCase
          if (
            withCaseConverter &&
            response.headers.get('content-type')?.includes('application/json')
          ) {
            const responseBody = await response.json();
            const camelCasedBody = camelcaseKeysFromApi(responseBody);

            return new Response(JSON.stringify(camelCasedBody), {
              headers: response.headers,
              status: response.status,
              statusText: response.statusText,
            });
          }

          return response;
        },
      ],
      beforeError: [
        (error) => {
          console.error('Ky error:', error);

          throw error; // Re-throw the error to propagate it to the calling function and handle it there.
        },
      ],
      beforeRequest: [
        (request, options) => {
          // Add Authorization header with the appropriate token.
          const headers = new Headers(request.headers);
          const token = useIdToken
            ? AuthService.getIdToken()
            : AuthService.getAccessToken();
          headers.set('Authorization', `Bearer ${token}`);

          // Remove trailing slashes from the URL, because the VESTIGAS API breaks at requests with trailing slashes.
          const url = request.url.replace(trailingSlashRegex, '');

          // Convert the request body to snake_case
          let { body } = options;
          if (withCaseConverter && body && typeof body === 'string') {
            const jsonBody = JSON.parse(body);

            // Convert the request body to snake_case; skip the conversion if the body is an array of strings or numbers, b/c snakecaseKeys cannot handle that.
            body = JSON.stringify(
              Array.isArray(jsonBody) &&
                jsonBody.every(
                  (item) =>
                    typeof item === 'string' || typeof item === 'number',
                )
                ? jsonBody
                : snakecaseKeys(jsonBody, { deep: true }),
            );
          }

          // Create a new request with the modified URL, headers, and body
          return new Request(url, {
            ...options,
            body,
            headers,
          });
        },
      ],
    },

    prefixUrl: baseUrl,

    // Disable timeouts (managed by react-query)
    retry: 0,
    timeout: false,
  });

  return kyInstance;
};

/**
 * ky instance for working with the VESTIGAS API in the current environment.
 */
export const vestigasApi = createKyInstance({
  baseUrl: apiUrl,
  withCaseConverter: true,
});

/**
 * ky instance for working with the VESTIGAS sync gateway in the current environment.
 */
export const vestigasSyncGateway = createKyInstance({
  baseUrl: sgwUrl,
  useIdToken: true,
  withCaseConverter: true,
});
