import Article from '~/models/articles/Article';

export const getArticles = (assetMain) => {
  if (!assetMain) {
    return [];
  }

  const articles = [];

  if (assetMain?.assetState?.body?.transaction?.logisticsPackage)
    for (const [
      index,
      logisticsPackage,
    ] of assetMain?.assetState?.body?.transaction?.logisticsPackage.entries()) {
      if (logisticsPackage?.lineItem)
        for (const [
          indexLineItem,
          lineItem,
        ] of logisticsPackage?.lineItem.entries()) {
          if (!lineItem) {
            continue;
          }

          const article = new Article(
            assetMain?.assetState?.body?.transaction?.logisticsPackage[
              index
            ]?.lineItem[indexLineItem],
            logisticsPackage.id,
            index,
            indexLineItem,
            assetMain.acceptStates?.acceptItems,
            assetMain.billedItems,
          );

          articles.push(article);
        }
    }

  return articles;
};
