import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import { sortByKey } from '~/utils/array';

const costCenters = createSlice({
  initialState: {
    costCenters: [],
    costCentersLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'costCenters',
  reducers: {
    replaceCostCenter(state, parameter) {
      const { payload } = parameter;

      const newCostCenters = state.costCenters.filter(
        ({ id }) => id !== payload.id,
      );
      newCostCenters.push(payload);

      state.costCenters = newCostCenters;
    },
    replaceCostCenters(state, parameter) {
      const { payload } = parameter;

      state.costCenters = sortByKey(payload, 'name');
      state.costCentersLoading = LOADING_STATE.SUCCEEDED;
    },
    setCostCentersLoading(state, parameter) {
      const { payload } = parameter;

      state.costCentersLoading = payload;
    },
  },
});

const { actions, reducer } = costCenters;
export const { replaceCostCenter, replaceCostCenters, setCostCentersLoading } =
  actions;
export default reducer;
