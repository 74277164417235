import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/logging';

import Company from '../masterdata/Company';

export default class Concrete {
  constructor(concreteId, concrete) {
    this.concreteId = concreteId ?? '';
    this.type = concrete.type ?? '';
    this.exposureClass = concrete.exposure_class ?? '';
    this.moistureClass = concrete.moisture_class ?? '';
    this.strengthClass = concrete.strength_class ?? '';
    this.consistency = concrete.consistency ?? '';
    this.largestGrain = concrete.largest_grain ?? '';
    this.strengthDevelopment = concrete.strength_development ?? '';
    this.usage = concrete.usage ?? '';
    this.chlorideClass = concrete.chloride_class ?? '';
    this.waterCementRatio = concrete.water_cement_ratio ?? null;

    this.components = this.getComponents(concrete.components);
    this.posteriorAdded = this.getPosteriorAdded(concrete.posterior_added);
  }

  getComponents(components) {
    const newComponents = [];

    if (components)
      for (const component of components) {
        const manufacturer = new Company(
          component.manufacturer?.legal_organization,
        );

        newComponents.push({
          comment: component.comment,
          manufacturer,
          name: component.name,
          quantity: {
            unit: component.quantity?.measure,
            value: component.quantity?.value,
          },
          type: component.type,
          volumeRequested: {
            unit: component.requested?.measure,
            value: component.requested?.value,
          },
        });
      }

    return newComponents;
  }

  getPosteriorAdded(posteriorAdded) {
    const newPosteriorAdded = [];

    if (posteriorAdded)
      for (const item of posteriorAdded) {
        const manufacturer = new Company(item.manufacturer?.legal_organization);

        newPosteriorAdded.push({
          comment: item.comment,
          manufacturer,
          name: item.name,
          quantity: {
            unit: item.quantity?.measure,
            value: item.quantity?.value,
          },
          reason: item.reason,
          remainingConcrete: {
            unit: item.remaining_concrete?.measure,
            value: item.remaining_concrete?.value,
          },
          result: item.result,
          type: item.type,
        });
      }

    return newPosteriorAdded;
  }

  // source: https://www.betontechnische-daten.de/de/10-8-1-ueberwachungsklassen-für-beton
  isMonitoringConcrete() {
    // if the strength class is at least C30/37, then the concrete must be monitored
    if (
      Concrete.STRENGTH_CLASSES.indexOf(this.strengthClass) >=
      Concrete.STRENGTH_CLASSES.indexOf(
        Concrete.MONITORING_CLASS_2.MINIMUM_STRENGTH_CLASS,
      )
    ) {
      return true;
    }

    // also some exposure classes must be monitored
    for (const monitoredExposureClass of Concrete.MONITORING_CLASS_2
      .EXPOSURE_CLASSES) {
      if (this.exposureClass.includes(monitoredExposureClass)) {
        true;
        continue;
      }
    }

    return false;
  }

  static getDescriptiveStrengthDevelopment(strengthDevelopment) {
    if (!strengthDevelopment) {
      return null;
    }

    const strengthDevelopmentConst = Object.keys(
      Concrete.STRENGTH_DEVELOPMENT,
    ).find(
      (x) =>
        Concrete.STRENGTH_DEVELOPMENT[x].STANDARDISED === strengthDevelopment,
    );

    if (!strengthDevelopmentConst) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid strength development. strengthDevelopment: ' +
            strengthDevelopment,
        ),
      );
      return strengthDevelopment;
    }

    return Concrete.STRENGTH_DEVELOPMENT[strengthDevelopmentConst].DESCRIPTIVE;
  }

  static STRENGTH_CLASSES = [
    'C8/10',
    'C12/15',
    'C16/20',
    'C20/25',
    'C25/30',
    'C30/37',
    'C35/45',
    'C40/50',
    'C45/55',
    'C50/60',
    'C55/67',
    'C60/75',
    'C70/85',
    'C80/95',
    'C90/105',
    'C100/115',
  ];
  static LARGEST_GRAINS = ['1', '2', '4', '8', '16', '22', '32'];
  static MONITORING_CLASS_2 = {
    EXPOSURE_CLASSES: ['XS', 'XD', 'XA', 'XM', 'XF2', 'XF3', 'XF4'],
    MINIMUM_STRENGTH_CLASS: 'C30/37',
  };
  static STRENGTH_DEVELOPMENT = {
    FAST: {
      DESCRIPTIVE: 'schnell',
      STANDARDISED: 'fast',
    },
    MIDDLE: {
      DESCRIPTIVE: 'mittel',
      STANDARDISED: 'middle',
    },
    SLOW: {
      DESCRIPTIVE: 'langsam',
      STANDARDISED: 'slow',
    },
  };
}
