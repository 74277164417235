import { isObject } from 'lodash-es';

import { unique } from '~/utils/array';

import Value from './Value';

export default class ValueGroup {
  constructor() {
    this.current = null;
    this.initial = null;
    this.history = [];
  }

  latestValueEquals(plainValue) {
    if (this.history.length > 0) {
      if (
        JSON.stringify(this.history.at(-1).value) === JSON.stringify(plainValue)
      ) {
        return true;
      }
    } else if (
      this.initial &&
      JSON.stringify(this.initial.value) === JSON.stringify(plainValue)
    ) {
      return true;
    }

    return false;
  }

  static isValueGroup(value) {
    return (
      isObject(value) &&
      value.hasOwnProperty('current') &&
      value.hasOwnProperty('initial') &&
      value.hasOwnProperty('history')
    );
  }

  static getCurrentValue(value) {
    if (ValueGroup.isValueGroup(value)) {
      return value.current?.value;
    }

    return value;
  }

  static applyFunction(valueGroups, callback) {
    const returnValueGroup = new ValueGroup();

    // determine current value
    const current = {};
    for (const key of Object.keys(valueGroups)) {
      current[key] = ValueGroup.getCurrentValue(valueGroups[key]);
    }

    returnValueGroup.current = new Value(callback(current), null);

    // determine initial value
    const initial = {};
    let datetime;
    let company;
    for (const key of Object.keys(valueGroups)) {
      // Initial value might be undefined if plain value and not ValueGroup has been passed. In this case, take current value as fallback.
      initial[key] = valueGroups[key]?.initial?.value ?? current[key];
      datetime = valueGroups[key]?.initial?.datetime;
      company = valueGroups[key]?.initial?.company;
    }

    returnValueGroup.initial = new Value(callback(initial), datetime);

    // determine history
    let historyDatetimes = [];
    for (const key of Object.keys(valueGroups)) {
      if (valueGroups[key]?.history)
        for (const value of valueGroups[key]?.history) {
          historyDatetimes.push(value.datetime);
        }
    }

    historyDatetimes = unique(historyDatetimes);
    historyDatetimes.sort();

    for (const historyDatetime of historyDatetimes) {
      let company;

      for (const key of Object.keys(valueGroups)) {
        const updatedValue = valueGroups[key]?.history?.find(
          ({ datetime }) => datetime === historyDatetime,
        );
        if (updatedValue) {
          initial[key] = updatedValue.value;
          company = updatedValue.company;
        }
      }

      returnValueGroup.history.push(
        new Value(callback(initial), historyDatetime, company),
      );
    }

    return returnValueGroup;
  }

  static hasBeenEdited(object, excludedPaths, currentPath) {
    if (currentPath === null) {
      currentPath = [];
    }

    for (const excludedPath of excludedPaths) {
      if (JSON.stringify(excludedPath) === JSON.stringify(currentPath)) {
        return false;
      }
    }

    let hasBeenEdited = false;

    if (!isObject(object)) {
      return false;
    }

    if (ValueGroup.isValueGroup(object)) {
      return object.history?.length > 0;
    }

    for (const key of Object.keys(object)) {
      if (
        ValueGroup.hasBeenEdited(object[key], excludedPaths, [
          ...currentPath,
          key,
        ])
      ) {
        hasBeenEdited = true;
      }
    }

    return hasBeenEdited;
  }

  static removeValueGroups(input) {
    if (ValueGroup.isValueGroup(input)) {
      return ValueGroup.getCurrentValue(input);
    }

    if (isObject(input)) {
      for (const key in input) {
        input[key] = ValueGroup.removeValueGroups(input[key]);
      }

      return input;
    }

    if (Array.isArray(input)) {
      return input.map((item) => ValueGroup.removeValueGroups(item));
    }

    return input;
  }
}
