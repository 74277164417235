import { type UncefactUnitType, UNITS } from '~/constants/units';

export const MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED = 100;
export const MAXIMUM_NUMBER_OF_LEGEND_ITEMS = 6;

export const selectableUnits = Object.entries(UNITS)
  .filter(([key]) =>
    [
      'EA',
      'TNE',
      'MTR',
      'MTK',
      'SA',
      'RO',
      'PF',
      'PA',
      'BJ',
      'BG',
      'PK',
      'TU',
      'CA',
      'CI',
      'PR',
      'CT',
      'E48',
      'MTQ',
      'LTR',
    ].includes(key),
  )
  .map(([key, details]) => ({
    value: key as UncefactUnitType,
    label: details.labelLong,
  }));
