import {
  Add as AddIcon,
  Check as CheckIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import { cloneDeep } from 'lodash-es';
import { type UUID } from 'node:crypto';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import FeatureService from '~/services/feature.service';

import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterNew from '~/models/filters/FilterNew';

import Log from '~/utils/logging';
import { isObjectContentEqual } from '~/utils/object';

import { AccordionBody } from '~/components/baseComponents/accordion/AccordionBody';
import BasicForm from '~/components/BasicForm';
import { emptyDeliveryNoteFilterGroup } from '~/components/deliveries/deliveryOverview/DeliveryFilterGroups/utils';
import { ClientPortalTooltip } from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionTooltip';
import { withErrorBoundary } from '~/ui/atoms';

import { FilterGroupsList } from './FilterGroupsList';
import { FilterRow } from './FilterRow';

type P = {
  baseTestId: string;
  currentFilterGroupObject: Record<string, any>;
  deleteFilterGroup: () => void;
  emptyFilterGroupObject: Record<string, any>;
  disableDeleteButton: boolean;
  disableUpdateButton: boolean;
  filterContext: Record<string, any>;
  filterGroupMarks: Record<string, any>;
  filterGroupOpen: boolean;
  filterGroups: Array<Record<string, any>>;
  filterRows: Array<Record<string, any>>;
  hideAccordion: boolean;
  hideEditNameIcon: boolean;
  hideUpdateDeleteButton: boolean;
  onChangeValue: (filterRow: string, filterValue: any[]) => void;
  onClickExpandFilterGroup: () => void;
  onClickFilterGroup: (filterGroupId: UUID) => void;
  onOpenFilterRow: (filterId: UUID) => void;
  originalFilterGroupObject: Record<string, any>;
  renderFilterRow: React.ComponentType<any>;
  resetSelectedFilterGroup: () => void;
  saveNewFilterGroup: (
    filterGroupId: UUID,
    filterGroupName: string,
    filterRows: Array<Record<string, any>>,
    hasChanges: boolean,
  ) => void;
  selectableFilters: Array<Record<string, any>>;
  selectedFilterGroup: string;
  updateFilterGroup: (filterRows: Array<Record<string, any>>) => void;
  updateFilterGroupName: (
    filterGroupId: string,
    filterGroupName: string,
  ) => void;
  updateFilterRows: (filterRows: Array<Record<string, any>>) => void;
  withExpandableFilterGroup: boolean;
};

export const FilterGroups = withErrorBoundary(
  ({
    baseTestId,
    currentFilterGroupObject,
    deleteFilterGroup,
    emptyFilterGroupObject = emptyDeliveryNoteFilterGroup,
    disableDeleteButton,
    disableUpdateButton,
    filterContext,
    filterGroupMarks,
    filterGroupOpen,
    filterGroups,
    filterRows,
    hideAccordion,
    hideEditNameIcon,
    hideUpdateDeleteButton,
    onChangeValue,
    onClickExpandFilterGroup,
    onClickFilterGroup,
    onOpenFilterRow,
    originalFilterGroupObject,
    renderFilterRow,
    resetSelectedFilterGroup,
    saveNewFilterGroup,
    selectableFilters,
    selectedFilterGroup,
    updateFilterGroup,
    updateFilterGroupName: updateFilterGroupNameProperty,
    updateFilterRows,
    withExpandableFilterGroup,
  }: P) => {
    const [formType, setFormType] = useState('create');
    const [formOpen, setFormOpen] = useState(false);
    const [deleteFormOpen, setDeleteFormOpen] = useState(false);
    const [newFilterGroupName, setNewFilterGroupName] = useState('');

    const resetSelectedFilterGroupHandler = () => {
      if (!selectedFilterGroup) {
        return false;
      }

      if (filterGroups.some(({ id }) => id === selectedFilterGroup)) {
        return false;
      }

      resetSelectedFilterGroup();
      return true;
    };

    const getFilterRowsFromFilterGroup = (filterGroup) => {
      const filterRows = [...filterGroup.filterRows];

      // Extend the filter rows by the props that are filtered on.
      for (const [key, value] of Object.entries(filterGroup.filters)) {
        // If no filter is applied or the filter is already in the list of filter rows, there is no use in extending the filter row list.
        if (!value?.length || filterRows.includes(key)) {
          continue;
        }

        // If the filter is not the custom fields filter, we can handle it normally.
        if (key !== FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS) {
          filterRows.push(key);
          continue;
        }

        // If the filter is the custom fields filter, we have to handle it differently because custom fields filters are stored with a different format.
        for (const customField of value) {
          // Prevent duplicate filter rows.
          if (filterRows.includes(customField.key)) {
            continue;
          }

          filterRows.push(customField.key);
        }
      }

      return filterRows;
    };

    const initFilterRows = () => {
      // Get filter rows from current filter group
      let newFilterRows = getFilterRowsFromFilterGroup(
        currentFilterGroupObject,
      );

      // If no filter rows found, try to get them from localStorage
      if (newFilterRows.length === 0 && originalFilterGroupObject?.filterRows) {
        newFilterRows = [...originalFilterGroupObject.filterRows];
      }

      // Filter out disabled filters
      newFilterRows = newFilterRows.filter((filterRow) => {
        const selectableFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );
        return selectableFilter && !selectableFilter.disabled;
      });

      // Add default filter if empty
      if (newFilterRows.length === 0) {
        const selectableFilter = selectableFilters.find(
          ({ disabled }) => !disabled,
        );
        if (selectableFilter) {
          newFilterRows.push(selectableFilter.id);
        }
      }

      updateFilterRows(newFilterRows);
    };

    const removeDisabledFilterRows = () => {
      const newFilterRows = [...filterRows].filter((filterRow) => {
        const selectableFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );

        if (!selectableFilter) {
          return true;
        }

        return !selectableFilter.disabled;
      });

      updateFilterRows(newFilterRows);
    };

    useEffect(() => {
      const selectedFilterGroupReset = resetSelectedFilterGroupHandler();
      if (!selectedFilterGroupReset) {
        initFilterRows();
      }
    }, []);

    useEffect(() => {
      if (currentFilterGroupObject?.id) {
        initFilterRows();
      }
    }, [currentFilterGroupObject?.id]);

    useEffect(() => {
      if (filterRows.length > 0) {
        removeDisabledFilterRows();
      }
    }, [selectableFilters]);

    const filterGroupHasBeenUpdated = () => {
      const mergedOriginalFilterGroupObjectFilters = {
        ...emptyFilterGroupObject,
        ...originalFilterGroupObject?.filters,
      };

      const currentFilters = currentFilterGroupObject?.filters;

      const currentCustomFields = currentFilters?.selectedCustomFields || [];
      const originalCustomFields =
        mergedOriginalFilterGroupObjectFilters?.selectedCustomFields || [];

      // Custom field filters have a different structure and need to be compared separately
      const isCustomFieldFiltersEqual = isObjectContentEqual(
        currentCustomFields,
        originalCustomFields,
      );

      // Remove custom fields before comparing other filters
      const currentFiltersWithoutCustom = { ...currentFilters };
      const originalFiltersWithoutCustom = {
        ...mergedOriginalFilterGroupObjectFilters,
      };
      delete currentFiltersWithoutCustom.selectedCustomFields;
      delete originalFiltersWithoutCustom.selectedCustomFields;

      // Compare regular filters
      const isRegularFiltersEqual = isObjectContentEqual(
        currentFiltersWithoutCustom,
        originalFiltersWithoutCustom,
      );

      return !isCustomFieldFiltersEqual || !isRegularFiltersEqual;
    };

    const handleClickFilterGroup = (filterGroupId: string) => {
      if (selectedFilterGroup === filterGroupId) {
        Log.info(
          'Deselect filter group',
          filterGroupId,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent(
          'Deselect filter group',
          Log.FEATURE.FILTER_GROUPS,
        );
      } else {
        const filterGroup = filterGroups.find(({ id }) => id === filterGroupId);

        Log.info(
          'Select filter group',
          filterGroupId,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent(
          'Select filter group',
          Log.FEATURE.FILTER_GROUPS,
          undefined,
          {
            filterGroupId,
            filterGroupName: filterGroup?.name,
            filterNames: filterGroup?.filterRows,
          },
        );
      }

      onClickFilterGroup(filterGroupId);
    };

    const handleClickNewFilterGroup = (event) => {
      event.currentTarget.blur();

      setFormOpen(true);
      setFormType('create');
    };

    const handleClickChangeFilterGroupName = (event) => {
      event.stopPropagation();
      event.currentTarget.blur();

      setFormOpen(true);
      setFormType('edit');
      setNewFilterGroupName(
        filterGroups.find(({ id }) => id === selectedFilterGroup)?.name,
      );
    };

    const handleClickExpandFilterGroup = (event) => {
      event.stopPropagation();

      if (filterGroupOpen) {
        Log.info(
          'Hide filters',
          selectedFilterGroup,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent('Hide filters', Log.FEATURE.FILTER_GROUPS);
      } else {
        Log.info(
          'Expand filters',
          selectedFilterGroup,
          Log.BREADCRUMB.USER_ACTION.KEY,
        );
        Log.productAnalyticsEvent('Expand filters', Log.FEATURE.FILTER_GROUPS);
      }

      onClickExpandFilterGroup();
    };

    const handleSaveNewFilterGroup = (event) => {
      event.preventDefault();

      Log.info(
        'Save filter',
        newFilterGroupName,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Submit form', Log.FEATURE.FILTER_GROUPS);

      if (formType === 'create') {
        saveNewFilterGroup(
          uuidv4(),
          newFilterGroupName,
          filterRows,
          !filterGroupHasBeenUpdated(),
        );
      } else {
        updateFilterGroupNameProperty(selectedFilterGroup, newFilterGroupName);
      }

      setFormOpen(false);
      setNewFilterGroupName('');
    };

    const handleAbortNewFilterGroup = () => {
      Log.productAnalyticsEvent('Abort form', Log.FEATURE.FILTER_GROUPS);

      setFormOpen(false);
      setNewFilterGroupName('');
    };

    const handleUpdateFilterGroup = () => {
      Log.info(
        'Update filter group',
        selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Update filter group',
        Log.FEATURE.FILTER_GROUPS,
      );

      updateFilterGroup(filterRows);
    };

    const handleChangeProperty = (oldFilterRow, newFilterRow) => {
      Log.info(
        'Update property of filter',
        oldFilterRow,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Update property of filter',
        Log.FEATURE.FILTER_GROUPS,
      );

      const oldSelectedFilter = selectableFilters.find(
        ({ id }) => id === oldFilterRow,
      );

      // Reset old filter row value.
      onChangeValue(oldFilterRow, oldSelectedFilter.customField, []);

      // Replace old filter row with new filter row.
      const filterRowIndex = filterRows.indexOf(oldFilterRow);
      const newFilterRows = [...filterRows];
      newFilterRows[filterRowIndex] = newFilterRow;

      updateFilterRows(newFilterRows);
    };

    const handleDeleteRow = (deletedFilterRow) => {
      Log.info(
        'Delete filter',
        deletedFilterRow,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent('Delete filter', Log.FEATURE.FILTER_GROUPS);

      const oldSelectedFilter = selectableFilters.find(
        (selectableFilter) => selectableFilter.id === deletedFilterRow,
      );

      onChangeValue(deletedFilterRow, oldSelectedFilter.customField, []);

      // If there is only one filter left, just reset it, but don't remove it so that the list of filters isn't empty.
      if (filterRows.length === 1) {
        return;
      }

      const filterRowIndex = filterRows.indexOf(deletedFilterRow);
      const newFilterRows = [...filterRows];
      newFilterRows.splice(filterRowIndex, 1);

      updateFilterRows(newFilterRows);
    };

    const handleOpenFilterRow = (filterId) => {
      Log.info('Open filter row', filterId, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent('Open filter row', Log.FEATURE.FILTER_GROUPS);

      if (onOpenFilterRow) {
        onOpenFilterRow(filterId);
      }
    };

    const handleAddFilterRow = () => {
      Log.info('Add filter', null, Log.BREADCRUMB.USER_ACTION.KEY);
      Log.productAnalyticsEvent('Add filter', Log.FEATURE.FILTER_GROUPS);

      const availableFilters = selectableFilters.filter(
        ({ disabled, id }) => !filterRows.includes(id) && !disabled,
      );

      if (availableFilters.length === 0) {
        return;
      }

      updateFilterRows([...filterRows, availableFilters[0].id]);
    };

    const isAccordionBodyOpen = () => {
      if (hideAccordion) {
        return false;
      }

      if (filterGroupOpen) {
        return true;
      }

      return filterGroups.length === 0;
    };

    const handleDeleteFormSuccess = (event) => {
      event.preventDefault();
      event.stopPropagation();

      Log.info(
        'Delete filter group',
        selectedFilterGroup,
        Log.BREADCRUMB.USER_ACTION.KEY,
      );
      Log.productAnalyticsEvent(
        'Delete filter group',
        Log.FEATURE.FILTER_GROUPS,
      );

      deleteFilterGroup();
      setDeleteFormOpen(false);
    };

    const getUpdateButton = () => (
      <Button
        data-testid={`${baseTestId}_update_group_button`}
        variant={
          !filterGroupHasBeenUpdated() || disableUpdateButton
            ? 'text'
            : 'contained'
        }
        color="primary"
        disabled={!filterGroupHasBeenUpdated() || disableUpdateButton}
        startIcon={
          !filterGroupHasBeenUpdated() || disableUpdateButton ? (
            <CheckIcon />
          ) : null
        }
        onClick={handleUpdateFilterGroup}
      >
        {!filterGroupHasBeenUpdated() || disableUpdateButton
          ? 'Filtergruppe gespeichert'
          : 'Filtergruppe speichern'}
      </Button>
    );

    const getDeleteButton = () => (
      <>
        <Button
          data-testid={`${baseTestId}_delete_group_button`}
          variant="outlined"
          color="secondary"
          disabled={disableDeleteButton}
          startIcon={<DeleteOutlinedIcon />}
          onClick={() => {
            setDeleteFormOpen(true);
          }}
        >
          Filtergruppe löschen
        </Button>
        <BasicForm
          testId={`${baseTestId}_delete_group_form`}
          open={deleteFormOpen}
          formSuccess={handleDeleteFormSuccess}
          formAbort={() => {
            setDeleteFormOpen(false);
          }}
          title="Filtergruppe löschen"
          submitButtonTitle="Löschen"
        >
          Willst du mit dem Löschen fortfahren?
        </BasicForm>
      </>
    );

    const getButtons = () => {
      if (
        !selectedFilterGroup ||
        filterGroups.length === 0 ||
        hideUpdateDeleteButton
      ) {
        return null;
      }

      return (
        <div className="mt-4 flex items-center justify-between gap-4">
          {getUpdateButton()}
          {getDeleteButton()}
        </div>
      );
    };

    const hasUnusedFilters = selectableFilters.some(
      ({ id }) => !filterRows.includes(id),
    );
    const everyAppliedFilterHasAValue = filterRows
      .map((filterRow) => {
        const selectedFilter = selectableFilters.find(
          ({ id }) => id === filterRow,
        );
        const selectedValues =
          (selectedFilter?.customField
            ? currentFilterGroupObject?.filters?.selectedCustomFields?.find(
                ({ key }) => key === selectedFilter.customField.key,
              )?.filterValue
            : currentFilterGroupObject.filters[filterRow]) ?? [];

        return selectedValues.filter(Boolean);
      })
      .every((filterValues) => Boolean(filterValues?.length > 0));
    const canAddFilter = everyAppliedFilterHasAValue && hasUnusedFilters;

    return (
      <>
        <FilterGroupsList
          abortNewFilterGroup={handleAbortNewFilterGroup}
          baseTestId={baseTestId}
          filterGroupHasChanges={filterGroupHasBeenUpdated()}
          filterGroupMarks={filterGroupMarks}
          filterGroups={filterGroups}
          hideAccordion={hideAccordion}
          hideEditNameIcon={hideEditNameIcon}
          isCollapsed={!filterGroupOpen}
          isCreating={formType === 'create'}
          isSubmitting={false}
          saveNewFilterGroup={handleSaveNewFilterGroup}
          selectedFilterGroup={selectedFilterGroup}
          state={{ formOpen, formType, newFilterGroupName }}
          withExpandableFilterGroup={withExpandableFilterGroup}
          onChangeName={setNewFilterGroupName}
          onClickChangeFilterGroupName={handleClickChangeFilterGroupName}
          onClickExpandFilterGroup={handleClickExpandFilterGroup}
          onClickFilterGroup={handleClickFilterGroup}
          onClickNewFilterGroup={handleClickNewFilterGroup}
        />
        <AccordionBody
          testId={`${baseTestId}_filter_rows_accordion`}
          id="filter_accordion"
          open={isAccordionBodyOpen()}
          className="flex-none"
        >
          <div className="mt-2 flex flex-col gap-2">
            {filterRows.map((filterRow) => {
              const newSelectedFilter = cloneDeep(
                selectableFilters.find(({ id }) => id === filterRow),
              );

              if (!newSelectedFilter) {
                return null;
              }

              const newSelectedValues =
                (newSelectedFilter.customField
                  ? currentFilterGroupObject.filters.selectedCustomFields.find(
                      ({ key }) => key === newSelectedFilter.customField.key,
                    )?.filterValue
                  : cloneDeep(currentFilterGroupObject.filters[filterRow])) ??
                [];

              const FilterRowComponent = renderFilterRow || FilterRow;

              return (
                <FilterRowComponent
                  key={newSelectedFilter.id}
                  testId={`${baseTestId}_filter_row`}
                  selectedValues={newSelectedValues}
                  selectedFilter={newSelectedFilter}
                  selectableFilters={selectableFilters.filter(
                    ({ id }) => id === filterRow || !filterRows.includes(id),
                  )}
                  operator={FilterNew.OPERATOR.IS_ANY_OF}
                  hideDeleteButton={filterRows.length === 1}
                  isDisabled={newSelectedFilter.disabled}
                  filterContext={filterContext}
                  onChangeProperty={(value) => {
                    handleChangeProperty(filterRow, value);
                  }}
                  onChangeOperator={() => {}} // Feature FILTER_OPERATOR
                  onChangeValue={(_, filterValue) => {
                    onChangeValue(
                      filterRow,
                      newSelectedFilter.customField,
                      filterValue,
                    );
                  }}
                  onDeleteRow={() => {
                    handleDeleteRow(filterRow);
                  }}
                  onOpen={() => {
                    handleOpenFilterRow(newSelectedFilter.id);
                  }}
                />
              );
            })}
          </div>
          {canAddFilter && (
            <PackageBasicRestrictionTooltip>
              <ClientPortalTooltip>
                <Button
                  data-testid={`${baseTestId}_add_filter_button`}
                  variant="outlined"
                  color="primary"
                  className="!mt-2 w-80"
                  disabled={
                    FeatureService.clientPortal() ||
                    FeatureService.packageBasicRestriction()
                  }
                  startIcon={<AddIcon />}
                  onMouseDown={handleAddFilterRow}
                >
                  Weiteren Filter hinzufügen
                </Button>
              </ClientPortalTooltip>
            </PackageBasicRestrictionTooltip>
          )}
          {getButtons()}
        </AccordionBody>
      </>
    );
  },
  'Filter konnten nicht geladen werden.',
);

FilterGroups.displayName = 'FilterGroups';
