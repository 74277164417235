import { FEATURE_FLAG } from '~/constants/featureFlags';
import { COMPANY, COMPANY_ACCOUNT, USER_ACCOUNT } from '~/constants/Masterdata';

import store from '~/redux/store';

import AuthService from '~/services/auth.service';
import FeatureService from '~/services/feature.service';

import CustomField from '~/models/customData/CustomField';
import Permissions from '~/models/masterdata/Permissions';

import { intersection } from './array';

class UserUtils {
  constructor() {
    this.VESTIGAS_ACCOUNTS = [
      USER_ACCOUNT.DEMO_LIEFERANT,
      USER_ACCOUNT.DEMO_POLIER,
      USER_ACCOUNT.DEMO_SPEDITEUR,
      USER_ACCOUNT.LIEFERANT_DEV,
      USER_ACCOUNT.LIEFERANT_IDP,
      USER_ACCOUNT.MUSTER_POLIER,
      USER_ACCOUNT.POLIER_DEV,
      USER_ACCOUNT.SPEDITEUR_DEV,
    ];

    this.SCHICKER_COMPANY_ACCOUNTS = [COMPANY_ACCOUNT.SCHICKER];

    this.DLN_DATE_COMPANY_ACCOUNTS = [
      COMPANY_ACCOUNT.SCHICKER,
      COMPANY_ACCOUNT.JOSEF_RAEDLINGER,
      COMPANY_ACCOUNT.STAATLICHES_BAUAMT_BAYREUTH,
    ];

    this.STRABAG_COMPANY_ACCOUNTS = [COMPANY_ACCOUNT.STRABAG];

    this.GROSSMANN_COMPANIES = [COMPANY.GROSSMANN];

    this.DECHANT_COMPANIES = [COMPANY.DECHANT];

    this.WM_COMPANIES = [COMPANY.WM];

    this.ARGE_A3_COMPANIES = [COMPANY.ARGE_A3];

    this.ANBS_COMPANIES = [COMPANY.ANBS];

    this.ATH_COMPANIES = [COMPANY.ATH, COMPANY.ARGE_OBERIRDISCHE_WEST];

    this.ANTON_EIREINER_COMPANIES = [
      COMPANY.ANTON_EIREINER_GMBH,
      COMPANY.STEINWERKE_ANTON_EIREINER,
    ];

    this.DEMO_ALLOWED_COMPANY_ACCOUNTS = [
      COMPANY_ACCOUNT.DEMO_ALLOWED_01,
      COMPANY_ACCOUNT.DEMO_ALLOWED_02,
      COMPANY_ACCOUNT.DEMO_ALLOWED_03,
      COMPANY_ACCOUNT.DEMO_ALLOWED_04,
      COMPANY_ACCOUNT.DEMO_ALLOWED_05,
      COMPANY_ACCOUNT.DEMO_ALLOWED_06,
      COMPANY_ACCOUNT.DEMO_ALLOWED_07,
      COMPANY_ACCOUNT.DEMO_ALLOWED_08,
      COMPANY_ACCOUNT.DEMO_ALLOWED_09,
      COMPANY_ACCOUNT.DEMO_ALLOWED_10,
      COMPANY_ACCOUNT.DEMO_ALLOWED_11,
      COMPANY_ACCOUNT.DEMO_ALLOWED_12,
      COMPANY_ACCOUNT.DEMO_ALLOWED_13,
      COMPANY_ACCOUNT.DEMO_ALLOWED_14,
      COMPANY_ACCOUNT.DEMO_ALLOWED_15,
      COMPANY_ACCOUNT.DEMO_ALLOWED_16,
      COMPANY_ACCOUNT.DEMO_ALLOWED_17,
      COMPANY_ACCOUNT.DEMO_ALLOWED_18,
    ];

    this.CONCRETE_DIARY_ALLOWED_USERS = [
      // polier-dev, muster-polier
      '830acde4-ef26-4411-a9b1-150957cb3a83',
      '0d9ece68-9750-4a38-8c09-37ba3319ecad',
      // grossmann: support, vizethum, wallner, polier-wallner, henken, prinz, schuster, keranovic
      '0501aefe-9d0e-490f-b347-c160ac49fab8',
      '2a3c5c0d-cb57-46ea-a6a3-739ca9e0cf7d',
      '264858ae-98ca-43ae-84ff-9fcbd92df2bd',
      '28831097-274d-41db-a5c9-97a3699ffa12',
      'f865f22e-cdbb-4e8c-b64e-eae044c10e7f',
      '9c919144-dd39-4692-8017-a0c585ac4d18',
      'fb59cbb3-082e-4969-8b81-ca194ac39ab6',
      '86197910-de17-477e-9173-9a5a4ae734ad',
      // dechant: support, hüttner, sielaff
      '451b53b2-3317-48a1-ba03-61e34f5841da',
      '2def63c0-1637-48f8-8cc4-90fc89440bb7',
      '6b90ec85-6b31-44eb-8d3b-f4301af02e5a',
    ];

    this.PERMITTED_SITE_ALLOWED_USERS = [
      '114cdae9-b1ad-4dbc-82db-49739382bc7d',
      'e3c981df-3645-4086-9051-73d34b90182b', // grossmann: dettendorfer, hülle
    ];

    this.USER_METRICS_ALLOWED_USERS = [
      '114cdae9-b1ad-4dbc-82db-49739382bc7d',
      'e3c981df-3645-4086-9051-73d34b90182b', // grossmann: dettendorfer, hülle
      'b4a90b65-6edf-48ce-8e51-cdd865c2c6a8', // dechant: huemmer
      '2b4bf107-1408-4663-86df-4bf734f8897b', // rödl: Krauss
      // STRABAG Demo
      'b6afad0c-017a-439d-8246-4cfa27694202', // stefanie.auschner@strabag.com
      '422f735c-c30f-40fe-81ff-ce0cc9446182', // verena.nachreiner@strabag.com
      '18fc58cc-fe2b-41bb-a265-bcc05ad2a1bd', // kimberly.selwyn@strabag.com
      '46bf1ee8-9c22-4b88-9f0b-e2b423c2d5bc', // heiko.natusch@strabag.com
      'dfaf2307-ad64-4615-90c4-ad8c2433d1c5', // thomas.haberecht@strabag.com
      '736295f5-07ec-49bf-bec1-e8905bec2ece', // maximilian.kaun@strabag.com
      '6cda641c-2a88-47f9-9069-4528baa71175', // dirk.hildebrandt@strabag.com
      '75756df1-ac6b-41c4-be02-6e375385bb63', // guido.ausboeck-coenen@strabag.com
      '59061d47-45d3-4b26-9960-a297367a2168', // dominik.bamberger@strabag.com
      '8be6036a-3ea5-4de8-a664-a441d27b7af5', // josefine.malter@strabag.com
      '20674e97-29a5-4e60-9086-a9fbba5d689f', // philipp.scheidig@strabag.com
      'a5483a94-dc47-43bc-b555-c21dd2280fd2', // tobias.roith@strabag.com
      'aaf05915-9b71-4da7-9382-47f8139c2ca9', // alexander.schneider@strabag.com
      '761615d3-4105-42fc-a292-c4ac6fab48de', // bernd.lechleitner@strabag.com
      'b2eb1ecb-f902-4878-a509-e247164e9c3a', // franz.hollmayr@strabag.com
      '419edccd-9d03-4574-98ba-53fb7131bd96', // stefanie.biegner@strabag.com
      '574c6546-d28c-4a91-a34e-89381ade7a0d', // florian.lussert@strabag.com
      '1d1d7082-43c5-4f2b-93b3-99f084d504bf', // julian.olbrich@strabag.com
      '188b83e5-2d43-4ed2-9152-cb6dc9da5bc6', // lena.karwath@strabag.com
      'b625c15f-52e9-47ae-a4f5-9c2f79e8b987', // tim.strohbehn@strabag.com
      '90892af5-dbe6-4cd5-996b-eb9e01a4c929', // peter.hessler@strabag.com
      '2b709bf1-3f8d-4337-b6d6-429246327251', // thomas.sixt@strabag.com
      '82078b66-3a75-409e-9667-29f17721cf90', // thomas.nicklas@strabag.com
      // STRABAG Live
      'cea92bd5-203b-4ab1-9e35-a890fd717d98', // kaunmax@sbsit.com
      '01fb1182-6c75-4d36-a4d1-2d5f51271f6c', // bambergerdom@sbsit.com
      '8422efcf-a6c6-414a-8784-a45d36948ecb', // malterjos@sbsit.com
      '4f5fbc55-34a2-4ef4-9087-bd805057f08d', // scheidigphi@sbsit.com
      '7da92fb8-45fb-4f9c-a066-dbde928f46ca', // roithtob@sbsit.com
      '32a2aaee-35b4-45c6-bd03-a71d91577004', // schneiderale@sbsit.com
      '7988a8ad-4229-4e91-ae05-3106ee543c75', // lechleitnerber@sbsit.com
      'e2fc5629-4ed2-45a8-98cc-8fe905f623f5', // hollmayrfra@sbsit.com
      '82fab511-5def-4359-a919-ce674dea9b86', // auschnerste@sbsit.com
      '2e45f168-d0c2-4644-81eb-b3e912b8839f', // biegnerste@sbsit.com
      'd66dfdd1-1b93-4a91-9d7a-756218eedf23', // nicklastho@sbsit.com
      'f7db9cfe-eb56-4338-8a39-5f4fd07f4089', // lussertflo@sbsit.com
      '71ce353c-a2e5-4e52-95ec-c12bc444037f', // sixttho@sbsit.com
      'c70bc1c4-be5a-405b-a8b6-089eab2ea979', // natuschhei@sbsit.com
      'a1098e62-9e4d-4719-be1c-c2df5e39da55', // nachreinerver@sbsit.com
      'fd193672-443d-4ead-85d1-822ab100e3f4', // olbrichjul@sbsit.com
      '2cf465c3-d5d5-443e-8b78-929dabed759e', // karwathlen@sbsit.com
      'd444f57c-5e26-4e58-b59c-b71c71bff985', // SelwynKim@sbsit.com
      'b9f08cec-f35c-4df9-9906-2eff7043488c', // hildebrandtdir@sbsit.com
      '90e7879a-a5f0-4d21-b6c3-384c32f442c5', // haberechttho@sbsit.com
      '03dce664-fc6e-4d23-9cc8-cf2b208967c7', // Tim Strohbehn
      'a31da27b-6e4d-4fa5-a076-7b3063fa36d9', // Peter Hessler
    ];

    // Users that are allowed to see the delayed signature tiles in the home screen.
    this.DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER = [
      '114cdae9-b1ad-4dbc-82db-49739382bc7d',
      'e3c981df-3645-4086-9051-73d34b90182b', // Grossmann: Dettendorfer, Hülle
      'cd7b03e6-e5eb-4fac-a6a1-2d0e9c96f574', // Ochs: Giesbrecht
      'e75355e4-3fd4-4786-a56f-d5da4e111ac3', // dechant: Schmidtlein
      'ee8c1f30-f472-41e0-9c37-69fc46e55630', // Thannhauser: Burkhardt
    ];

    // The mappings of the custom data keys is provided by custom fields which can be loaded from the backend.
    // However, currently there are some hardcoded mappings for specific users.
    // They are used to display additional_party_data as column in the delivery list and as filter in te dashboard.
    // eireiner: support, baumann
    this.USERS_WITH_CUSTOM_FIELDS = [
      {
        customFields: [
          new CustomField({
            display_name: 'Zufuhrart',
            hardcoded: true,
            id: 'Zufuhrart',
            visibility: CustomField.VISIBILITY.ALWAYS.KEY,
          }),
        ],
        userId: 'ad191288-cb8f-4c87-96f2-131aa9f43db1',
      },
      {
        customFields: [
          new CustomField({
            display_name: 'Zufuhrart',
            hardcoded: true,
            id: 'Zufuhrart',
            visibility: CustomField.VISIBILITY.ALWAYS.KEY,
          }),
        ],
        userId: 'bb56ca3b-80e0-47f9-83e4-bc5a0bfa2fac',
      },
    ];

    this.STRABAG_SUPPORT_USERS = ['d0ca33e3-54b3-4c14-a8af-3fd81eb92fa3'];

    this.HTI_SUPPORT_USERS = ['c83792b3-45ad-451a-aa18-e6f1c82b0dfb'];

    this.SUPPLIER_OVERVIEW_USER = 'befc3a91-e8be-42f7-9559-16a8cd9cc3e8';

    this.BAYWA_SCREEN_USER = '018df28e-5247-428f-8ca1-0e79865a3da9';

    this.ANTON_EIREINER_USERS = [
      'ad191288-cb8f-4c87-96f2-131aa9f43db1',
      'bb56ca3b-80e0-47f9-83e4-bc5a0bfa2fac',
    ];

    this.MAXI_KAUN_USERS = ['cea92bd5-203b-4ab1-9e35-a890fd717d98'];
  }

  isVestigasSupportAccount() {
    return store.getState().userinfo.userinfo.isVestigasSupport;
  }

  isVestigasSupportAccount() {
    return store.getState().userinfo.userinfo.isVestigasSupport;
  }

  isVestigasAccount() {
    const accessToken = AuthService.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const accessTokenPayload = AuthService.getJwtPayload(accessToken);

    if (!accessTokenPayload) {
      return false;
    }

    return (
      this.isVestigasSupportAccount() ||
      this.VESTIGAS_ACCOUNTS.includes(accessTokenPayload.preferred_username) ||
      accessTokenPayload.preferred_username
        ?.toLowerCase()
        ?.includes('support') ||
      accessTokenPayload.email?.toLowerCase()?.includes('support') ||
      accessTokenPayload.email?.includes('oleksiy.tsebriy@chisw.com')
    );
  }

  isVestigasAdminAccount() {
    const accessToken = AuthService.getAccessToken();

    if (!accessToken) {
      return false;
    }

    const accessTokenPayload = AuthService.getJwtPayload(accessToken);

    if (!accessTokenPayload) {
      return false;
    }

    return accessTokenPayload.preferred_username === 'vestigas-admin';
  }

  isSchickerAccount(companyAccountId) {
    return this.SCHICKER_COMPANY_ACCOUNTS.includes(companyAccountId);
  }

  // Should we render the DLN date as full date time instead of just the date?
  showFullDlnDateDateTime(companyAccountId) {
    return this.DLN_DATE_COMPANY_ACCOUNTS.includes(companyAccountId);
  }

  // Should we render a user feedback button?
  showUserFeedbackButton() {
    return AuthService.getUserIdFromAccessToken() !== this.BAYWA_SCREEN_USER;
  }

  isStrabagAccount(companyAccountId) {
    return this.STRABAG_COMPANY_ACCOUNTS.includes(companyAccountId);
  }

  isGrossmannAccount(companyId) {
    return this.GROSSMANN_COMPANIES.includes(companyId);
  }

  isAntonEireinerAccount(companyId) {
    return this.ANTON_EIREINER_COMPANIES.includes(companyId);
  }

  isDechantAccount(companyId) {
    return this.DECHANT_COMPANIES.includes(companyId);
  }

  isWMAccount(companyId) {
    return this.WM_COMPANIES.includes(companyId);
  }

  isArgeA3Account(companyId) {
    return this.ARGE_A3_COMPANIES.includes(companyId);
  }

  isANBSAccount(companyId) {
    return this.ANBS_COMPANIES.includes(companyId);
  }

  isATHAccount(companyId) {
    return this.ATH_COMPANIES.includes(companyId);
  }

  isMaxiKaunUser() {
    return this.MAXI_KAUN_USERS.includes(
      AuthService.getUserIdFromAccessToken(),
    );
  }

  isStrabagSupportUser() {
    return this.STRABAG_SUPPORT_USERS.includes(
      AuthService.getUserIdFromAccessToken(),
    );
  }

  isHtiSupportUser() {
    return this.HTI_SUPPORT_USERS.includes(
      AuthService.getUserIdFromAccessToken(),
    );
  }

  isArchiveModeAllowedUser() {
    if (FeatureService.accessArchive()) {
      return true;
    }

    const company = store?.getState()?.userinfo?.userinfo?.company?.id;

    if (this.isGrossmannAccount(company)) {
      return true;
    }

    if (this.isAntonEireinerAccount(company)) {
      return true;
    }

    return false;
  }

  // user can see demo tab in settings
  isDemoAllowedCompanyAccount(companyAccountId) {
    return this.DEMO_ALLOWED_COMPANY_ACCOUNTS.includes(companyAccountId);
  }

  // user can see permitted/mapped sites and cost centers
  isPermittedSiteAllowedUser() {
    return (
      this.isVestigasAccount() ||
      this.PERMITTED_SITE_ALLOWED_USERS.includes(
        AuthService.getUserIdFromAccessToken(),
      )
    );
  }

  // user can see user metrics
  isUserMetricsAllowedUser() {
    // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
    // Also, nobody uses it.
    return false;

    // return (
    //   this.isVestigasAccount() ||
    //   this.USER_METRICS_ALLOWED_USERS.includes(
    //     AuthService.getUserIdFromAccessToken(),
    //   )
    // );
  }

  isDelayedSignatureHomeScreenAllowedUser(userId, featureFlags) {
    if (this.STRABAG_SUPPORT_USERS.includes(userId)) {
      return false;
    }

    if (featureFlags?.disableInvoices) {
      return false;
    }

    if (this.isVestigasAccount()) {
      return true;
    }

    return this.DELAYED_SIGNATURE_HOME_SCREEN_ALLOWED_USER.includes(userId);
  }

  isInvoiceExcelAllowedCompany(companyId) {
    return this.GROSSMANN_COMPANIES.includes(companyId);
  }

  isInvoiceExcelAllowedUser() {
    const companyId = store.getState().userinfo.userinfo.company?.id;
    return this.isInvoiceExcelAllowedCompany(companyId);
  }

  // ############ Based on granted permissions ############

  isUserReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_USER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.WRITE_USER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.CREATE_USER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isSiteReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_SITE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isSiteWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.WRITE_SITE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isSiteCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.CREATE_SITE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCostCenterReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_COST_CENTER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCostCenterWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.WRITE_COST_CENTER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCostCenterCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.CREATE_COST_CENTER.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isVehicleReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_VEHICLE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isVehicleWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.WRITE_VEHICLE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isVehicleCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.CREATE_VEHICLE.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCompanyReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_COMPANY.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCompanyWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.WRITE_COMPANY.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isCompanyCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.CREATE_COMPANY.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isOrganisationalGroupReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.READ_ORGANISATIONAL_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isOrganisationalGroupWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.WRITE_ORGANISATIONAL_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isOrganisationalGroupCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.CREATE_ORGANISATIONAL_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isOrganisationalGroupDeleteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.DELETE_ORGANISATIONAL_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserGroupReadAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.READ_USER_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserGroupWriteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.WRITE_USER_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserGroupCreateAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.CREATE_USER_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isUserGroupDeleteAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions =
      Permissions.PERMISSION.DELETE_USER_GROUP.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }

  isPermissionGrantAllowedUser() {
    const { userPermissions } = store.getState().userinfo;
    const requiredPermissions = Permissions.PERMISSION.GRANT_PERMISSION.BACKEND;

    return intersection(userPermissions, requiredPermissions).length > 0;
  }
}

export default new UserUtils();
