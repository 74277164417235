import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import { sortByKey } from '~/utils/array';

const articleMasterSlice = createSlice({
  initialState: {
    articles: [],
    articlesLoading: LOADING_STATE.NOT_LOADED,
    categories: [],
    categoriesLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'articleMaster',
  reducers: {
    replaceArticle(state, parameter) {
      const { payload } = parameter;

      const newArticles = state.articles.filter(({ id }) => id !== payload.id);
      newArticles.push(payload);

      state.articles = newArticles;
    },
    replaceArticles(state, parameter) {
      const { payload } = parameter;

      state.articles = sortByKey(payload, 'articleId');
      state.articlesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceCategories(state, parameter) {
      const { payload } = parameter;

      state.categories = sortByKey(payload, 'name');
      state.categoriesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceCategory(state, parameter) {
      const { payload } = parameter;

      const newCategories = state.categories.filter(
        ({ id }) => id !== payload.id,
      );
      newCategories.push(payload);

      state.categories = newCategories;
    },
    setArticlesLoading(state, parameter) {
      const { payload } = parameter;

      state.articlesLoading = payload;
    },
    setCategoriesLoading(state, parameter) {
      const { payload } = parameter;

      state.categoriesLoading = payload;
    },
  },
});

const { actions, reducer } = articleMasterSlice;
export const {
  replaceArticle,
  replaceArticles,
  replaceCategories,
  replaceCategory,
  setArticlesLoading,
  setCategoriesLoading,
} = actions;
export default reducer;
