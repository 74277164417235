import { Check as CheckIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';

import { type UncefactUnitType } from '~/constants/units';

type P = {
  readonly isSelected: boolean;
  readonly label: string;
  readonly onUnitChange: (unit: string) => void;
  readonly value: UncefactUnitType;
};

export const UnitChip = ({ isSelected, label, onUnitChange, value }: P) => (
  <Chip
    key={value}
    label={label}
    icon={isSelected ? <CheckIcon className="!text-white" /> : undefined}
    className={
      isSelected ? 'bg-primary500 cursor-auto !text-white' : '!cursor-pointer'
    }
    onMouseDown={() => {
      onUnitChange(value);
    }}
  />
);
