import { type UUID } from '~/types/common';

import { type CustomFieldsQueryParams } from './useQueryCustomFields';

/**
 * Query key factory for custom field-related queries.
 */
export const queryKeysCustomField = {
  base: () => 'customFields' as const,
  getAll: (queryParams: Partial<CustomFieldsQueryParams>) =>
    [queryKeysCustomField.base(), 'list', queryParams] as const,
  getAllInfinite: (queryParams: Partial<CustomFieldsQueryParams>) =>
    [queryKeysCustomField.base(), 'list', 'infinite', queryParams] as const,
  getAllLegacy: () => [queryKeysCustomField.base(), 'list', 'LEGACY'] as const,
  getBulk: (ids: UUID[]) =>
    [queryKeysCustomField.base(), 'bulk', { ids }] as const,
  getOption: (customFieldId: UUID, customFieldOptionId: UUID) =>
    [
      queryKeysCustomField.base(),
      'option',
      { customFieldId, customFieldOptionId },
    ] as const,
  getOptions: (customFieldId: UUID) =>
    [queryKeysCustomField.base(), 'options', { customFieldId }] as const,
} as const;
