const i18nMap = {
  carrier: 'Spedition',
  'internal-delivery': 'Interne Lieferung',
  none: 'Nicht in diese Lieferung involviert',
  recipient: 'Eingehende Lieferung',
  supplier: 'Ausgehende Lieferung',
};

export const getProcessCategory = (processRolesOfUserCompany) => {
  const { carrier, recipient, supplier } = processRolesOfUserCompany;

  if (supplier && recipient) {
    return 'internal-delivery';
  }

  if (supplier) {
    return 'supplier';
  }

  if (recipient) {
    return 'recipient';
  }

  // The order of the if statements is important!
  // (e.g. if user company is supplier and carrier, then process category is supplier)
  if (carrier) {
    return 'carrier';
  }

  return 'none';
};
