import { isObject } from 'lodash-es';

import { getNestedProperty } from '~/utils/object';
import { getBiggestUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

/**
 * Calculates the total weight of a list of articles.
 * Aggregates the values and returns the total in the biggest unit from the data.
 *
 * @param {Array<Object>} articles - An array of articles with weight information.
 * @return {string|null} The total weight formatted as a string with the unit abbreviation, or null if no weight information is available.
 */

export const calculateTotalWeight = (articles, path = 'weight') => {
  if (!articles || articles.length === 0) {
    return null;
  }

  const articleWeights = articles
    .map((article) => {
      const nestedValue = getNestedProperty(article, path);

      return nestedValue && isObject(nestedValue)
        ? {
            unit: nestedValue.unit,
            value: nestedValue.value,
          }
        : null;
    })
    .filter((weight) => weight?.value && weight.unit)
    .map(({ unit, value }) => ({
      unit,
      value,
    }));

  if (articleWeights.length === 0) {
    return null;
  }

  const targetUnit = getBiggestUnit(articleWeights.map(({ unit }) => unit));

  if (!targetUnit) {
    return null;
  }

  const totalWeightValue = articleWeights.reduce(
    (total, { value, unit }) =>
      total + UnitUtils.calculateWeightInTargetUnit(value, unit, targetUnit),
    0,
  );

  if (totalWeightValue === 0) {
    return null;
  }

  const formattedTotalWeight =
    UnitUtils.roundAndFormatDe_safe(totalWeightValue);

  if (formattedTotalWeight === null) {
    return null;
  }

  return [
    formattedTotalWeight,
    UnitUtils.getAbbreviatedUnitString(targetUnit),
  ].join(' ');
};
