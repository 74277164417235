import { type DateRange } from '~/types/common';

import { type DeliveryNoteAnalyticsData } from '~/data/deliveryNote';

import { getOptimallyDistinctColor } from '~/utils/color';

import { MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED } from '../constants';
import { type AggregationInterval } from '../types';

import { getAggregationDatesFromDateRange } from './getAggregationDatesFromDateRange';

/**
 * Generates time series chart data for delivery note analytics, formatted for Chart.js.
 *
 * @param analyticsData - Array of delivery note analytics containing article names and their corresponding values
 * @param dateRange - Date range for the time series data
 * @param aggregationInterval - Interval for data aggregation (e.g., daily, weekly, monthly)
 * @returns Array of chart datasets, each representing an article's time series data.
 *          Limited to MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED entries.
 *          Each dataset includes:
 *          - Styling properties (backgroundColor, borderColor, borderWidth)
 *          - Time series data points with x (date) and y (value) coordinates
 *          - Article label and stack configuration for Chart.js
 */
export const generateTimeSeriesChartData = (
  analyticsData: DeliveryNoteAnalyticsData[],
  dateRange: DateRange,
  aggregationInterval: AggregationInterval,
) => {
  const graphedDates = getAggregationDatesFromDateRange(
    dateRange,
    aggregationInterval,
  );

  if (!analyticsData?.length) {
    // Always return the labels so we can render the chart, even if it doesn't have any data.
    return {
      datasets: [],
      labels: graphedDates.map((date) => date.toDate()),
    };
  }

  const renderedData = analyticsData.slice(
    0,
    MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED,
  );

  const totalColors = renderedData.length;

  return {
    datasets: renderedData.map(({ article, values }, index) => ({
      backgroundColor: getOptimallyDistinctColor(index, totalColors),
      borderColor: 'white',
      borderWidth: {
        top: index < analyticsData.length - 1 ? 1 : 0, // Add white border on top of first bar to create a gap between the bars.
      },
      data: graphedDates.map((date, index) => ({
        x: date.toDate(),
        y: values[index] ?? null,
      })),
      label: article,
      stack: 'arbitrary-stack-key', // required for stacking values in bar chart
      yAxisID: 'yAxisID',
    })),
  };
};
