import { DashboardTestIds } from '~/constants/test-ids';

export const TAB = {
  DASHBOARD: {
    CHARTS: {
      INDEX: 0,
      NAME: 'Diagramme',
      TESTID: DashboardTestIds.CHARTS,
    },
    REPORT: {
      INDEX: 1,
      NAME: 'Berichte',
      TESTID: DashboardTestIds.REPORT,
    },
  },
  DELIVERY: {
    CHANGES: {
      ID: '6d2815cd-ff12-42ef-b4ff-9953a395cd27', // assign stable UUID
      INDEX: 1,
      NAME: 'Änderungen',
    },
    LIST: {
      INDEX: 0,
      NAME: 'Alle Lieferungen',
    },
    UNASSIGNED_DELIVERY_NOTES: {
      ID: '8898ca6d-fee8-4a69-8e42-6c18f319681a', // assign stable UUID
      INDEX: 2,
      NAME: 'Nicht zugeordnet',
    },
  },
};
