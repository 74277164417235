import { apiUrl, environment } from '~/constants/environment';

const { hostname } = globalThis.location;

export const isLocalhost = Boolean(
  hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    /^127(?:\.(?:25[0-5]|2[0-4]\d|[01]?\d\d?)){3}$/.test(hostname),
);

export const isDevelopmentEnvironment = environment === 'development';

export const isDevelopmentStagingEnvironment =
  environment === 'development' || environment === 'staging';

export const isProductionEnvironment = environment === 'production';

const SUBDOMAIN_API_MAP = {
  beta: 'BETA',
  dev: 'DEV',
  staging: 'STAGING',
  test: 'TEST',
} as const;

const getApiFromSubdomain = (url: string) => {
  if (!url) {
    return 'PROD';
  }

  const regex = /app\.([^.]+)\.vestigas/;
  const match = regex.exec(url);

  const subdomain = match?.[1]?.toLowerCase() ?? 'production';

  return (
    SUBDOMAIN_API_MAP[subdomain as keyof typeof SUBDOMAIN_API_MAP] ?? 'PROD'
  );
};

export const activeApiName = `${getApiFromSubdomain(apiUrl)} API`;
