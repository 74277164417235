export const HomeOutlinedIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M18 8.535L25.5 15.285V27H22.5V18H13.5V27H10.5V15.285L18 8.535ZM18 4.5L3 18H7.5V30H16.5V21H19.5V30H28.5V18H33L18 4.5Z"
      fill="currentColor"
    />
  </svg>
);
