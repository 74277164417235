import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';
import { ROUTE } from '~/constants/Route';

import store from '~/redux/store';
import { replaceUser, replaceUsers, setUsersLoading } from '~/redux/usersSlice';

import Company from '~/models/masterdata/Company';
import PermissionGrant from '~/models/masterdata/PermissionGrant';
import User from '~/models/masterdata/User';

import axios from '~/utils/api-client';
import Log from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';
import RouteUtils from '~/utils/routeUtils';
import UserUtils from '~/utils/userUtils';

import CacheService from './cache.service';

const API_URL = apiUrl + '/user';

class UserService {
  constructor() {
    this.usersLoading = LOADING_STATE.NOT_LOADED;
    this.users = [];
    this.usersBulk = [];
  }

  async getData() {
    return axios
      .get(API_URL + '/data', { params: { include_company_info: true } })
      .then((response) => {
        if (response?.status === 200) {
          return response?.data;
        }

        throw response;
      });
  }

  async getAllUsers() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data?.users?.map((item) => new User(item)) ?? [];
    });
  }

  getUserById = async (userId) => {
    return this.getUser(userId, true) ?? null;
  };
  getUser = async (userId, ignoreCache = true) => {
    const url = API_URL + '/' + userId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response.status !== 200) {
          Log.warn('GET /user did not return 200', { status: response.status });
        }

        const user = new User(response.data);
        CacheService.setCached(url, user);

        store.dispatch(replaceUser(user));

        return user;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  async getCompany() {
    return axios.get(API_URL + '/company').then((response) => {
      if (response?.status === 200) {
        return new Company(response?.data);
      }

      throw response;
    });
  }

  async createUser(body) {
    return axios.post(apiUrl + '/admin/users', body).then((response) => {
      return response.data?.id;
    });
  }

  async createUsersViaCSV(file) {
    const formData = new FormData();
    formData.append('user_data', file);

    return axios
      .post(apiUrl + '/admin/users_csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data.ids;
      });
  }

  async updateUser(id, body) {
    return axios.put(apiUrl + '/admin/users/' + id, body);
  }

  async updateDeliveryTabs(tabs) {
    return axios.post(apiUrl + '/user/settings', {
      webapp_delivery_tabs: tabs,
    });
  }

  async updateDashboardFilterGroups(dashboardFilterGroups) {
    return axios.post(apiUrl + '/user/settings', {
      webapp_dashboard_filter_groups: dashboardFilterGroups,
    });
  }

  async updateDeliveryFilterGroups(deliveryFilterGroups) {
    return axios.post(apiUrl + '/user/settings', {
      webapp_delivery_filter_groups: deliveryFilterGroups,
    });
  }

  async updateInvoiceFilterGroups(invoiceFilterGroups) {
    return axios.post(apiUrl + '/user/settings', {
      webapp_invoice_filter_groups: invoiceFilterGroups,
    });
  }

  // Bug: When user updates user actions from two different tabs, the entries will overwrite each other.
  // As this is an edge case and the bug fix would be rather complex, it is accepted as it is.
  async updateUserActions(userActions) {
    return axios.post(apiUrl + '/user/settings', {
      webapp_user_actions: userActions,
    });
  }

  /**
   * Update the settings of the CURRENT (your own) user.
   * @see https://app.dev.vestigas.com/redoc#tag/User/operation/update_user_settings_user_settings_post
   */
  async updateOwnUserSettings(settings = {}) {
    return axios.post(`${apiUrl}/user/settings`, settings);
  }

  getUserCompany(user_id) {
    const url = apiUrl + '/user/' + user_id + '/company';

    const [cachedValue, error] = CacheService.getCached(url);
    if (cachedValue) {
      return Promise.resolve(cachedValue);
    }

    if (error) {
      return Promise.reject(error);
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'GET user company did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }

        const company = new Company(response?.data);
        CacheService.setCached(url, company);
        return company;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  }

  async uploadProfilePicture(picture) {
    return axios
      .put(API_URL + '/profile_picture', picture, {
        headers: { 'Content-Type': picture.type },
      })
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn(
            'PUT profile picture did not return 200',
            { status_code: response?.status },
            Log.BREADCRUMB.HTTP_NOT_200.KEY,
          );
        }
      });
  }

  async deleteProfilePicture() {
    return axios.delete(API_URL + '/profile_picture');
  }

  userIsAuthorizedForPage(pageUrl, userPermissions, featureFlags) {
    if (
      pageUrl === ROUTE.USER_METRICS.ROUTE
      // The entire UserMetrics feature can not be implemented with the current paginated API endpoints.
      // Also, nobody uses it.
      // && !UserUtils.isUserMetricsAllowedUser()
      // && !featureFlags?.userMetrics
    ) {
      return false;
    }

    if (
      RouteUtils.getRoute(pageUrl)?.ONLY_ACCESS_BY_VESTIGAS_SUPPORT &&
      !UserUtils.isVestigasAccount()
    ) {
      return false;
    }

    let authorized = false;

    const requiredPermissions = this.getRequiredPermissions(pageUrl);

    if (requiredPermissions) {
      for (const userPermission of userPermissions) {
        for (const routePermission of requiredPermissions) {
          if (userPermission.includes(routePermission)) {
            authorized = true;
          }
        }
      }
    } else {
      authorized = true;
    }

    const { disablingFeatureFlag, requiredFeatureFlag } =
      this.getFeatureFlag(pageUrl);

    if (requiredFeatureFlag && !featureFlags?.[requiredFeatureFlag]) {
      return false;
    }

    if (disablingFeatureFlag && featureFlags?.[disablingFeatureFlag]) {
      return false;
    }

    return authorized;
  }

  getRequiredPermissions(pageUrl) {
    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);

    let requiredPermissions = [];

    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        requiredPermissions = ROUTE[item].PERMISSIONS;
      }
    }

    return requiredPermissions;
  }

  getFeatureFlag(pageUrl) {
    const featureFlag = {};

    const bestFittingUrl = RouteUtils.getBestFittingUrls(pageUrl);

    for (const [index, item] of Object.keys(ROUTE).entries()) {
      if (bestFittingUrl.includes(ROUTE[item].ROUTE)) {
        featureFlag.requiredFeatureFlag = ROUTE[item].FEATURE_FLAG;
        featureFlag.disablingFeatureFlag = ROUTE[item].DISABLING_FEATURE_FLAG;
      }
    }

    return featureFlag;
  }

  async getPermittedUsersOfEntity(
    entityId,
    loadEntityCallback,
    checkEntityExistenceCallback,
  ) {
    // Check if the entity (site or cost center) is accessible by the user.
    // If not, it is also not useful to call the endpoint to load the single entity via loadEntityCallback as it would just throw an error.
    // This case happens when you are logged in as a supplier and try to access the sites or cost centers of the recipient.
    const [entityById, error] = await promiseHandler(
      checkEntityExistenceCallback(entityId),
    );
    if (error) {
      throw error;
    }

    if (!entityById) {
      return [];
    }

    const permittedUsers = [];

    const [entity, error2] = await promiseHandler(loadEntityCallback(entityId));

    if (error2) {
      throw error2;
    }

    for (let index = 0; index < entity.permissionGrantsFrom.length; index++) {
      const permissionGrantsFrom = entity.permissionGrantsFrom[index];

      if (
        permissionGrantsFrom.subjectType !==
        PermissionGrant.SUBJECT_TYPE.USER.KEY
      ) {
        continue;
      }

      const [user, error2] = await promiseHandler(
        this.getUserById(permissionGrantsFrom.subjectId),
      );

      if (error2) {
        throw error2;
      }

      if (
        user?.canReadDeliveryNotes() &&
        !permittedUsers.find((permittedUser) => permittedUser.id === user.id)
      ) {
        user.permissionGrantsOn = user.permissionGrantsOn.filter(
          (permissionGrant) => permissionGrant.entityId === entityId,
        );
        permittedUsers.push(user);
      }
    }

    return permittedUsers;
  }

  loadUsers = async () => {
    // to not load users again when they are already loading or have already been loaded
    if (store.getState().users?.usersLoading !== LOADING_STATE.NOT_LOADED) {
      return;
    }

    this.refreshUsers();
  };
  refreshUsers = async () => {
    store.dispatch(setUsersLoading(LOADING_STATE.LOADING));

    const [users, error] = await promiseHandler(this.getAllUsers());

    if (error) {
      store.dispatch(setUsersLoading(LOADING_STATE.FAILED));
      Log.error('Failed to refresh users.', error);
      Log.productAnalyticsEvent(
        'Failed to load users',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUsers(users));
  };
  refreshUser = async (userId) => {
    const [user, error] = await promiseHandler(this.getUser(userId, true));

    if (error) {
      Log.error('Failed to refresh user. id: ' + userId, error);
      Log.productAnalyticsEvent(
        'Failed to load user',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceUser(user));
  };
  loadUsersBulk = async (ids) => {
    return axios.post(API_URL + '/query/bulk', { ids }).then((response) => {
      this.usersBulk = response.data.items.map((item) => new User(item));
    });
  };
  getUserFromUsersBulk = (userId) => {
    return this.usersBulk.find((user) => user.id === userId) ?? null;
  };
}

export default new UserService();
