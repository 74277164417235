import { dateUtils, parseDate } from '~/utils/dateUtils';

export const SELECTABLE_COLUMNS = [
  {
    field: 'processState',
    headerName: 'Status',
  },
  {
    field: 'dlnAcceptState',
    headerName: 'Angenommen',
  },
  {
    field: 'billedState',
    headerName: 'Abgerechnet',
  },
  {
    field: 'dlnDate',
    headerName: 'LFS-Datum',
    type: 'date',
    valueFormatter: (value: Date) =>
      dateUtils.getFormattedDate_safe(value, dateUtils.DATE_FORMAT.DD_MM_YYYY),
    valueGetter: (value: string) => parseDate(value),
  },
  {
    field: 'dlnNr',
    headerName: 'LFS-Nr.',
  },
  {
    field: 'supplierName',
    headerName: 'Lieferant',
  },
  {
    field: 'recipientName',
    headerName: 'Abnehmer',
  },
  {
    field: 'confirmedSiteName',
    headerName: 'Bestätigter Lieferort',
  },
  {
    field: 'supplierAssignedSiteName',
    headerName: 'Lieferort (Lieferantenbezeichnung)',
  },
  {
    field: 'confirmedAccountingReferenceName',
    headerName: 'Kostenstelle',
  },
  {
    field: 'articleNumber',
    headerName: 'Artikel-Nr.',
  },
  {
    field: 'articleName',
    headerName: 'Artikel',
  },
];
