import { useMutation } from '@tanstack/react-query';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import { type FilterConfig } from './types';

export type ExportFormatPdf = 'pdf' | 'zip';

type DeliveryNotePdfExportParams = {
  assetIds?: UUID[] | undefined;
  filterConfig?: FilterConfig | undefined;
  format?: ExportFormatPdf;
};

export type ExportPdfResponse = {
  message: string;
  requestId: UUID;
};

/**
 * Sends a request to initiate generation of PDF download of delivery notes.
 *
 * @param {DeliveryNotePdfExportParams} searchParams - Parameters for filtering and configuring the PDF export
 * @returns {Promise<ExportPdfResponse>} The response containing request_id
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/request_delivery_note_pdf_export_asset_delivery_note_export_pdf_request_post
 */
export const requestDeliveryNotePdfExport = async ({
  assetIds,
  filterConfig,
  format = 'zip',
}: DeliveryNotePdfExportParams) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.REQUEST_PDF_EXPORT(), {
        json: snakecaseKeys({
          assetIds: assetIds ?? null,
          filterConfig: filterConfig ?? null,
          format,
        }),
      })
      .json<ExportPdfResponse>();

    return response;
  } catch (error) {
    console.error('Failed to request PDF export of delivery notes:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for requesting PDF export of delivery notes.
 *
 * @returns {UseMutationResult<ExportPdfResponse, Error, DeliveryNotePdfExportParams>}
 */
export const useMutationRequestDeliveryNotePdfExport = () => {
  return useMutation({
    mutationFn: async ({
      assetIds,
      filterConfig,
      format = 'zip',
    }: DeliveryNotePdfExportParams) =>
      requestDeliveryNotePdfExport({
        assetIds,
        filterConfig,
        format,
      }),
  });
};
