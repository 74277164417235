import { createSelector } from '@reduxjs/toolkit';

export const selectFilters = (state) => state.filters;

export const selectDashboardFilters = createSelector(
  [selectFilters],
  (filters) => ({
    dateRange: filters.dashboard_dateRange,
    filterGroups: filters.dashboard_filterGroups,
    filterRows: filters.dashboard_filterRows,
    individualDateRange: filters.dashboard_individualDateRange,
    oldestFilteredDlnDate: filters.oldestFilteredDlnDate,
    selectedAcceptState: filters.dashboard_selectedAcceptState,
    selectedArticle: filters.dashboard_selectedArticle,
    selectedArticleNumber: filters.dashboard_selectedArticleNumber,
    selectedCategory: filters.dashboard_selectedCategory,
    selectedCostCenter: filters.dashboard_selectedCostCenter,
    selectedCostCenters: filters.selectedCostCenters,
    selectedCustomFields: filters.dashboard_selectedCustomFields,
    selectedDateRange: filters.dashboard_selectedDateRange,
    selectedFilterGroup: filters.dashboard_selectedFilterGroup,
    selectedFromSite: filters.dashboard_selectedFromSite,
    selectedOuId: filters.dashboard_selectedOuId,
    selectedPermittedCostCenters:
      filters.dashboard_selectedPermittedCostCenters,
    selectedPermittedToSites: filters.dashboard_selectedPermittedToSites,
    selectedPredefinedDateRange: filters.dashboard_selectedPredefinedDateRange,
    selectedProcessState: filters.dashboard_selectedProcessState,
    selectedRecipient: filters.dashboard_selectedRecipient,
    selectedSettledStatus: filters.dashboard_selectedSettledStatus,
    selectedSites: filters.selectedSites,
    selectedSupplier: filters.dashboard_selectedSupplier,
    selectedTab: filters.dashboard_tab,
    selectedToSiteRecipient: filters.dashboard_selectedToSiteRecipient,
    selectedToSiteSupplier: filters.dashboard_selectedToSiteSupplier,
    selectedToSiteSupplierTradeContact:
      filters.dashboard_selectedToSiteSupplierTradeContact,
    selectedUnit: filters.dashboard_selectedUnit,
  }),
);
