import { cn } from '~/utils/tailwind';

export const EmptyStateIllustration = ({ className = '' }) => (
  <svg
    className={cn('h-32', className)}
    viewBox="0 0 128 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M41.3805 126.431C50.393 131.863 56.8224 129.608 59.2839 125.252C64.044 116.828 79.8712 99.6019 104.953 93.7796C138.172 86.0682 131.234 65.5931 110.813 54.8487C90.3922 44.1043 75.123 29.2375 77.434 13.0724C79.745 -3.0927 56.8475 -8.66532 46.6647 22.4308C40.0843 42.5264 28.2918 47.91 20.7719 49.2042C13.6846 50.4238 5.932 51.9541 2.15144 58.0716C-2.43602 65.4943 -0.835215 73.0028 18.3715 78.1527C45.5892 85.4504 21.1797 114.255 41.3805 126.431Z"
      fill="white"
      fillOpacity="0.05"
    />
    <g className="animate-float">
      <path
        d="M17.9643 91.2099C18.3457 90.9915 22.6505 89.1691 44.0662 99.153C44.0038 99.238 43.9773 99.2781 43.9773 99.2781C43.9773 99.2781 21.7136 106.776 17.3514 97.5391C16.0488 94.7893 16.4221 92.8958 17.9271 91.2312L17.9643 91.2099Z"
        fill="#424242"
      />
      <path
        d="M89.9442 94.988C85.2914 98.8995 77.9949 101.739 70.9543 97.9632C72.0669 97.2523 73.2161 96.52 74.3881 95.7626C81.4586 91.2196 87.6865 94.2594 89.9442 94.988Z"
        fill="#424242"
      />
      <path
        d="M98.2784 46.8804C103.032 86.464 91.7351 93.6479 90.4775 94.7392C90.1134 95.0551 89.9658 95.0321 89.4153 94.8903L89.406 94.8957C87.0761 94.278 81.4588 91.2195 74.388 95.762C73.216 96.5194 72.0665 97.2517 70.9543 97.9626C63.7414 102.537 57.9167 105.761 53.7203 103.89C50.1609 102.074 46.9516 100.503 44.0663 99.1529C22.6506 89.1689 18.3458 90.9913 17.9644 91.2097C18.4069 90.9687 22.4667 88.7183 27.9332 84.7609C27.9518 84.7502 27.9704 84.7396 27.9744 84.725C35.2811 79.4297 45.0662 71.0851 52.0997 60.3507C56.8879 53.0513 61.5721 34.5466 70.61 30.9157C89.1847 23.9865 97.5072 38.8289 98.2784 46.8804Z"
        fill="#D1D1D1"
      />
      <path
        d="M77.7399 46.186C78.3165 44.6635 78.2603 43.231 77.6144 42.9864C76.9685 42.7418 75.9775 43.7777 75.4009 45.3002C74.8243 46.8227 74.8805 48.2552 75.5264 48.4998C76.1723 48.7444 77.1634 47.7085 77.7399 46.186Z"
        fill="#5B5B5B"
      />
      <path
        d="M89.5667 48.1285C89.9754 46.5526 89.7646 45.1346 89.0961 44.9612C88.4275 44.7879 87.5543 45.9248 87.1456 47.5008C86.737 49.0767 86.9477 50.4947 87.6163 50.6681C88.2849 50.8414 89.1581 49.7044 89.5667 48.1285Z"
        fill="#5B5B5B"
      />
      <path
        d="M82.3674 53.9147C82.2705 53.9146 82.1766 53.8814 82.1012 53.8206C82.0258 53.7598 81.9734 53.675 81.9528 53.5803C81.8772 53.2375 81.5404 52.2233 81.0063 52.0726C80.4723 51.9218 79.4247 52.5071 79.0009 52.8141C78.9097 52.8801 78.796 52.9073 78.6848 52.8895C78.5735 52.8717 78.4739 52.8104 78.4079 52.7192C78.3418 52.628 78.3147 52.5143 78.3325 52.403C78.3503 52.2918 78.4115 52.1922 78.5027 52.1262C78.6698 52.0052 80.1657 50.9529 81.2369 51.2552C82.3541 51.5704 82.7422 53.2133 82.7826 53.3997C82.7961 53.4617 82.7956 53.526 82.781 53.5877C82.7664 53.6495 82.7381 53.7073 82.6982 53.7567C82.6584 53.8061 82.608 53.8459 82.5507 53.8733C82.4934 53.9006 82.4307 53.9148 82.3672 53.9147H82.3674Z"
        fill="#5B5B5B"
      />
    </g>
  </svg>
);
