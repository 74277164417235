export const OutgoingInvoiceIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 22L4.5 20.5L6 22L7.5 20.5L9 22L10.1834 20.8166C10.0632 20.2298 10 19.6223 10 19C10 18.3126 10.0771 17.6432 10.223 17H6V15H10.9355C11.2974 14.2719 11.7549 13.5997 12.2917 13H6V11H14.8728C16.109 10.3609 17.5124 10 19 10C19.6874 10 20.3568 10.0771 21 10.223V2L19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22ZM6 9V7H18V9H6ZM19.0505 20.9133H14V17.0867H19.0505V14L24 19L19.0505 24V20.9133Z"
      fill="currentColor"
    />
  </svg>
);
