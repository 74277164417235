import { useCallback, useEffect, useState } from 'react';

const DEFAULT_PAGE_SIZE = 100;

export const useDashboardPagination = (searchBody) => {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const handlePaginationModelChange = useCallback(
    (newModel) => {
      if (
        newModel.page !== paginationModel.page ||
        newModel.pageSize !== paginationModel.pageSize
      ) {
        // Only update if it's a user-initiated change (not an internal DataGrid validation)
        setPaginationModel(newModel);
      }
    },
    [paginationModel],
  );

  useEffect(() => {
    // Show results from start when search parameters change
    setPaginationModel((previousModel) => ({
      ...previousModel,
      page: 0,
    }));
  }, [JSON.stringify(searchBody)]);

  return {
    handlePaginationModelChange,
    paginationModel,
  };
};
