/**
 * A noop function that does nothing.
 */
export const noop = () => {};

/**
 * Transforms a type `T` to improve TypeScript's IntelliSense readability.
 *
 * The `Prettify<T>` type takes an input type `T` and maps over all its keys.
 * This process helps to reformat the type `T` such that it is easier to read
 * and understand when inspected using TypeScript's type analysis tools
 *
 * @template T - The input type to be prettified.
 *
 * Usage:
 * type Example = {
 *   id: number;
 *   name: string;
 *   data: {
 *     date: Date;
 *     flag: boolean;
 *   };
 * };
 *
 * type PrettyExample = Prettify<Example>;
 * // `PrettyExample` will now have the same structure as `Example`,
 * // but may appear cleaner in IntelliSense tooltips.
 */
export type Prettify<T> = {
  [K in keyof T]: T[K];
};
