import { cloneDeep } from 'lodash-es';

import {
  setDashboard_selectedCustomFields,
  setDelivery_selectedCustomFields,
  setDeliveryList_filterModel,
} from '~/redux/filtersSlice';
import store from '~/redux/store';

import { getFilterContext } from '~/utils/filters';

import BackendFilter from './BackendFilter';
import { BOOLEAN_OPERATOR, FILTER_OPERATOR_DATAGRID } from './constants';
import FilterGroupFilter from './FilterGroupFilter';
import FilterProps from './FilterProps';
import MuiDataGridFilter from './MuiDataGridFilter';

export default class FilterNew {
  constructor(filter) {
    this.field = filter?.field ?? null; // to compare filters independent of the source (e.g. filter group or filter model)
    this.operator = filter?.operator ?? null;
    this.value = filter?.value ?? null;
    this.logicOperator = filter?.logicOperator ?? null;
    this.filterContext = filter?.filterContext ?? getFilterContext();
    this.filterProps = new FilterProps({
      field: this.field,
      filterContext: this.filterContext,
      operator: this.operator,
      value: this.value,
    });
  }

  static getActiveFiltersForPage(page) {
    switch (page) {
      case 'delivery': {
        return FilterNew.getActiveFiltersForDeliveryPage();
      }

      case 'dashboard': {
        return FilterNew.getActiveFiltersForDashboardPage();
      }

      case 'home': {
        // Not implemented yet. Empty result needed to not crash DateRangeSelect.
        return [];
      }

      default: {
        return [];
      }
    }
  }

  /**
   * Gets all active filters for the delivery page:
   * - query
   * - filter model
   * - filter groups
   *
   * @return {Array} Array of active filters for the delivery page.
   */
  static getActiveFiltersForDeliveryPage() {
    const activeFilters = [];

    const query = store.getState()?.filters?.delivery_query;
    if (query) {
      activeFilters.push(FilterNew.getParsedQueryFilter(query));
    }

    const filterModel = store.getState()?.filters?.deliveryList_filterModel;
    if (filterModel) {
      activeFilters.push(
        ...MuiDataGridFilter.getParsedMuiDataGridFilters(filterModel),
      );
    }

    const filterGroupFilters =
      FilterNew.getActiveFilterGroupFiltersForPage('delivery');

    activeFilters.push(...filterGroupFilters);

    return activeFilters;
  }

  /**
   * Gets all active filters for the dashboard page.
   *
   * @return {Array} Array of active filters for the dashboard page.
   */
  static getActiveFiltersForDashboardPage() {
    return FilterNew.getActiveFilterGroupFiltersForPage('dashboard');
  }

  static getActiveFilterGroupFiltersForPage(page) {
    const filterContext = getFilterContext({
      component: 'filterGroup',
      page,
    });

    function getValue(filterGroupFilter) {
      const getValueCallback =
        FilterProps.getGetValueCallbackFromFilterGroupFilterAndContext(
          filterGroupFilter,
          filterContext,
        );

      return getValueCallback();
    }

    const filterGroupFilters = FilterProps.getFilterGroupFiltersFromPage(
      page,
    ).filter(
      (filterGroupFilterKey) =>
        filterGroupFilterKey !==
        FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS,
    );

    const pageFilters = FilterNew.getActiveFilterGroupFilters(
      filterGroupFilters,
      filterGroupFilters.map(getValue),
      filterContext,
    );

    return [...pageFilters, ...FilterNew.getCustomFiltersForPage(page)];
  }

  static getActiveFiltersForFilterGroup(filterGroup, page) {
    const filterContext = getFilterContext({
      component: 'filterGroup',
      page,
    });

    const pageFilters = [];

    // Only parse filterModel if it exists and we're not on the dashboard page
    if (filterGroup.filterModel && page !== 'dashboard') {
      pageFilters.push(
        ...MuiDataGridFilter.getParsedMuiDataGridFilters(
          filterGroup.filterModel,
        ),
      );
    }

    const filterGroupFilters = Object.keys(filterGroup.filters || {}).filter(
      (filterGroupFilterKey) =>
        filterGroupFilterKey !==
        FilterGroupFilter.FILTER.SELECTED_CUSTOM_FIELDS,
    );

    const filterValues = filterGroupFilters.map(
      (filterGroupFilter) => filterGroup.filters?.[filterGroupFilter],
    );

    pageFilters.push(
      ...FilterNew.getActiveFilterGroupFilters(
        filterGroupFilters,
        filterValues,
        filterContext,
      ),
    );

    return [
      ...pageFilters,
      ...FilterNew.getCustomFilters(filterGroup.filters?.selectedCustomFields),
    ];
  }

  static getActiveFilterGroupFilters(
    filterGroupFilterKeys,
    values,
    filterContext,
  ) {
    return filterGroupFilterKeys
      .map((filterGroupFilterKey, index) => {
        const field = FilterProps.getFieldFromFilterGroupFilter(
          filterGroupFilterKey,
          filterContext,
        );
        const operator = FilterNew.OPERATOR.IS_ANY_OF;
        const value = values[index];

        return new FilterNew({
          field,
          filterContext,
          operator,
          value,
        });
      })
      .filter((filter) => !FilterNew.filterContainsEmptyValue(filter));
  }

  static getCustomFiltersForPage(page) {
    let key;
    switch (page) {
      case 'delivery': {
        key = FilterGroupFilter.REDUX_VARIABLE.DELIVERY_SELECTED_CUSTOM_FIELDS;

        return FilterNew.getCustomFilters(store.getState().filters[key]);
      }

      case 'dashboard': {
        key = FilterGroupFilter.REDUX_VARIABLE.DASHBOARD_SELECTED_CUSTOM_FIELDS;

        return FilterNew.getCustomFilters(store.getState().filters[key]);
      }

      default: {
        return [];
      }
    }
  }

  static getCustomFilters(selectedCustomFields) {
    return (
      selectedCustomFields
        ?.filter((filter) => filter?.filterValue?.length)
        ?.map(({ filterValue, key }) => {
          const operator = FilterNew.OPERATOR.IS_ANY_OF; // In the future, replace this with the applied operator in the filter group.
          // This can be hardcoded for now. In the future, this must be dynamic.
          const filterContext = getFilterContext({
            component: 'filterGroup',
            isCustomField: true,
            page: 'delivery',
          });

          return new FilterNew({
            field: key,
            filterContext,
            operator,
            value: filterValue,
          });
        }) ?? []
    );
  }

  static getParsedQueryFilter(query) {
    return new FilterNew({
      field: 'query',
      filterContext: getFilterContext({
        component: 'query',
        page: 'delivery',
      }),
      operator: FilterNew.OPERATOR.CONTAINS,
      value: query,
    });
  }

  /**
   * Returns an array of active filters that are not applicable.
   * An active filter is not applicable if it is not in the list of applicable backend filters.
   *
   * @param {Array} activeFilters - An array of active filters.
   * @return {Array} An array of active filters that are not applicable.
   */
  static getNonApplicableFilters(activeFilters) {
    return activeFilters.filter((activeFilter) => {
      const isSameAsApplicableBackendFilter =
        BackendFilter.getApplicableBackendFilters().some(
          (applicableBackendFilter) =>
            FilterNew.filtersAreSame(activeFilter, applicableBackendFilter),
        );

      return !isSameAsApplicableBackendFilter;
    });
  }

  /**
   * Removes non-applicable backend filters based on the provided page.
   *
   * @param {string} page - The page for which to remove non-applicable backend filters.
   *                        Must be one of 'delivery' or 'dashboard'.
   */
  static removeNonApplicableBackendFilters(page) {
    switch (page) {
      case 'delivery': {
        FilterNew.removeNonApplicableBackendFiltersForDeliveryPage();
        break;
      }

      case 'dashboard': {
        FilterNew.removeNonApplicableBackendFiltersForDashboardPage();
        break;
      }

      default: {
        break;
      }
    }
  }

  static removeNonApplicableBackendFiltersForDeliveryPage() {
    const activeFilters = FilterNew.getActiveFiltersForPage('delivery');
    const nonApplicableFilters =
      FilterNew.getNonApplicableFilters(activeFilters);

    FilterNew.removeNonApplicableBackendFiltersForFilterModel(
      nonApplicableFilters,
    );

    FilterNew.removeNonApplicableBackendFiltersForFilterGroup(
      nonApplicableFilters,
    );

    // Custom fields are not provided as backend filters yet.
    store.dispatch(setDelivery_selectedCustomFields([]));
  }

  static removeNonApplicableBackendFiltersForDashboardPage() {
    const activeFilters = FilterNew.getActiveFiltersForPage('dashboard');

    const nonApplicableFilters =
      FilterNew.getNonApplicableFilters(activeFilters);

    FilterNew.removeNonApplicableBackendFiltersForFilterGroup(
      nonApplicableFilters,
    );

    // Custom fields are not provided as backend filters yet.
    store.dispatch(setDashboard_selectedCustomFields([]));
  }

  static removeNonApplicableBackendFiltersForFilterGroup(nonApplicableFilters) {
    for (const nonApplicableFilter of nonApplicableFilters) {
      // Custom fields are reset separately for now.
      if (nonApplicableFilter.filterContext.isCustomField) {
        continue;
      }

      nonApplicableFilter.filterProps.resetValueCallback();
    }
  }

  static removeNonApplicableBackendFiltersForFilterModel(nonApplicableFilters) {
    // Only applies to the delivery page yet.
    // -----------------------------
    // This function should actually not be necessary because the filter model should be reset in
    // nonApplicableFilter.filterProps.resetValueCallback();
    // -----------------------------
    // This leads to a bug though: The backend endpoints GET /search and /search/count are called multiple times.
    // Once with the outdated filter model and once with the reset filter model.
    // This is because in componentDidUpdate in DeliveryList.js, there are two props changes detected:
    // One for the date range change and one for the filter model change.
    // -----------------------------
    // Because the first backend call is made with the outdated filter model, it fails with an 422 error.
    // As a workaround, this function is called as it seems to work like this (but I don't know exactly why).
    // -----------------------------
    // The actual source problem of this bug is that in in componentDidUpdate in DeliveryList.js, the detection of
    // the props changes is async and therefore we cant control when the backend calls are made. This must be refactored
    // so that there is only exactly one backend call when the user changes to the archive mode (and thus changes
    // the date range, the filter group filters and the filter model).
    // -----------------------------
    // Files to be changed when refactoring:
    // - DeliveryList.js -> componentDidUpdate
    // - FilterNew.js -> removeNonApplicableBackendFiltersForFilterGroup
    // - FilterNew.js -> removeNonApplicableBackendFiltersForDeliveryPage
    // - FilterProps.js -> getResetValueCallback
    // TODO mgottelt refactor in the future

    const newFilterModel = cloneDeep(
      store.getState().filters?.deliveryList_filterModel,
    );

    // Remove all filters from the filter model that are in the list of the non-applicable filters.
    newFilterModel.items = newFilterModel.items.filter(
      (filter) =>
        !nonApplicableFilters.some(({ field }) => field === filter.field),
    );

    store.dispatch(setDeliveryList_filterModel(newFilterModel));
  }

  static fieldIsApplicableBackendFilter(field, page) {
    const filterContext = getFilterContext({ page });

    return FilterProps.getBackendFilterFromFieldAndContext(
      field,
      filterContext,
    );
  }

  /**
   * Checks if two filters are the same by comparing their field and operator.
   *
   * @param {Object} filterA - The first filter to compare.
   * @param {Object} filterB - The second filter to compare.
   * @return {boolean} True if the field and operator of both filters are identical, false otherwise.
   */
  static filtersAreSame(filterA, filterB) {
    const fieldIsIdentical = filterA.field === filterB.field;
    const operatorIsIdentical = filterA.operator === filterB.operator;

    return fieldIsIdentical && operatorIsIdentical;
  }

  /**
   * Checks if the filter contains an empty value.
   *
   * @param {Object} filter - The filter object to check for empty value.
   * @return {boolean} True if the filter contains an empty value, false otherwise.
   */
  static filterContainsEmptyValue(filter) {
    // Number 0 is NOT considered an empty value here.
    const isEmptyPrimitive =
      filter.value === '' ||
      filter.value === null ||
      filter.value === undefined;

    const isEmptyArray =
      Array.isArray(filter.value) && filter.value.length === 0; // TODO: this only checks for length, and would happily accept [' ', null, undefined] as not empty.

    return isEmptyPrimitive || isEmptyArray;
  }

  static OPERATOR = FILTER_OPERATOR_DATAGRID;
  static BOOLEAN_OPERATOR = BOOLEAN_OPERATOR;
}
