import { isObject } from 'lodash-es';

export default class Value {
  constructor(value, datetime, company) {
    this.value = value;
    this.datetime = datetime;
    this.company = company;
  }

  static isSimpleValue(data) {
    if (!isObject(data)) {
      return true;
    } // if data is a "real value" (e.g. String, Number, etc.) and not a nested object

    if (
      isObject(data) &&
      Array.isArray(data) &&
      data.length > 0 &&
      !isObject(data[0])
    ) {
      return true;
    } // if data is an array containing "real values" (e.g. Strings, such as comments array)

    return false;
  }
}
