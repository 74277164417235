import {
  AccountCircle as AccountCircleIcon,
  ArrowBack as ArrowBackIcon,
  FactCheck as FactCheckIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from '@xstate/store/react';
import { useAuth } from 'oidc-react';
import { memo, useMemo, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router';

import { redirectUrl } from '~/constants/environment';
import { ROUTE } from '~/constants/Route';
import { ComponentTestIds } from '~/constants/test-ids';

import { useQueryCompanyLogo } from '~/data/company';
import { useQueryUserProfilePicture } from '~/data/user';

import UserService from '~/services/user.service';

import { globalState } from '~/modules/applicationState';

import { LightTooltip } from '~/utils/componentUtils';
import {
  activeApiName,
  isDevelopmentStagingEnvironment,
  isProductionEnvironment,
} from '~/utils/environment';
import Log from '~/utils/logging';
import RouteUtils from '~/utils/routeUtils';

import { withErrorBoundary } from '~/ui/atoms';

import { GlobalSitesAndCostCentersFilter } from './GlobalSitesAndCostCentersFilter';

type P = {
  readonly drawerWidth: number;
};

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (property) => property !== 'drawerWidth',
})(({ drawerWidth }) => ({
  backgroundColor: 'white',
  color: '#343A4D',
  marginLeft: drawerWidth,
  paddingRight: '0 !important',
  transition: 'width 200ms ease-out',
  width: `calc(100% - ${drawerWidth}px)`,
}));

export const TopBar = withErrorBoundary(
  memo(({ drawerWidth }: P) => {
    const pageTitle = useSelector(
      globalState,
      (state) => state.context.pageTitle,
    );
    const userName = useSelector(
      globalState,
      (state) => state.context.currentUser?.name,
    );
    const position = useSelector(
      globalState,
      (state) => state.context.currentUser?.position,
    );
    const featureFlags = useSelector(
      globalState,
      (state) => state.context.currentUser?.featureFlags,
    );
    const permissions = useSelector(
      globalState,
      (state) => state.context.currentUser?.permissions,
    );
    const company = useSelector(
      globalState,
      (state) => state.context.currentUser?.company,
    );
    const hasLoggedInViaSso = useSelector(
      globalState,
      (state) => state.context.currentUser?.hasLoggedInViaSso,
    );

    const { data: companyLogo } = useQueryCompanyLogo(company?.id);

    const { data: profilePicture, isSuccess: isSuccessProfilePicture } =
      useQueryUserProfilePicture();

    const [anchorElement, setAnchorElement] = useState(null);
    const isMenuOpen = Boolean(anchorElement);

    const auth = useAuth();

    const navigate = useNavigate();
    const location = useLocation();

    const logout = () => {
      Log.productAnalyticsEvent('Logout', Log.FEATURE.AUTHENTICATION);

      // Clear all local storage items on logout.
      localStorage.clear();

      auth.signOutRedirect({
        post_logout_redirect_uri: redirectUrl,
      });
    };

    const handleMenuClose = (event) => {
      Log.productAnalyticsEvent('Close burger menu', Log.FEATURE.MENU);

      setAnchorElement(null);
    };

    const handleMenuOpen = (event) => {
      Log.productAnalyticsEvent('Open burger menu', Log.FEATURE.MENU);

      setAnchorElement(event.currentTarget);
    };

    const handleNavigateBack = () => {
      Log.productAnalyticsEvent('Navigate back', Log.FEATURE.MENU);

      navigate(-1);
    };

    const handleClickDeliveryDataQuality = () => {
      Log.productAnalyticsEvent(
        'Open delivery note data quality',
        Log.FEATURE.DELIVERY_NOTE,
      );
      navigate(
        `${ROUTE.DELIVERY_NOTE_DATA_QUALITY.ROUTE}/${RouteUtils.getUuid(location.pathname)}`,
      );
    };

    const menuId = 'account-menu';
    const renderMenu = useMemo(
      () => (
        <Menu
          keepMounted
          anchorEl={anchorElement}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          id={menuId}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          open={isMenuOpen}
          MenuListProps={{
            sx: { padding: 0 },
          }}
          onClose={handleMenuClose}
        >
          <div className="max-w-48">
            <div className="flex flex-col items-center gap-4 p-4 text-center">
              {isSuccessProfilePicture && profilePicture ? (
                <div className="size-24 rounded-full border-2 border-gray-200 bg-white p-0.5">
                  <img
                    src={profilePicture}
                    className="size-full rounded-full"
                  />
                </div>
              ) : (
                <AccountCircleIcon fontSize="large" className="my-4 size-16" />
              )}
              <div>
                {userName ? <div className="font-bold">{userName}</div> : null}
                {position ? <div>{position}</div> : null}
                {company?.name ? <div>{company?.name}</div> : null}
              </div>
            </div>
            <div className="border-b border-gray-200" />
          </div>
          {UserService.userIsAuthorizedForPage(
            ROUTE.DASHBOARD.ROUTE,
            permissions ?? [],
            featureFlags,
          ) ? (
            <MenuItem
              style={{ paddingBottom: 11, paddingTop: 11 }}
              component={Link}
              to="/dashboard"
              onClick={handleMenuClose}
            >
              Statistiken
            </MenuItem>
          ) : null}
          {/* workaround time: MenuItem with link to external site didn't work. Hence this beautiful solution of an a tag, styled the same as a MenuItem */}
          {hasLoggedInViaSso ? null : (
            <MenuItem>
              <a
                className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
                target="_blank"
                href="https://login.vestigas.com/auth/realms/vestigas/account/password"
                rel="noreferrer"
              >
                Passwort ändern
              </a>
            </MenuItem>
          )}
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS.ROUTE}
            onClick={handleMenuClose}
          >
            Benutzerdaten ändern
          </MenuItem>
          <MenuItem>
            <a
              className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters"
              target="_blank"
              href="https://vestigas.notion.site/Herzlich-Willkommen-in-einer-Welt-ohne-Lieferscheine-c4db904d5ff4405fa1feb1f5cf8bd0c2"
              rel="noreferrer"
            >
              Zur Schulung
            </a>
          </MenuItem>
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS.ROUTE}
            onClick={handleMenuClose}
          >
            Einstellungen/Admin
          </MenuItem>
          <MenuItem
            className="py-2"
            component={Link}
            to={ROUTE.SETTINGS_IMPRESSUM.ROUTE}
            onClick={handleMenuClose}
          >
            Impressum
          </MenuItem>
          <MenuItem
            className="border-t border-gray-200 pt-2"
            style={{ borderTop: '1px solid rgb(209, 213, 219)' }}
            onClick={logout}
          >
            Abmelden
          </MenuItem>
        </Menu>
      ),
      [
        anchorElement,
        company?.name,
        featureFlags,
        hasLoggedInViaSso,
        isMenuOpen,
        isSuccessProfilePicture,
        logout,
        permissions,
        position,
        profilePicture,
        userName,
      ],
    );

    return (
      <StyledAppBar position="fixed" drawerWidth={drawerWidth}>
        <Toolbar>
          <IconButton size="large" onClick={handleNavigateBack}>
            <ArrowBackIcon />
          </IconButton>
          <div className="flex flex-1 items-center gap-4 pl-4">
            <span
              data-testid={ComponentTestIds.TOPBAR.PAGETITLE}
              className="truncate text-3xl"
            >
              {pageTitle}
            </span>
            {RouteUtils.getBaseUrl(location.pathname) ===
              ROUTE.DELIVERY_NOTE.ROUTE && isDevelopmentStagingEnvironment ? (
              <div>
                <LightTooltip title="Öffne die Datenansicht der Lieferung und überprüfe, ob die Informationen der Lieferung der gewünschten Datenqualität entsprechen.">
                  <Button
                    variant="outlined"
                    startIcon={<FactCheckIcon />}
                    component="span"
                    onClick={handleClickDeliveryDataQuality}
                  >
                    Datenqualität prüfen
                  </Button>
                </LightTooltip>
              </div>
            ) : null}
          </div>
          <div className="flex items-center gap-4">
            <GlobalSitesAndCostCentersFilter />
            <div className="h-12 w-px bg-gray-300" />
            {isProductionEnvironment ? null : (
              <>
                <div className="text-sm font-bold text-green-600">
                  {activeApiName}
                </div>
                <div className="h-12 w-px bg-gray-300" />
              </>
            )}
            {companyLogo ? (
              <>
                <img className="hidden h-12 xl:flex" src={companyLogo} />
                <div className="hidden h-12 w-px bg-gray-300 xl:flex" />
              </>
            ) : null}
            <Button
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              startIcon={
                isSuccessProfilePicture && profilePicture ? (
                  <img
                    src={profilePicture}
                    className="size-8 rounded-full"
                    alt="Profile"
                  />
                ) : (
                  <AccountCircleIcon fontSize="large" />
                )
              }
              endIcon={<KeyboardArrowDownIcon fontSize="large" />}
              onClick={handleMenuOpen}
            >
              {userName ?? 'Menü'}
            </Button>
            {renderMenu}
          </div>
        </Toolbar>
      </StyledAppBar>
    );
  }),
);

TopBar.displayName = 'TopBar';
