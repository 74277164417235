import { type GridApi } from '@mui/x-data-grid-pro';

import { type ToolbarItem } from './DatagridServerDriven';

export const exportCsvClientSide: ToolbarItem = {
  id: 'export-csv-client-side',
  icon: 'excel',
  title: 'Daten exportieren',
  actions: [
    {
      name: 'Exportieren als CSV',
      onClick(apiRef: GridApi) {
        apiRef.current.exportDataAsCsv();
      },
    },
  ],
};
