/**
 * Check if a string represents an integer.
 *
 * @param {string} string - The string to check.
 * @returns {boolean} True if the string represents an integer, false otherwise.
 */
export const isInteger = (string: string): boolean => {
  /**
   * Regular expression to match strings that represent integers.
   * The regular expression matches strings that start with a digit
   * other than zero, followed by zero or more digits.
   * @type {RegExp}
   */
  const integerRegex = /^[1-9]\d*$/;

  // Check if the string matches the integer regex.
  return integerRegex.test(string);
};

/**
 * Calculate the precision (number of decimal places) of a number.
 *
 * @param {number} number - The number to calculate the precision for.
 * @returns {number} The precision of the number (number of decimal places).
 *
 * @example
 * precision(1.23) // returns 2
 * precision(1.0)  // returns 0
 * precision(1.234) // returns 3
 */
export const precision = (number: number): number => {
  if (!Number.isFinite(number)) {
    return 0;
  }

  // Start with multiplier 1 and 0 decimalPlaces.
  let multiplier = 1;
  let decimalPlaces = 0;

  // Keep multiplying by 10 until we get a whole number
  // For example, with 1.23:
  // 1st loop: 1.23 * 1 = 1.23    (rounded != original)
  // 2nd loop: 1.23 * 10 = 12.3   (rounded != original)
  // 3rd loop: 1.23 * 100 = 123   (rounded == original, done!)
  while (Math.round(number * multiplier) / multiplier !== number) {
    multiplier *= 10;
    decimalPlaces++;
  }

  return decimalPlaces;
};

/**
 * Check if a value is a valid number.
 *
 * @param {number} number - The value to check.
 * @returns {boolean} True if the value is a valid number, false otherwise.
 *
 * @example
 * isValidNumber(123)    // returns true
 * isValidNumber(NaN)    // returns false
 * isValidNumber(1.234)  // returns true
 */
export const isValidNumber = (number: number): boolean =>
  typeof number === 'number' && !Number.isNaN(number);
