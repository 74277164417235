import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useDebounce } from '@uidotdev/usehooks';
import {
  type ChangeEvent,
  forwardRef,
  type KeyboardEvent,
  memo,
  type ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import Log from '~/utils/logging';
import { cn } from '~/utils/tailwind';

export const EndAdornmentClear = ({
  onClick,
}: {
  readonly onClick: () => void;
}) => (
  <IconButton edge="end" size="medium" onMouseDown={onClick}>
    <ClearIcon fontSize="small" />
  </IconButton>
);

type P = {
  readonly autoFocus?: boolean;
  readonly debounceTime?: number;
  readonly disabled?: boolean;
  readonly onChange: (value: string) => void;
  readonly onFocus: () => void;
  readonly placeholder: string;
  readonly productAnalyticsFeature: string;
  readonly startAdornment?: ReactNode;
  readonly testId?: string;
  readonly value: string;
} & ComponentStyling;

export const SearchInput = memo(
  forwardRef<HTMLInputElement, P>(
    (
      {
        autoFocus,
        className,
        debounceTime = 600,
        disabled,
        onChange,
        onFocus,
        placeholder = 'Suchen ...',
        productAnalyticsFeature,
        startAdornment = (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        style,
        testId,
        value = '',
      }: P,
      forwardedRef,
    ) => {
      const [searchTerm, setSearchTerm] = useState(value);
      const debouncedSearchTerm = useDebounce(searchTerm, debounceTime);

      useEffect(() => {
        setSearchTerm(value);
      }, [value]);

      useEffect(() => {
        if (debouncedSearchTerm !== value) {
          Log.productAnalyticsEvent(
            'Update free text search',
            productAnalyticsFeature,
          );
          onChange(debouncedSearchTerm);
        }
      }, [debouncedSearchTerm, onChange, productAnalyticsFeature, value]);

      const handleChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          Log.info(
            'Change value of free text search',
            {
              from: searchTerm,
              to: newValue,
            },
            Log.BREADCRUMB.FILTER_CHANGE.KEY,
          );
          setSearchTerm(newValue);
        },
        [searchTerm],
      );

      const handleClear = useCallback(() => {
        Log.info(
          'Change value of free text search',
          {
            from: searchTerm,
            to: '',
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Remove free text search',
          productAnalyticsFeature,
        );
        setSearchTerm('');
      }, [searchTerm, productAnalyticsFeature]);

      const handleKeyDown = useCallback(
        (event: KeyboardEvent<HTMLInputElement>) => {
          if (event.key === 'Escape') {
            handleClear();
            event.stopPropagation();
          }
        },
        [handleClear],
      );

      const handleFocus = useCallback(() => {
        onFocus?.();
      }, [onFocus]);

      return (
        <TextField
          inputRef={forwardedRef}
          placeholder={placeholder}
          variant="outlined"
          value={searchTerm}
          autoComplete="off"
          size="small"
          className={cn('w-full bg-white pl-0', className)}
          disabled={disabled}
          autoFocus={autoFocus}
          style={style}
          slotProps={{
            input: {
              endAdornment: searchTerm ? (
                <EndAdornmentClear onClick={handleClear} />
              ) : null,
              startAdornment,
            },

            htmlInput: {
              'data-testid': testId,
            },
          }}
          onClick={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      );
    },
  ),
);

SearchInput.displayName = 'SearchInput';
