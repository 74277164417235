import { isEqualWith } from 'lodash-es';

/**
 * Retrieves a deeply nested property from an object.
 *
 * @param {Record<string, unknown>} obj - The object from which to retrieve the property.
 * @param {string} nestedProperty - The path to the nested property, separated by dots.
 * @return {unknown} The value of the nested property.
 */
export const getNestedProperty = <T>(
  object: Record<string, unknown>,
  nestedProperty: string,
): T | undefined => {
  const propertyPath = nestedProperty.split('.');

  return propertyPath.reduce((current, key) => current?.[key], object) as
    | T
    | undefined;
};

/**
 * Compares two objects/arrays for content equality, regardless of property/element order.
 *
 * @param {unknown} objectOne - First object/array to compare
 * @param {unknown} objectTwo - Second object/array to compare
 * @returns {boolean} True if contents are equal, false otherwise
 */
export const isObjectContentEqual = (
  objectOne: unknown,
  objectTwo: unknown,
): boolean =>
  isEqualWith(objectOne, objectTwo, (value1: unknown, value2: unknown) => {
    // Handle arrays - sort them for comparison
    if (Array.isArray(value1) && Array.isArray(value2)) {
      return (
        JSON.stringify([...value1].sort()) ===
        JSON.stringify([...value2].sort())
      );
    }

    // Handle objects - sort keys and compare
    if (
      value1 &&
      value2 &&
      typeof value1 === 'object' &&
      typeof value2 === 'object'
    ) {
      const sortedObject1 = JSON.stringify(value1, Object.keys(value1).sort());
      const sortedObject2 = JSON.stringify(value2, Object.keys(value2).sort());
      return sortedObject1 === sortedObject2;
    }

    // Return undefined for other types to let lodash handle the comparison
    return undefined;
  });
