import { CssBaseline } from '@mui/material';
import ms from 'ms';

import { getSearchBody, useQueryDeliveryNotes } from '~/data/deliveryNote';

import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { dayjs } from '~/utils/datetime';

import { AdOverlay } from './AdOverlay';
import { DLNList } from './DLNList';
import { OrderInProcessIcon } from './OrderInProcessIcon';
import { OrderReadyIcon } from './OrderReadyIcon';
import { PageHeader } from './PageHeader';
import { dlnsForProcessState } from './utils';

export const PickupScreenBayWa = () => {
  const searchParams = getSearchBody({
    filterGroups: {
      dateRange: [
        dayjs().subtract(1, 'month').toISOString(),
        dayjs().add(1, 'week').toISOString(),
      ],
    },
  });

  const { data, isFetching, isLoading } = useQueryDeliveryNotes(
    {
      ...searchParams,
      limit: 200,
    },
    {
      refetchInterval: ms('10s'),
      refetchIntervalInBackground: true,
      staleTime: ms('10s'),
    },
  );
  const deliveryRows = data?.rows;

  const inProcess = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.IMPORTED.STRING,
  );
  const readyForPickup = dlnsForProcessState(
    deliveryRows,
    DeliveryNote.PROCESS_STATE.READY_FOR_OUTPUT.STRING,
  );

  return (
    <div className="flex h-screen w-full flex-col overflow-hidden">
      <CssBaseline />
      <AdOverlay />
      <PageHeader />
      <div className="flex flex-1">
        <DLNList
          dlns={inProcess}
          status="In Bearbeitung"
          prompt="— Bitte im Wartebereich warten —"
          icon={
            <OrderInProcessIcon
              className="inline-block align-top"
              style={{
                height: '6vw',
                marginRight: '1vw',
                marginTop: '-0.9vw',
              }}
            />
          }
          style={{
            color: 'var(--grey500)',
          }}
          isLoading={isLoading}
        />
        <DLNList
          dlns={readyForPickup}
          status="Zur Abholung"
          prompt="Bitte einfahren!"
          icon={
            <OrderReadyIcon
              className="inline-block align-top"
              style={{
                height: '6vw',
                marginRight: '1vw',
                marginTop: '-0.9vw',
              }}
            />
          }
          style={{
            backgroundColor: 'var(--successLight)',
            color: 'var(--successBase)',
          }}
          isLoading={isFetching}
        />
      </div>
    </div>
  );
};
