import { dayjs } from './datetime';
import i18n from './i18n';

export const date4server = 'YYYY-MM-DD';
export const dateLocalizedShort = 'DD.MM.YY';
export const dateLocalizedShortest = 'DD.MM.YY';
export const dateLocalizedWMonthName = 'DD. MMMM YYYY';
export const dateLocalizedWOYear = 'DD. MMMM';
export const dateLocalizedWOYearShort = 'D.M.';
export const dateFull = 'DD.MM.YYYY';
export const dateFullDay = 'dddd, DD.MM.YYYY';
export const dateFullDayShort = 'dd, DD.MM.YYYY';
export const dateLong = 'DD.MM.YYYY';
export const dateMonthYear = 'M/YYYY';
export const dateMonthYearLong = 'MMMM YYYY';
export const dateMonthYearShort = 'M/YY';
export const datetime4server = "YYYY-MM-DD'T'HH:mm:ss'Z'";
export const datetime4datetimeInput = "YYYY-MM-DD'T'HH:mm";
export const timeHours = 'HH';
export const time24h = 'H:mm';

export type NumberSeparator = '.' | ',';

const currentLocale = i18n.language.startsWith('de') ? 'de' : 'en';
const thousandsSeparator = Number(1000).toLocaleString(currentLocale).charAt(1);
const decimalSeparator = Number(1.1).toLocaleString(currentLocale).charAt(1);

export const LOCALE_INFO = {
  name: currentLocale,
  decimal: decimalSeparator as NumberSeparator,
  thousand: thousandsSeparator as NumberSeparator,
  weekdayNames: dayjs.weekdays(),
  weekdayNamesShort: dayjs.weekdaysMin(),
};

/**
 * Regular expression for localized numbers.
 * Matches optional leading negative sign, initial sequence of one to three digits, optional groups of three digits separated by periods or commas (for thousand separators), and an optional decimal part with one or more digits.
 *
 * Examples:
 * 1234
 * -1234
 * 1,234
 * 1.234
 * 1,234.56
 * 1.234,56
 * 1234.5678
 * -1,234.5678
 */
export const localizedNumberRegex =
  '^-?[0-9]{1,3}(?:[.,][0-9]{3})*(?:[.,][0-9]+)?$';

const thousandsSeparatorRegex = new RegExp(`\\${LOCALE_INFO.thousand}`, 'g');
const decimalSeparatorRegex = new RegExp(`\\${LOCALE_INFO.decimal}`, '');

/**
 * Calculates the number of digits after the decimal point in a given number.
 *
 * @param {number} num - The input number to calculate the digits from.
 * @return {number} The number of digits after the decimal point.
 */
export const numberOfDigits = (number_: number) => {
  const digits = String(number_).split('.')[1] || '';
  return digits.length;
};

/**
 * Normalizes a string number by removing whitespace characters, replacing ndashes with a real minus character,
 * and replacing thousands separators with a dot.
 *
 * @param {string} number - The string number to be normalized.
 * @return {string} The normalized string number.
 */
export const normalizeStringNumber = (number: string) => {
  if (!number) {
    return number;
  }

  return number
    .replaceAll(/\s/g, '') // Remove all whitespace characters.
    .replace('–', '-') // Replace ndashes and similar looking characters with a real minus character.
    .replace(thousandsSeparatorRegex, '')
    .replace(decimalSeparatorRegex, '.');
};

export const isValidNumber = (value: string | number) => {
  if (typeof value === 'number') {
    return true;
  }

  const number = typeof value === 'string' ? Number.parseFloat(value) : value;

  return !(value === undefined || value === null || Number.isNaN(number));
};

/**
 * Parses a string or number into a number.
 *
 * @param {string | number} number - The input number to be parsed.
 * @return {number} The parsed number, or NaN if the input is not a valid number.
 */
export const parseNumber = (number?: string | number) => {
  if (typeof number === 'string') {
    const normalized = normalizeStringNumber(number);
    return Number.parseFloat(normalized);
  }

  return number ?? Number.NaN;
};

/**
 * Formats a number with a sign.
 *
 * @param {number} number - The number to be formatted.
 * @param {string} formatted - The formatted string representation of the number.
 * @param {boolean} showSign - Whether to show the sign of the number.
 * @param {boolean} [showAsNegative] - Whether to show the number as negative.
 * @return {string} The formatted string with a sign.
 */
export const formatSign = (
  number: number,
  formatted: string,
  showSign: boolean,
  showAsNegative?: boolean,
) => {
  if (number > 0 && showSign) {
    return `+ ${formatted}`;
  }

  if (number > 0 && showAsNegative) {
    return `- ${formatted}`;
  }

  return formatted.replace(/^-/, '- ');
};

/**
 * Formats a number into a string without using scientific notation.
 *
 * @param {number} value - The number to be formatted.
 * @return {string} The formatted number as a string.
 */
export const numberToFullWideString = (value: number) =>
  Number(value).toLocaleString([LOCALE_INFO.name, 'fullwide'], {
    maximumFractionDigits: 20, // Ensure that the number is not truncated.
    useGrouping: false, // Prevent grouping digits with a thousands separator.
  });

/**
 * Formats a number into a string with the specified number of digits and sign.
 *
 * @param {number | string} numberToFormat - The number or string to be formatted.
 * @param {number} [digits=0] - The number of digits to be displayed after the decimal point.
 * @param {boolean} [showSign=false] - Whether to show the sign of the number.
 * @param {boolean} [showAsNegative] - Whether to show the number as negative, even if it is positive.
 * @return {string} The formatted number as a string.
 */
export const formatNumber = (
  numberToFormat: number | string,
  digits = 0,
  showSign = false,
  showAsNegative?: boolean,
) => {
  if (numberToFormat == null) {
    return null;
  }

  const floatNumber =
    typeof numberToFormat === 'string'
      ? parseNumber(numberToFormat)
      : numberToFormat;
  const formatted = floatNumber.toLocaleString(LOCALE_INFO.name, {
    useGrouping: true,
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return formatSign(floatNumber, formatted, showSign, showAsNegative);
};
