import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { queryKeysDeliveryNote } from './queryKeys';

/**
 * Fetches the completed PDF download for the provided request ID.
 * Will poll the endpoint until the PDF is ready or timeout is reached.
 *
 * @param {string} requestId - The ID of the export request.
 * @returns {Promise<Blob>} The exported PDF or ZIP file as a Blob.
 *
 * @throws {Error} If the PDF export fails or times out
 * @see https://app.dev.vestigas.com/redoc#tag/Delivery-Note/operation/get_delivery_note_pdf_export_asset_delivery_note_export_pdf__request_id__get
 */
export const fetchExportedDeliveryNotePdf = async (
  requestId: string,
  maximumWaitingTime = ms('5m'),
) => {
  let data = null;
  let attempts = 0;
  const retryDelay = ms('5s');

  const maxAttempts = maximumWaitingTime / retryDelay;

  while (!data && attempts < maxAttempts) {
    try {
      const response = await vestigasApi.get(
        ENDPOINT.DELIVERY_NOTE.GET_PDF_EXPORT(requestId),
      );

      // If status is 202, PDF is still being generated
      if (response.status === 202) {
        attempts++;
        if (attempts >= maxAttempts) {
          console.error(
            'Failed to fetch exported PDF after multiple attempts - timeout',
          );
          throw new Error('Timeout waiting for PDF export');
        }

        await new Promise((resolve) => setTimeout(resolve, retryDelay));
        continue;
      }

      if (response.status === 200) {
        data = await response.blob();
        break;
      }
    } catch (error) {
      console.error('Failed to fetch exported PDF:', error);
      throw error;
    }
  }

  if (!data) {
    throw new Error('Failed to fetch exported PDF');
  }

  return data;
};

/**
 * React Query custom hook for fetching the exported delivery note PDF using request ID.
 *
 * @param {string} requestId - The ID of the export request.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Blob>} The result of the useQuery hook containing the exported delivery note PDF.
 */
export const useQueryDeliveryNotePdfExport = (
  requestId: string,
  options?: Omit<UseQueryOptions<Blob>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    enabled: Boolean(requestId),
    queryFn: async () => fetchExportedDeliveryNotePdf(requestId),
    queryKey: queryKeysDeliveryNote.getPdfExport(requestId),
    ...options,
  });
};
