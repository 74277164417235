export const DataExchangeQualificationIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M22.875 16.3491H19.5825L18.8747 20.4479L16.5881 16.3491H7.92188V14.0054H16.8281V6.646H22.875V16.3491ZM16.0781 3.55225V13.2554H7.41234L5.12531 17.3555L4.41703 13.2554H1.125V3.55225H16.0781ZM6.40894 10.0934C6.40894 9.61811 5.83439 9.38022 5.49839 9.71622C5.16239 10.0522 5.40028 10.6268 5.87555 10.6268C6.17016 10.6268 6.40898 10.388 6.40898 10.0934H6.40894ZM9.26573 10.0934C9.26255 9.619 8.68823 9.38453 8.35392 9.72104C8.01966 10.0576 8.25797 10.6304 8.73234 10.6304C9.02831 10.6304 9.2677 10.3894 9.26573 10.0934ZM12.1225 10.0934C12.1225 9.61811 11.548 9.38022 11.212 9.71622C10.8759 10.0522 11.1139 10.6268 11.5891 10.6268C11.8837 10.6268 12.1225 10.388 12.1225 10.0934Z"
      fill="currentColor"
    />
  </svg>
);
