import { validate as uuidvalidate } from 'uuid';

import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { dateUtils } from '~/utils/dateUtils';
import Log from '~/utils/logging';
import UnitUtils from '~/utils/unitUtils';

import Company from '../masterdata/Company';

import CustomFieldOption from './CustomFieldOption';

export default class CustomField {
  constructor(customField, customFieldOptions = []) {
    this.id = customField?.id ?? null;
    this.name = customField?.name ?? null;
    this.key = this.id?.slice(0, 36) ?? null; // Make sure the key only consists of a UUID
    this.displayName =
      customField?.display_name ?? customField?.displayName ?? null;
    this.level = customField?.level ?? CustomField.LEVEL.DELIVERY_NOTE.KEY;
    this.helpText = customField?.help_text ?? customField?.helpText ?? null;
    this.public = customField?.public ?? false;
    this.visibility =
      customField?.visibility ?? CustomField.VISIBILITY.ALWAYS.KEY;
    this.hasOptions =
      customField?.has_options ?? customField?.hasOptions ?? false;
    this.type = customField?.type ?? CustomField.TYPE.STRING.KEY;
    this.options = customFieldOptions.map(
      (option) => new CustomFieldOption(option),
    );
    this.hardcoded = customField?.hardcoded ?? false;
    this.allowedUnits =
      customField?.allowed_units ?? customField?.allowedUnits ?? [];
    this.defaultUnit =
      customField?.default_unit ?? customField?.defaultUnit ?? null;
    this.usedBy = customField?.usedBy ?? customField?.used_by ?? [];
  }

  static ValueIsEmpty(value) {
    return value === '' || value === null;
  }

  static getIdFromKey(key) {
    if (typeof key !== 'string') {
      return null;
    }

    const id = key.slice(0, 36);

    if (!uuidvalidate(id)) {
      return null;
    }

    return id;
  }

  static getCustomFieldTypes() {
    return Object.keys(CustomField.TYPE).map((x) => {
      return {
        disabled: CustomField.TYPE[x].DISABLED,
        id: CustomField.TYPE[x].KEY,
        name: CustomField.TYPE[x].STRING,
      };
    });
  }

  static getCustomFieldLevels() {
    return Object.keys(CustomField.LEVEL).map((x) => {
      return {
        id: CustomField.LEVEL[x].KEY,
        name: CustomField.LEVEL[x].STRING,
      };
    });
  }

  static getCustomFieldVisibilities() {
    return Object.keys(CustomField.VISIBILITY).map((x) => {
      return {
        id: CustomField.VISIBILITY[x].KEY,
        name: CustomField.VISIBILITY[x].STRING,
      };
    });
  }

  static getTypeString(key) {
    const type = Object.keys(CustomField.TYPE).find(
      (x) => CustomField.TYPE[x].KEY === key,
    );

    if (!type) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid custom field type: ' + key),
      );
      return null;
    }

    return CustomField.TYPE[type].STRING;
  }

  static getFormatter(key) {
    const type = Object.keys(CustomField.TYPE).find(
      (x) => CustomField.TYPE[x].KEY === key,
    );

    if (!type) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid custom field type: ' + key),
      );
      return null;
    }

    return CustomField.TYPE[type].FORMATTER;
  }

  static getLevelString(key) {
    const level = Object.keys(CustomField.LEVEL).find(
      (x) => CustomField.LEVEL[x].KEY === key,
    );

    if (!level) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid custom field level: ' + key),
      );
      return null;
    }

    return CustomField.LEVEL[level].STRING;
  }

  static getVisibilityString(key) {
    const visibility = Object.keys(CustomField.VISIBILITY).find(
      (x) => CustomField.VISIBILITY[x].KEY === key,
    );

    if (!visibility) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          'Invalid custom field visibility: ' + key,
        ),
      );
      return null;
    }

    return CustomField.VISIBILITY[visibility].STRING;
  }

  static getDifferentValues(customFieldA, customFieldB) {
    const differentValues = [];

    if (customFieldA?.id !== customFieldB?.id) {
      differentValues.push('ID');
    }

    if (customFieldA?.name !== customFieldB?.name) {
      differentValues.push('Name');
    }

    if (customFieldA?.key !== customFieldB?.key) {
      differentValues.push('Key');
    }

    if (customFieldA?.displayName !== customFieldB?.displayName) {
      differentValues.push('Anzeige-Name');
    }

    if (customFieldA?.type !== customFieldB?.type) {
      differentValues.push('Typ');
    }

    if (customFieldA?.hasOptions !== customFieldB?.hasOptions) {
      differentValues.push('Dropdown');
    }

    if (customFieldA?.level !== customFieldB?.level) {
      differentValues.push('Level');
    }

    if (customFieldA?.public !== customFieldB?.public) {
      differentValues.push('Öffentlich/Privat');
    }

    if (customFieldA?.visibility !== customFieldB?.visibility) {
      differentValues.push('Sichtbarkeit');
    }

    if (
      JSON.stringify(customFieldA?.options) !==
      JSON.stringify(customFieldB?.options)
    ) {
      differentValues.push('Dropdown Optionen');
    }

    if (
      JSON.stringify(customFieldA?.allowedUnits) !==
      JSON.stringify(customFieldB?.allowedUnits)
    ) {
      differentValues.push('Mögliche Einheiten');
    }

    if (
      JSON.stringify(customFieldA?.defaultUnit) !==
      JSON.stringify(customFieldB?.defaultUnit)
    ) {
      differentValues.push('Default Einheit');
    }

    if (
      JSON.stringify(customFieldA?.usedBy) !==
      JSON.stringify(customFieldB?.usedBy)
    ) {
      differentValues.push('In PDF-Lieferschein verwendet von den Firmen...');
    }

    return differentValues;
  }

  static VISIBILITY = {
    ALWAYS: {
      KEY: 'always',
      STRING: 'Immer',
    },
    NEVER: {
      KEY: 'never',
      STRING: 'Nie',
    },
    WHEN_SET: {
      KEY: 'when_set',
      STRING: 'Wenn vorhanden',
    },
  };
  static LEVEL = {
    ARTICLE: {
      KEY: 'item',
      STRING: 'Artikel',
    },
    DELIVERY_NOTE: {
      KEY: 'global',
      STRING: 'Lieferung',
    },
  };
  static TYPE = {
    BOOLEAN: {
      FORMATTER: (value) => (value ? 'Ja' : 'Nein'),
      KEY: 'bool',
      STRING: 'Boolean',
    },
    DATETIME: {
      FORMATTER: (value) =>
        dateUtils.getFormattedDate(
          value,
          dateUtils.DATE_FORMAT.DD_MM_YYYY__HH_mm,
        ),
      KEY: 'datetime',
      STRING: 'Datetime',
    },
    DICT: {
      DISABLED: true,
      KEY: 'dict',
      STRING: 'Dictionary', // Currently not supported. Will be added in the future.
      // FORMATTER: // We will need a formatter function
    },
    DOUBLE: {
      FORMATTER: (value) => UnitUtils.formatDe_safe(value),
      KEY: 'double',
      STRING: 'Double',
    },
    ENUMERATION: {
      // In this case, an ID is given that is mapped to the corresponding custom field option in DeliveryNote.js when initializing a dln.
      FORMATTER: (value) => value,

      KEY: 'enumeration',

      STRING: 'Enumeration',
    },
    LEGAL_ORGANIZATION: {
      FORMATTER(value) {
        const company = new Company(value);
        return company.name;
      },
      KEY: 'legal_organization',
      STRING: 'Legal Organization (Firma)',
    },
    STRING: {
      FORMATTER: (value) => value,
      KEY: 'string',
      STRING: 'String',
    },
    VALUE_MEASURE: {
      FORMATTER: (value) =>
        UnitUtils.formatValueUnitPairAsString_safe(
          value.value,
          value.measure,
          UnitUtils.getAbbreviatedUnitString,
        ),
      KEY: 'value_measure',
      STRING: 'Double + Einheit',
      // Actually, the formatter should be UnitUtils.formatValueUnitPair_safe(value.value, value.measure, UnitUtils.getAbbreviatedUnit)
      // However, this leads to the error "Uncaught Error: Objects are not valid as a React child" in delivery note metadata as there is most likely a bug with the logic that determined the dln changes.
      // As a pragmatic solution, just format the value to a string.
    },
  };
}
