/**
 * Paths that are excluded when calculating the dln diffs.
 * Also works recursively, e.g. article.billedItem is also excluded.
 */
export const MERGE_EXCLUDED_KEYS = [
  'acceptArticleSupplier',
  'acceptArticleCarrier',
  'acceptArticleRecipient',
  'acceptArticleOnBehalfSupplier',
  'acceptArticleOnBehalfCarrier',
  'acceptArticleOnBehalfRecipient',
  'acceptState',
  'acceptStateCarrier',
  'acceptStateRecipient',
  'acceptStateSupplier',
  'acceptStateOnBehalfSupplier',
  'acceptStateOnBehalfCarrier',
  'acceptStateOnBehalfRecipient',
  'assetChain',
  'billedItem',
  'category',
  'combinedState',
  'changesListIncludedPaths',
  'createdOn',
  'deliveryDate',
  'history',
  'id',
  'issuer',
  'mainArticle',
  'modifiedOn',
  'permittedCostCenters',
  'permittedToSites',
  'processCategory',
  'processRolesOfUserCompany',
  'processState',
  'recalculatedMain',
  'referencedInvoices',
  'settledStatus',
] as const;
