import { type UUID } from '~/types/common';

import { type DeliveryNoteSearchParams } from './types';
import { type DeliveryNoteReportSearchParams } from './useQueryDeliveryNoteReport';

/**
 * Query key factory for delivery note-related queries.
 */
export const queryKeysDeliveryNote = {
  base: () => 'deliveryNotes' as const,
  get: (deliveryNoteId: UUID) =>
    [queryKeysDeliveryNote.base(), 'detail', { deliveryNoteId }] as const,
  getAll: (searchParams: DeliveryNoteSearchParams) =>
    [queryKeysDeliveryNote.base(), 'list', searchParams] as const,
  getAllInfinite: () =>
    [queryKeysDeliveryNote.base(), 'list', 'infinite'] as const,
  getAnalytics: (searchParams: DeliveryNoteSearchParams) =>
    [queryKeysDeliveryNote.base(), 'analytics', searchParams] as const,
  getAttachment: (chainId: UUID, digest: string) =>
    [queryKeysDeliveryNote.base(), 'attachment', { chainId, digest }] as const,
  getChains: (deliveryNoteIds: UUID[]) =>
    [queryKeysDeliveryNote.base(), 'chains', { deliveryNoteIds }] as const,
  getExportExcel: (requestId: UUID) =>
    [queryKeysDeliveryNote.base(), 'exportExcel', { requestId }] as const,
  getFilterSuggestions: (searchParams: DeliveryNoteSearchParams) =>
    [queryKeysDeliveryNote.base(), 'filterSuggestions', searchParams] as const,
  getHandwrittenSignature: (chainId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'handwrittenSignature',
      { chainId },
    ] as const,
  getPdfExport: (requestId: UUID) =>
    [queryKeysDeliveryNote.base(), 'exportPdf', { requestId }] as const,
  getPermittedUserIds: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'permittedUsers',
      { deliveryNoteId },
    ] as const,
  getPermittedUsers: (siteIds: UUID[]) =>
    [queryKeysDeliveryNote.base(), 'permittedUser', { siteIds }] as const,
  getReport: (searchParams: DeliveryNoteReportSearchParams) =>
    [queryKeysDeliveryNote.base(), 'report', searchParams] as const,
  getSearchCount: (searchParams: DeliveryNoteSearchParams) =>
    [queryKeysDeliveryNote.base(), 'searchCount', searchParams] as const,
  getSharedWithUsers: (deliveryNoteId: UUID) =>
    [
      queryKeysDeliveryNote.base(),
      'sharedWithUsers',
      { deliveryNoteId },
    ] as const,
  getSyncGatewayChanges: (since: string) =>
    [queryKeysDeliveryNote.base(), 'updatesFeed', { since }] as const,
  getTemplates: () =>
    [queryKeysDeliveryNote.base(), 'template', 'list'] as const,
} as const;
