import { useDispatch } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';

import {
  replaceBrowsableInvoices,
  saveIncomingInvoices,
  setIncomingInvoicesLoading,
} from '~/redux/invoicesSlice';

import InvoicesService from '~/services/invoices.service';

import Log from '~/utils/logging';

/**
 * Custom hook for loading incoming invoices.
 *
 * This hook provides functionality to load incoming invoices either from
 * the window.redux_state (if available and successfully loaded) or by fetching
 * from the backend using InvoicesService.
 *
 * @returns {Object} An object containing the loadIncomingInvoices function.
 */
export const useLoadIncomingInvoices = () => {
  const dispatch = useDispatch();

  /**
   * Loads incoming invoices.
   *
   * If invoices are available in window.redux_state and successfully loaded,
   * it dispatches actions to update the Redux store with the loaded data.
   * Otherwise, it calls InvoicesService.getAllIncomingInvoices() to fetch the data.
   */
  async function loadIncomingInvoices() {
    if (
      /**
       * Opening an item in a new browser tab using BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab
       * passes the whole Redux state to the new tab as window.redux_state.
       * If that is the case, we don't need to load the data again from the backend,
       * but push the data from the window object into Redux state.
       */
      globalThis.redux_state &&
      globalThis.redux_state.invoices.incomingInvoicesLoading ===
        LOADING_STATE.SUCCEEDED
    ) {
      dispatch(
        saveIncomingInvoices(globalThis.redux_state.invoices.incomingInvoices),
      );
      dispatch(
        replaceBrowsableInvoices(
          globalThis.redux_state.invoices.browsableInvoices,
        ),
      );

      Log.info('Invoice state passed via window.redux_state.');

      return;
    }

    dispatch(setIncomingInvoicesLoading(LOADING_STATE.LOADING));

    // Load data of incoming invoices from the backend.
    InvoicesService.getAllIncomingInvoices().catch((error) => {
      dispatch(setIncomingInvoicesLoading(LOADING_STATE.FAILED));
    });
  }

  return {
    loadIncomingInvoices,
  };
};
