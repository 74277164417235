import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import {
  DataGridPro,
  useGridApiEventHandler,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { cloneDeep, debounce } from 'lodash-es';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ComponentTestIds } from '~/constants/test-ids';

import LocalStorageService from '~/services/localStorage.service';
import UserService from '~/services/user.service';

import { sortByKeyValues, subtract, unique } from '~/utils/array';
import DatagridUtils from '~/utils/datagridUtils';
import Log from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import {
  Footer,
  LoadingState,
  Pagination,
} from '~/ui/molecules/Datagrid/components';

import { GridToolbar } from './GridToolbar';
import { NoRowsOverlay } from './NoRowsOverlay';
import { orderColumns, sizeColumns } from './utils';

export const BasicTable = memo(
  ({
    checkboxSelection,
    className,
    columns,
    defaultHiddenColumns,
    defaultPinnedColumns,
    disableColumnFilter,
    disableColumnReorder,
    excelColumns,
    excelData,
    filterMode,
    filterModel,
    headerSelectionCheckboxProps,
    hideFooter,
    hideHeader,
    hideToolbar,
    initialState,
    isFetching,
    isLoading,
    loading,
    localStorageKey,
    logicOperators,
    multiplePdfDownload,
    noColumnsButton,
    noExportButton,
    noFilterButton,
    onExportCSVFromBackend,
    onExportExcelFromBackend,
    onExportInvoiceExcel,
    onFilterModelChange,
    onMapDirectDeliveryNote,
    onMultiPermissionGrantEdit,
    onPdfExport,
    onRequestDeliveryNoteSignature,
    onRowClick,
    onRowRightClick,
    onRowSelectionModelChange: propsOnRowSelectionModelChange,
    onShareDeliveryNote,
    onStateChange: propsOnStateChange,
    pageSize: propsPageSize,
    paginationMode = 'client',
    paginationText,
    pinnedColumnsCookieId,
    productAnalyticsFeature,
    rows,
    rowSelectionModel,
    selectAllRowsCount,
    sortingMode,
    testId,
    ...otherProps
  }) => {
    // Allow passing in an apiRef and set one internally if not provided
    const apiRef = otherProps.apiRef ?? useGridApiRef();

    const [columnOrderModel, setColumnOrderModel] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [columnWidthModel, setColumnWidthModel] = useState({});
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
      [],
    );
    const [filteredSortedRows, setFilteredSortedRows] = useState([]);
    const [pinnedColumns, setPinnedColumns] = useState(
      LocalStorageService.getObjectFromLocalStorage(pinnedColumnsCookieId) ??
        defaultPinnedColumns ?? { left: [], right: [] },
    );

    const pageSize = useMemo(() => propsPageSize ?? 100, [propsPageSize]);

    const debouncedUpdateUserSettings = useCallback(
      debounce(async (settings) => {
        await promiseHandler(
          UserService.updateOwnUserSettings({
            [localStorageKey]: settings,
          }),
        );
      }, 400),
      [localStorageKey],
    );

    // Store the settings in user's settings on the server and in localStorage
    const persistSettings = useCallback(
      ({ columnVisibilityModel, columnWidthModel, columnOrderModel }) => {
        const settings = {
          columnOrderModel,
          columnVisibilityModel,
          columnWidthModel,
        };

        LocalStorageService.setObjectAsLocalStorage(localStorageKey, settings);
        debouncedUpdateUserSettings(settings);
      },
      [debouncedUpdateUserSettings, localStorageKey],
    );

    useEffect(() => {
      // Load initial state from localStorage
      const data =
        LocalStorageService.getObjectFromLocalStorage(localStorageKey);

      const newState = {
        columnOrderModel: data?.columnOrderModel ?? [],
        columnVisibilityModel: data?.columnVisibilityModel ?? {},
        columnWidthModel: data?.columnWidthModel ?? {},
      };

      newState.columnVisibilityModel = hideDefaultColumns(
        newState.columnVisibilityModel,
      );

      setColumnOrderModel(newState.columnOrderModel);
      setColumnVisibilityModel(newState.columnVisibilityModel);
      setColumnWidthModel(newState.columnWidthModel);
    }, [localStorageKey]);

    useEffect(() => {
      setColumnVisibilityModel((previousModel) =>
        hideDefaultColumns(previousModel),
      );
    }, [defaultHiddenColumns]);

    const hideDefaultColumns = useCallback(
      (visibilityModel) => {
        const newColumnVisibilityModel = cloneDeep(visibilityModel);

        if (defaultHiddenColumns) {
          for (const column of defaultHiddenColumns) {
            if (newColumnVisibilityModel[column] === undefined) {
              newColumnVisibilityModel[column] = false;
            }
          }
        }

        return newColumnVisibilityModel;
      },
      [defaultHiddenColumns],
    );

    const orderedAndSizedColumns = useMemo(() => {
      const orderedColumns = orderColumns(
        columns,
        localStorageKey,
        disableColumnReorder,
      );

      return sizeColumns(orderedColumns, columnWidthModel);
    }, [columns, columnWidthModel, disableColumnReorder, localStorageKey]);

    const hiddenHeaderProps = hideHeader
      ? {
          columnHeaderHeight: 0,
          sx: {
            '& .MuiDataGrid-columnHeaders': {
              display: 'none !important',
            },
            '& .MuiDataGrid-toolbarContainer': {
              display: 'none !important',
              height: '0 !important',
              padding: '0 !important',
              visibility: 'hidden !important',
            },
          },
        }
      : null;

    const getPinnedColumns = useCallback(() => {
      if (pinnedColumns === null) {
        return (
          defaultPinnedColumns ?? {
            left: [],
            right: [],
          }
        );
      }

      return pinnedColumns;
    }, [pinnedColumns, defaultPinnedColumns]);

    const memoizedExcelData = useMemo(() => {
      if (!excelData?.length) {
        return [];
      }

      if (!filteredSortedRows?.length) {
        return excelData;
      }

      return sortByKeyValues(excelData, filteredSortedRows, 'id');
    }, [excelData, filteredSortedRows]);

    const getToolbar = useCallback(() => {
      if (hideToolbar) {
        return null;
      }

      return (
        <GridToolbar
          apiRef={apiRef}
          excelColumns={excelColumns}
          excelData={memoizedExcelData}
          multiplePdfDownload={multiplePdfDownload}
          noColumnsButton={noColumnsButton}
          noExportButton={noExportButton}
          noFilterButton={noFilterButton}
          productAnalyticsFeature={productAnalyticsFeature}
          onExportCSVFromBackend={onExportCSVFromBackend}
          onExportExcelFromBackend={onExportExcelFromBackend}
          onExportInvoiceExcel={onExportInvoiceExcel}
          onMapDirectDeliveryNote={onMapDirectDeliveryNote}
          onMultiPermissionGrantEdit={onMultiPermissionGrantEdit}
          onPdfExport={onPdfExport}
          onRequestDeliveryNoteSignature={onRequestDeliveryNoteSignature}
          onShareDeliveryNote={onShareDeliveryNote}
        />
      );
    }, [
      apiRef,
      excelColumns,
      memoizedExcelData,
      hideToolbar,
      multiplePdfDownload,
      noColumnsButton,
      noExportButton,
      noFilterButton,
      onExportCSVFromBackend,
      onExportExcelFromBackend,
      onExportInvoiceExcel,
      onMapDirectDeliveryNote,
      onMultiPermissionGrantEdit,
      onPdfExport,
      onRequestDeliveryNoteSignature,
      onShareDeliveryNote,
      productAnalyticsFeature,
    ]);

    const onColumnVisibilityModelChange = useCallback(
      (newModel) => {
        Log.info(
          'Change column visibility',
          {
            from: columnVisibilityModel,
            to: newModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column visibility',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnVisibilityModel(newModel);
        persistSettings({
          columnVisibilityModel: newModel,
          columnWidthModel,
          columnOrderModel,
        });
      },
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        debouncedUpdateUserSettings,
        persistSettings,
      ],
    );

    const onColumnWidthChange = useCallback(
      (event) => {
        const newColumnWidthModel = cloneDeep(columnWidthModel);
        newColumnWidthModel[event.colDef.field] = event.colDef.width;

        Log.info(
          'Change column width',
          {
            from: columnWidthModel,
            to: newColumnWidthModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column width',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnWidthModel(newColumnWidthModel);
        persistSettings({
          columnOrderModel,
          columnVisibilityModel,
          columnWidthModel: newColumnWidthModel,
        });
      },
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        persistSettings,
      ],
    );

    const onColumnOrderChange = useCallback(
      async (newColumnOrderModel) => {
        // Don't persist column order if we don't have all columns yet
        if (!columns?.length) {
          return;
        }

        const storedColumnOrderModel =
          LocalStorageService.getObjectFromLocalStorage(
            localStorageKey,
          )?.columnOrderModel;

        // Validate that new order contains all current columns
        const currentFields = new Set(columns.map((col) => col.field));
        const newOrderFields = new Set(newColumnOrderModel);
        const isValidOrder = [...currentFields].every((field) =>
          newOrderFields.has(field),
        );

        if (!isValidOrder) {
          return;
        }

        if (
          newColumnOrderModel &&
          JSON.stringify(newColumnOrderModel) ===
            JSON.stringify(storedColumnOrderModel)
        ) {
          return;
        }

        setColumnOrderModel(newColumnOrderModel);
        persistSettings({
          columnOrderModel: newColumnOrderModel,
          columnVisibilityModel,
          columnWidthModel,
        });

        Log.info(
          'Change column order',
          {
            from: storedColumnOrderModel,
            to: newColumnOrderModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column order',
          Log.FEATURE.BASIC_TABLE,
        );
      },
      [
        columns,
        columnVisibilityModel,
        columnWidthModel,
        localStorageKey,
        persistSettings,
      ],
    );

    const onStateChange = useCallback(
      (state) => {
        onColumnOrderChange(state.columns.orderedFields);

        if (propsOnStateChange) {
          propsOnStateChange(state);
        }

        if (!excelData) {
          return;
        }

        const newFilteredSortedRows = unique(
          gridFilteredSortedRowIdsSelector(state, apiRef.current.state),
        );

        if (
          JSON.stringify(filteredSortedRows) ===
          JSON.stringify(newFilteredSortedRows)
        ) {
          return;
        }

        setFilteredSortedRows(newFilteredSortedRows);
      },
      [onColumnOrderChange, propsOnStateChange, excelData, filteredSortedRows],
    );

    const onRowSelectionModelChange = useCallback(
      (event) => propsOnRowSelectionModelChange(event),
      [propsOnRowSelectionModelChange, rowSelectionModel],
    );

    const onPinnedColumnsChange = useCallback(
      (event) => {
        Log.info(
          'Change pinned columns',
          {
            from: pinnedColumns,
            to: event,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change pinned columns',
          Log.FEATURE.BASIC_TABLE,
        );

        setPinnedColumns(event);

        LocalStorageService.setObjectAsLocalStorage(
          pinnedColumnsCookieId,
          event,
        );
      },
      [pinnedColumns, pinnedColumnsCookieId],
    );

    const slots = useMemo(
      () => ({
        footer: hideFooter ? () => null : Footer,
        loadingOverlay: () => <LoadingState />,
        noRowsOverlay: () => <NoRowsOverlay loading={loading} />,
        pagination: Pagination,
        toolbar: () => getToolbar(),
      }),
      [getToolbar, hideFooter, loading],
    );

    const slotProps = useMemo(
      () => ({
        ...(logicOperators && {
          filterPanel: {
            logicOperators,
          },
        }),
        footer: {
          isFetching: isFetching ?? false,
          paginationText,
          selectedRowCount: selectAllRowsCount,
        },
        pagination: {
          'data-testid': ComponentTestIds.TABLE.PAGINATION,
          isFetching: isFetching ?? false,
          paginationText,
        },
        row: {
          onContextMenu: onRowRightClick,
          style: {
            cursor: onRowRightClick
              ? 'context-menu'
              : onRowClick
                ? 'pointer'
                : 'auto',
          },
        },
      }),
      [
        isFetching,
        logicOperators,
        onRowClick,
        onRowRightClick,
        paginationText,
        selectAllRowsCount,
      ],
    );

    // Ensure detailPanelExpandedRowIds is an array of non-null values (null breaks the DataGridPro component)
    const filteredDetailPanelExpandedRowIds = detailPanelExpandedRowIds.filter(
      (id) => id !== null,
    );

    const shittyIsLoading = Boolean(
      isLoading === true ||
        loading === true ||
        loading === LOADING_STATE.LOADING,
    );

    return (
      <DataGridPro
        disableRowSelectionOnClick
        pagination
        apiRef={apiRef}
        checkboxSelection={checkboxSelection}
        className={className}
        columns={orderedAndSizedColumns}
        columnVisibilityModel={columnVisibilityModel}
        data-testid={testId}
        detailPanelExpandedRowIds={filteredDetailPanelExpandedRowIds}
        disableColumnFilter={disableColumnFilter}
        disableColumnSorting={isFetching}
        filterModel={filterModel}
        headerSelectionCheckboxProps={headerSelectionCheckboxProps}
        initialState={{
          pinnedColumns: getPinnedColumns(),
          ...initialState,
        }}
        loading={shittyIsLoading}
        paginationMode={paginationMode}
        rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
        rows={rows}
        rowSelectionModel={rowSelectionModel}
        slotProps={slotProps}
        slots={slots}
        sortingMode={sortingMode}
        onFilterModelChange={onFilterModelChange}
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnWidthChange={onColumnWidthChange}
        onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
        onPinnedColumnsChange={onPinnedColumnsChange}
        onRowClick={onRowClick}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onStateChange={onStateChange}
        {...hiddenHeaderProps}
        {...otherProps}
      />
    );
  },
);

BasicTable.displayName = 'BasicTable';
