export const getSellerOrderReferences = (assetMain: any) => {
  const sellerOrderReferences = [];

  if (assetMain?.assetState?.body?.transaction?.logisticsPackage)
    for (const logisticsPackage of assetMain?.assetState?.body?.transaction
      ?.logisticsPackage) {
      if (logisticsPackage?.lineItem)
        for (const lineItem of logisticsPackage?.lineItem) {
          if (
            lineItem?.agreement?.sellerOrder &&
            !sellerOrderReferences.includes(lineItem.agreement?.sellerOrder)
          ) {
            sellerOrderReferences.push(lineItem.agreement.sellerOrder);
          }
        }
    }

  return sellerOrderReferences.join(', ');
};
