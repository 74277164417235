import InvalidDateException from '~/errors/InvalidDateException';

import LocalStorageService from '~/services/localStorage.service';

import { dayjs } from './datetime';
import Log from './logging';

export const startOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate?.getTime();
};

export const endOfDay = (date) => {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 999);

  return newDate?.getTime();
};

export const parseDate = (date) => {
  if (!date) {
    return null;
  }

  // Handle dates as timestamp stored in a string.
  const number_ = Number(date);
  if (Number.isFinite(number_)) {
    return new Date(number_);
  }

  const parsedDate = new Date(date);
  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  return parsedDate;
};

/**
 * Converts a given date from UTC to the user's local time.
 *
 * Offsets UTC time to the user's local time.
 * Important when reading dates from the server, as the server sends UTC time!
 *
 * @param {string | number | Date} date - The date to convert from UTC.
 * @returns {Date | null} - The equivalent date in the user's local time, or null if the input date is undefined or null.
 */
export const fromUTC = (date) => {
  if (!date) {
    return null;
  }

  let parsedDate;

  // Parse the input date
  if (typeof date === 'string' || typeof date === 'number') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = new Date(date.getTime());
  } else {
    return null;
  }

  // Check if the parsed date is valid
  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  // Calculate the timezone offset based on the specific date - important for handling daylight saving time!
  const timezoneOffset = parsedDate.getTimezoneOffset() * 60_000;

  // Convert UTC to local time by subtracting the offset
  const localDate = new Date(parsedDate.getTime() - timezoneOffset);

  // If the date is a string without time information, set the time to midnight local time
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    localDate.setHours(0, 0, 0, 0);
  }

  return localDate;
};

/**
 * Converts a given date string into UTC format.
 *
 * Offsets timezone from user's local time into UTC time
 *
 * @param {string | number | Date} date - The date to convert to UTC.
 * @returns {Date | null} - The equivalent date in UTC time, or null if the input date is undefined or null.
 */
export const toUTC = (date) => {
  if (!date) {
    return null;
  }

  let parsedDate;

  // Parse the input date
  if (typeof date === 'string' || typeof date === 'number') {
    parsedDate = new Date(date);
  } else if (date instanceof Date) {
    parsedDate = new Date(date.getTime());
  } else {
    return null;
  }

  // Check if the parsed date is valid
  if (Number.isNaN(parsedDate.getTime())) {
    return null;
  }

  // If the date is a string without time information, set the time to midnight UTC
  if (typeof date === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(date)) {
    parsedDate.setUTCHours(0, 0, 0, 0);
  }

  return parsedDate;
};

class DateUtils {
  constructor() {
    this.PREDEFINED_DATE_RANGE_OPTIONS = {
      _7DAYS: {
        ID: '7-days',
        STRING: 'Letzte 7 Tage',
      },
      _30DAYS: {
        ID: '30-days',
        STRING: 'Letzte 30 Tage',
      },
      _365DAYS: {
        ID: '365-days',
        STRING: 'Letzte 365 Tage',
      },
      DAY: {
        ID: 'day',
        STRING: 'Heute',
      },
      LAST_MONTH: {
        ID: 'last-month',
        STRING: 'Letzter Monat',
      },
      LAST_WEEK: {
        ID: 'last-week',
        STRING: 'Letzte Woche',
      },
      MONTH: {
        ID: 'month',
        STRING: 'Dieser Monat',
      },
      WEEK: {
        ID: 'week',
        STRING: 'Diese Woche',
      },
      YEAR: {
        ID: 'year',
        STRING: 'Dieses Jahr',
      },
      YESTERDAY: {
        ID: 'yesterday',
        STRING: 'Gestern',
      },
    };

    this.DATE_FORMAT = {
      DATE_TIME_INPUT_FORMAT: 'YYYY-MM-DD[T]HH:mm',
      DD_MM: 'DD.MM',
      DD_MM_YY: 'DD.MM.YY',
      DD_MM_YYYY: 'DD.MM.YYYY',
      DD_MM_YYYY__HH_mm: 'DD.MM.YYYY HH:mm',
      DD_MM_YYYY__HH_mm_ss: 'DD.MM.YYYY HH:mm:ss',
      HH: 'HH',
      HH_mm_ss: 'HH:mm:ss',
      mm: 'mm',
      MMM_YYYY: 'MMM YYYY',
      MMMM_YYYY: 'MMMM YYYY',
      YYYY_MM_DD: 'YYYY.MM.DD',
      YYYY_MM_DD__HH_mm_ss_SSSSSS: 'YYYY-MM-DD HH:mm:ss.SSSSSS',
      YYYY_MM_DD_ISO: 'YYYY-MM-DD',
      YYYYMMDD: 'YYYYMMDD',
      YYYYMMDD_HHMMSS: 'YYYYMMDD_HHmmss',
      YYYYMMDDHHMMSS: 'YYYYMMDDHHmmss',
    };
  }

  getFormattedDate = (date, format) => {
    const value = dayjs(date);

    if (!value.isValid()) {
      throw new InvalidDateException();
    }

    return value.format(format);
  };
  getFormattedDate_safe = (date, outputFormat, inputFormat) => {
    if (date == null) {
      return null;
    }

    const value = dayjs(date, inputFormat);

    if (!value.isValid()) {
      return null;
    }

    return value.format(outputFormat);
  };
  getFormattedDateWithoutMidnight_safe = (date, outputFormat, inputFormat) => {
    if (date == null) {
      return null;
    }

    const value = dayjs(date, inputFormat);

    if (!value.isValid()) {
      return null;
    }

    const formattedValue = value.format(outputFormat);

    if (
      formattedValue.split(' ')[1] === '00:00' ||
      formattedValue.split(' ')[1] === '00:00:00'
    ) {
      return formattedValue.split(' ')[0];
    }

    return formattedValue;
  };

  getNumberOfDays(from, to) {
    return Math.round((to - from) / (1000 * 60 * 60 * 24)) + 1;
  }

  /**
   * Converts the selected date range into a timeframe object for data filtering.
   *
   * @param {array} selectedDateRange - An array representing the selected date range.
   * @return {object} The timeframe object with 'from' and 'to' properties.
   */
  extractTimeframe(selectedDateRange) {
    if (!selectedDateRange?.[0] || !selectedDateRange[1]) {
      Log.error(
        'Failed to extract timeframe from date range. date range: ' +
          JSON.stringify(selectedDateRange),
      );

      const from = new Date();
      from.setFullYear(from.getFullYear() - 100); // subtract 100 years per default
      const to = new Date();

      return {
        from,
        to,
      };
    }

    const timeframe = {
      from: startOfDay(parseDate(selectedDateRange[0])),
      to: endOfDay(parseDate(selectedDateRange[1])),
    };

    return timeframe;
  }

  isToday(date) {
    return (
      new Date(date).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0)
    );
  }

  onTheSameDay(day1, day2) {
    return (
      new Date(day1).setHours(0, 0, 0, 0) ===
      new Date(day2).setHours(0, 0, 0, 0)
    );
  }

  // Special function is needed because the to date of the date range must be set to the current day if the cookie is "old".
  // Otherwise, the to date would stay the same and the user wouldn't see his latest deliveries.
  // Thus, if the creation date of the cookie is not from today, take today as to date.
  getDateRangeFromCookies(cookieName, defaultStart, defaultEnd) {
    const cookie = LocalStorageService.getObjectFromLocalStorage(cookieName);

    if (!cookie) {
      return [defaultStart, defaultEnd];
    }

    const dateRange = cookie.data.split(LocalStorageService.SEPARATOR);
    const { createdOn } = cookie;

    // if not from today, set the to date to today so that user sees his latest delivery notes
    if (!this.isToday(createdOn)) {
      return [dateRange[0], defaultEnd];
    }

    return dateRange.map((date) => parseDate(date));
  }

  setDateRangeAsCookie(cookieName, dateRange) {
    LocalStorageService.setMetaDataLocalStorage(
      cookieName,
      dateRange.join(LocalStorageService.SEPARATOR),
    );
  }

  getDifferenceInHours(date1, date2) {
    const diffInMilliseconds =
      new Date(date2).getTime() - new Date(date1).getTime();
    // Convert the time difference from milliseconds to hours
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);

    return diffInHours;
  }
}

export const dateUtils = new DateUtils();
