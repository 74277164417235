/**
 * format the delivery description (e.g. +3 weitere Positionen   |   Gesamtgewicht: 728,8kg)
 */
export const getDeliveryDescription = ({
  deliveredArticles,
  returnedArticles,
  totalWeightDelivered,
  totalWeightReturned,
}) => {
  // Delivered articles
  let deliveredArticlesCount = '';
  if (deliveredArticles.length > 2) {
    const furtherItems = deliveredArticles.length - 1;
    deliveredArticlesCount = `+${furtherItems} weitere Positionen`;
  } else if (deliveredArticles.length > 1) {
    deliveredArticlesCount = '+1 weitere Position';
  }

  // Don't display a total weight in the description if it is already displayed as amount in the delivery list.
  const deliveredArticlesTotalWeight =
    Boolean(totalWeightDelivered && deliveredArticles.length > 1) &&
    `Gesamtgewicht: ${totalWeightDelivered}`;

  // Returned articles
  const returnedArticlesCount =
    returnedArticles.length > 0 &&
    `+${returnedArticles.length} retournierte Artikel`;

  const returnedArticlesTotalWeight =
    totalWeightReturned &&
    `Gesamtgewicht retournierte Artikel: ${totalWeightReturned}`;

  const values = [
    deliveredArticlesCount,
    deliveredArticlesTotalWeight,
    returnedArticlesCount,
    returnedArticlesTotalWeight,
  ].filter(Boolean);

  if (values.length === 0) {
    return '';
  }

  return values.join('\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0');
};
