import iso3311a2 from 'iso-3166-1-alpha-2';

import MapperService from '~/services/mapper.service';

import ValueGroup from '../deliveries/ValueGroup';

export const joinElements = (array = [], joinSequence = ' ') =>
  array
    .filter((element) => element != null && element !== '')
    .join(joinSequence);

/**
 * Renders an address object to a single string.
 *
 * @example addressToString({buildingNumber: '19', city: 'Berlin', country: 'DE', postCode: '12345', streetName: 'Musterstraße'})
 * => 'Musterstraße 19, 12345 Berlin, DE'
 */
export const addressToString = (address) => {
  if (!address) {
    return '';
  }

  const { buildingNumber, city, country, postCode, streetName } = address;
  const addressString = joinElements(
    [
      joinElements([streetName, buildingNumber]),
      joinElements([postCode, city]),
      joinElements([country]),
    ],
    ', ',
  );

  return addressString;
};

export default class Address {
  constructor(address) {
    this.streetName = address?.street_name ?? address?.streetName ?? '';
    this.buildingNumber =
      address?.building_number ?? address?.buildingNumber ?? '';
    this.postCode = address?.post_code ?? address?.postCode ?? '';
    this.city = address?.city ?? '';
    this.country = address?.country ?? '';
  }

  getConcatenatedAddress() {
    return Address.getConcatenatedAddress(this);
  }

  static getConcatenatedAddress(address) {
    const streetName = ValueGroup.getCurrentValue(address?.streetName);
    const buildingNumber = ValueGroup.getCurrentValue(address?.buildingNumber);
    const postCode = ValueGroup.getCurrentValue(address?.postCode);
    const city = ValueGroup.getCurrentValue(address?.city);
    const country = ValueGroup.getCurrentValue(address?.country);

    return addressToString({
      buildingNumber,
      city,
      country,
      postCode,
      streetName,
    });
  }

  getBackendFormat() {
    return {
      building_number: this.buildingNumber,
      city: this.city,
      country: this.country,
      post_code: this.postCode,
      street_name: this.streetName,
    };
  }

  static getCountryCodes() {
    return iso3311a2.getData();
  }

  static getCountryCodeOptions() {
    this.countryCodes = Address.getCountryCodes();
    return Object.keys(this.countryCodes).map((code) => {
      const newCountry = {
        id: code,
        name: code + ': ' + this.countryCodes[code],
      };

      return MapperService.addSearchStringWithValues(newCountry, [
        code,
        this.countryCodes[code],
      ]);
    });
  }

  static getDifferentValues(addressA, addressB) {
    const differentValues = [];

    if (addressA?.streetName !== addressB?.streetName) {
      differentValues.push('Straße');
    }

    if (addressA?.buildingNumber !== addressB?.buildingNumber) {
      differentValues.push('Hausnummer');
    }

    if (addressA?.postCode !== addressB?.postCode) {
      differentValues.push('PLZ');
    }

    if (addressA?.city !== addressB?.city) {
      differentValues.push('Ort');
    }

    if (addressA?.country !== addressB?.country) {
      differentValues.push('Land');
    }

    return differentValues;
  }

  static DEFAULT_COUNTRY_CODE = {
    DE: 'DE',
  };
}
