import { Error as ErrorIcon } from '@mui/icons-material';

import { useQueryUserData } from '~/data/user';

import { Spinner } from '~/ui/atoms/Spinner';

export const UnauthorizedPage = () => {
  const { isError, isLoading } = useQueryUserData(true);

  const getMessage = () => {
    if (isLoading) {
      return <Spinner title="Berechtigungen werden geladen..." />;
    }

    if (isError) {
      return (
        <>
          <ErrorIcon className="mr-2" />
          Berechtigungen konnten nicht geladen werden.
        </>
      );
    }

    return (
      <>
        <ErrorIcon className="mr-2" />
        Du bist nicht berechtigt, auf diesen Bereich zuzugreifen.
      </>
    );
  };

  return (
    <div className="flex h-full w-full items-center justify-center">
      {getMessage()}
    </div>
  );
};
