import { unique } from '~/utils/array';

import FilterNew from './FilterNew';
import FilterProps from './FilterProps';

export default class BackendFilter {
  constructor(backendFilter) {
    this.name = backendFilter.name;
    this.comp = backendFilter.comp;
    this.value = backendFilter.value;
    this.caseSensitive = backendFilter.caseSensitive;
  }

  /**
   * Gets all filters that can be used in API requests to the backend based on the supported fields and operators.
   *
   * @return {Array} An array of filter configurations for each field that can be filtered.
   */
  static getApplicableBackendFilters() {
    const filterableFields = unique(
      FilterProps.FILTER_MAPPING.filter(({ BACKEND_FILTER }) =>
        Boolean(BACKEND_FILTER),
      ).map(({ FIELD }) => FIELD),
    );

    const filters = [];

    // Generate the filter configurations for each field that can be filtered
    for (const field of filterableFields) {
      for (const operator of BackendFilter.getApplicableBackendOperators()) {
        filters.push(
          new FilterNew({
            field,
            operator,
          }),
        );
      }
    }

    return filters;
  }

  /**
   * Get all filter operators that can be used in API requests to the backend.
   *
   * @return {Array} An array of applicable backend operators.
   */
  static getApplicableBackendOperators() {
    return FilterProps.OPERATOR_STRING_MAPPING.map(
      ({ OPERATOR }) => OPERATOR,
    ).filter(Boolean);
  }
}
