import { DateRangePicker as MuiDateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useTranslation } from 'react-i18next';

import { type DateRange } from '~/types/common';

import { dayjs } from '~/utils/datetime';
import { dateFull, LOCALE_INFO } from '~/utils/l10n';

type P = {
  readonly isDisabled?: boolean;
  readonly maxDate?: Date;
  readonly onChange: (value: DateRange) => void;
  readonly value: DateRange;
};

export const DateRangePicker = ({
  isDisabled,
  maxDate,
  onChange,
  value = [undefined, undefined],
}: P) => {
  const { t } = useTranslation('forms');

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={LOCALE_INFO.name}
    >
      <MuiDateRangePicker
        localeText={{
          start: t('date.range.from', { ns: 'forms' }),
          end: t('date.range.to', { ns: 'forms' }),
        }}
        value={[dayjs(value[0]) || null, dayjs(value[1]) || null]}
        disabled={isDisabled}
        format={dateFull}
        maxDate={maxDate ? dayjs(maxDate) : undefined}
        calendars={3}
        currentMonthCalendarPosition={2}
        dayOfWeekFormatter={(day) => day.format('dd')}
        slotProps={{
          textField: {
            InputProps: {
              className: 'w-36',
            },
          },
        }}
        onChange={(newValue) => {
          onChange([newValue[0]?.toDate(), newValue[1]?.toDate()]);
        }}
      />
    </LocalizationProvider>
  );
};
