import { cloneDeep } from 'lodash-es';

export const sizeColumns = (columns, columnWidthModel) => {
  const newColumns = cloneDeep(columns);

  for (const [index, column] of newColumns.entries()) {
    const customWidth = columnWidthModel[column.field];
    if (customWidth) {
      newColumns[index].width = customWidth;
    }
  }

  return newColumns;
};
