import { forwardRef, type Ref } from 'react';

export const EditedIconLight = forwardRef(
  ({ className, style }: ComponentStyling, ref) => (
    <svg
      ref={ref as Ref<SVGSVGElement>}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
      fill="none"
    >
      <circle cx="13" cy="13" r="12.75" stroke="#173C88" strokeWidth="0.5" />
      <path
        d="M19.0417 8.88571L16.375 6.21905L17.25 5.34405C17.4861 5.10794 17.7812 4.99335 18.1354 5.0003C18.4896 5.00724 18.7847 5.12877 19.0208 5.36488L19.9167 6.26071C20.1528 6.49683 20.2708 6.78849 20.2708 7.13571C20.2708 7.48294 20.1528 7.7746 19.9167 8.01071L19.0417 8.88571ZM18.1667 9.76071L7.66667 20.2607H5V17.594L15.5 7.09405L18.1667 9.76071Z"
        fill="#173C88"
      />
    </svg>
  ),
);
