import { validate as isUUID } from 'uuid';

/**
 * Checks if a string starts with a UUID.
 * Custom field ids should start with a UUID.
 */
export const isCustomFieldId = (id?: string) =>
  Boolean(id?.slice(0, 36) && isUUID(id.slice(0, 36)));

/**
 * Checks if a string starts with a UUID.
 * Custom fields should start with a UUID followed by an underscore.
 *
 * @param {string} name - The name (e.g. column or filter name) to check
 * @returns {boolean} True if the name starts with a UUID, false otherwise
 */
export const isCustomFieldName = (name?: string) =>
  Boolean(name?.slice(0, 36) && isUUID(name.slice(0, 36)));
