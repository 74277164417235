import { type DateRange } from '~/types/common';

import { dayjs } from '~/utils/datetime';
import Log from '~/utils/logging';

import { type AggregationInterval } from '../types';

const AGGREGATION_INTERVALS = new Map<number, AggregationInterval>([
  [2, 'hour'],
  [32, 'day'],
  [93, 'week'],
  [731, 'month'],
  [36_500, 'quarter'],
]);

/**
 * Determines the time interval to aggregate (chart) data by based on a given date range.
 *
 * @param dateRange - A tuple of [startDate, endDate] in string format
 * @returns The aggregation interval ('hour', 'day', 'week', 'month', 'quarter') or undefined if invalid
 *
 * Aggregation rules:
 * - Up to 2 days: hourly
 * - Up to 32 days: daily
 * - Up to 93 days: weekly
 * - Up to 731 days: monthly
 * - Up to 36,500 days: quarterly
 */
export const getAggregationFromDateRange = (dateRange: DateRange) => {
  if (dateRange.length !== 2) {
    Log.error(`Invalid date range: ${JSON.stringify(dateRange)}`);
    return 'day';
  }

  const from = dayjs(dateRange[0]).startOf('day');
  const to = dayjs(dateRange[1]).endOf('day');
  const numberOfDays = to.diff(from, 'day') + 1;

  const aggregationLevel = [...AGGREGATION_INTERVALS.entries()].find(
    ([maxDays]) => maxDays >= numberOfDays,
  );

  return aggregationLevel?.[1] ?? 'day';
};
