import { Backdrop } from '@mui/material';
import { useEffect, useState } from 'react';

import { LogoWithText } from '~/ui/atoms/VestigasLogo';

import {
  BACKDROP_COLOR,
  BRAND_COLOR,
  SCREENSAVER_INTERVAL_DISPLAYED,
  SCREENSAVER_INTERVAL_HIDDEN,
} from './constants';

type P = {
  readonly timeHidden?: number;
  readonly timeShown?: number;
};

/**
 * Renders an overlay that displays the VESTIGAS logo and a message.
 * The overlay is initially hidden and then shown and hidden at regular intervals.
 * The component cleans up the interval on unmount.
 *
 * @return {JSX.Element} The rendered overlay ad.
 */
export const AdOverlay = ({
  timeHidden = SCREENSAVER_INTERVAL_HIDDEN,
  timeShown = SCREENSAVER_INTERVAL_DISPLAYED,
}: P) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const showAndHideOverlay = () => {
      setTimeout(() => {
        setOpen(true);

        // Set timeout to hide the overlay after it has been shown for `timeShown`.
        setTimeout(() => {
          setOpen(false);
        }, timeShown);
      }, timeHidden);
    };

    showAndHideOverlay();

    // Set up the interval to repeat the cycle.
    const intervalId = setInterval(showAndHideOverlay, timeHidden + timeShown);

    // Clean up the interval on component unmount.
    return () => {
      clearInterval(intervalId);
    };
  }, [timeHidden, timeShown]);

  return (
    <Backdrop
      open={open}
      sx={{
        backdropFilter: 'blur(10px)',
        backgroundColor: BACKDROP_COLOR,
        color: '#fff',
        flexDirection: 'column',
        textShadow: `0 0 0.75vw ${BRAND_COLOR}`,
        transitionProperty: 'opacity',
        transitionTimingFunction: 'ease-out',
        zIndex: (theme) => theme.zIndex.snackbar,
      }}
      transitionDuration={{
        enter: 1000,
        exit: 600,
      }}
      onClick={() => {
        setOpen(false);
      }}
    >
      <LogoWithText
        style={{
          filter: `drop-shadow(0 0 0.75vw ${BRAND_COLOR})`,
          width: '50vw',
        }}
      />
      <h1
        style={{
          fontSize: '5vw',
          marginBottom: 0,
          marginTop: '3vw',
        }}
      >
        Der digitale Lieferschein
      </h1>
    </Backdrop>
  );
};
