// FIXME: these need to be internationalized properly!
const getTranslatedDlnAcceptState = (dlnAcceptState: string) => {
  switch (dlnAcceptState) {
    case 'accepted': {
      return 'Angenommen';
    }

    case 'rejected': {
      return 'Abgelehnt';
    }

    case 'open': {
      return 'Offen';
    }

    default: {
      return dlnAcceptState;
    }
  }
};

const getTranslatedProcessState = (processState: string) => {
  switch (processState) {
    case 'delivered': {
      return 'Geliefert';
    }

    case 'readyForOutput': {
      return 'Ausgabe';
    }

    case 'inTransport': {
      return 'In Transport';
    }

    case 'arrived': {
      return 'Übergabe';
    }

    case 'cancelled': {
      return 'Storniert';
    }

    default: {
      return processState;
    }
  }
};

const getTranslatedBilledState = (billedState: string) => {
  switch (billedState) {
    case 'unbilled': {
      return 'Nein';
    }

    default: {
      return 'Ja';
    }
  }
};

export const selectDashboardReportData = (data) => ({
  ...data,
  data: data.data.map(
    (
      {
        amount,
        articleName,
        articleNumber,
        billedState,
        confirmedAccountingReferenceName,
        confirmedSiteName,
        dlnAcceptState,
        dlnDate,
        dlnNr,
        processState,
        recipientName,
        supplierAssignedSiteName,
        supplierName,
        unitType,
      },
      index,
    ) => ({
      amount,
      articleName,
      articleNumber,
      billedState: getTranslatedBilledState(billedState),
      confirmedAccountingReferenceName,
      confirmedSiteName,
      dlnAcceptState: getTranslatedDlnAcceptState(dlnAcceptState),
      dlnDate,
      dlnNr,
      id: index,
      processState: getTranslatedProcessState(processState),
      recipientName,
      supplierAssignedSiteName,
      supplierName,
      unitType,
    }),
  ),
});
