import ms from 'ms';

export const SCREENSAVER_INTERVAL_HIDDEN = ms('60s');
export const SCREENSAVER_INTERVAL_DISPLAYED = ms('5s');

// Cutoff in hours for which a delivery note will be considered recent and displayed on the pick-up screen.
export const DISPLAY_IF_YOUNGER_THAN_HOURS = 3;

export const BRAND_COLOR = 'var(--primary900)';
export const BACKDROP_COLOR = 'rgba(12, 30, 68, 0.8)';

export const PICKUP_NUMBER_KEY = 'd5ced93e-0056-4ff3-b477-04bbb2f10ec6'; // The key of the custom field used to identify the "Abhol-Nummer" in the deliveryRow object. The "Abhol-Nummer" is what`s displayed on the screen.

export const PICKUP_NUMBER_KEY_FALLBACK = 'number'; // The key of the field used to show a fallback value in case PICKUP_NUMBER_KEY is empty.
