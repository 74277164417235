import { DarkTooltipExtraWide } from '~/utils/componentUtils';

export const InvoiceCheckTableTooltip = ({ children, header, rows, title }) => {
  const body = (
    <div className="flex items-center p-1">
      <table>
        <tr>
          {/* Add padding of 20px between columns. If column name is "Rechnung", highlight the text in red. */}
          {header.map((value, index) => (
            <th
              key={index}
              className={
                'normal text-start ' +
                (index > 0 ? 'pl-4' : '') +
                (value === 'Rechnung' ? 'text-red-300' : '')
              }
            >
              {value}
            </th>
          ))}
        </tr>
        {rows.map((row, index) => (
          <tr key={index}>
            {/* Add padding of 20px between columns. If column name is "Rechnung", highlight the text in red. */}
            {row.map((value, index) => (
              <th
                key={index}
                className={
                  'text-start align-top text-sm ' +
                  (index > 0 ? 'pl-4' : '') +
                  (header[index] === 'Rechnung' ? 'text-red-300' : '')
                }
              >
                {value}
              </th>
            ))}
          </tr>
        ))}
      </table>
    </div>
  );

  const content = (
    <div>
      <div className="mb-2 flex w-full items-center justify-center border-b border-gray-400 p-1 text-sm font-bold">
        {title}
      </div>
      {body}
    </div>
  );

  return (
    <DarkTooltipExtraWide title={content}>
      <span className="cursor-pointer">{children}</span>
    </DarkTooltipExtraWide>
  );
};

InvoiceCheckTableTooltip.displayName = 'InvoiceCheckTableTooltip';
