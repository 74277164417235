/* eslint-disable import/no-unassigned-import */
import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';

import { type UncefactUnitType } from '~/constants/units';

import { LOCALE_INFO } from '~/utils/l10n';
import UnitUtils from '~/utils/unitUtils';

const defaultChartData = {
  datasets: [],
  labels: [],
};

type ChartData = {
  datasets: Array<{
    backgroundColor: string[];
    data: number[];
  }>;
  labels: string[];
};

type P = {
  readonly chartData: ChartData;
  readonly selectedUnit?: UncefactUnitType;
} & ComponentStyling;

export const DoughnutChart = ({
  chartData = defaultChartData,
  className,
  selectedUnit,
  style,
}: P) => {
  const options = {
    cutout: '56%',
    elements: {
      arc: {
        borderColor: 'white',
        hoverBorderColor: 'white',
      },
    },
    locale: LOCALE_INFO.name,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        boxPadding: 6,
        callbacks: {
          label({
            formattedValue,
            label,
          }: {
            formattedValue: string;
            label: string;
          }) {
            const abbreviatedUnit =
              UnitUtils.getAbbreviatedUnitString(selectedUnit);

            return `${label}: ${formattedValue} ${abbreviatedUnit}`.trim();
          },
        },
        padding: {
          x: 10,
          y: 8,
        },
      },
    },
  };

  return (
    <Doughnut
      data={chartData}
      options={options}
      className={className}
      style={style}
    />
  );
};
