import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import LocalStorageService from '~/services/localStorage.service';
import MapperService from '~/services/mapper.service';

const invoicesSlice = createSlice({
  initialState: {
    browsableInvoices: [],
    filteredIncomingInvoiceRows: [],
    filteredIncomingInvoices: [],
    filteredIncomingInvoicesVersion: 0,
    filteredOutgoingInvoiceRows: [],
    filteredOutgoingInvoices: [],
    filteredOutgoingInvoicesVersion: 0,
    incomingInvoiceRows: [],
    incomingInvoices: [],
    incomingInvoicesFromBackendVersion: 0,
    incomingInvoicesLoading: LOADING_STATE.NOT_LOADED,
    incomingInvoicesVersion: 0,
    invoiceCheckIgnoredArticles: [],
    invoiceCheckIgnoredArticlesLoading: LOADING_STATE.NOT_LOADED,
    invoiceReferencedDeliveryNotesExpanded:
      LocalStorageService.getBooleanFromLocalStorage(
        LocalStorageService.INVOICE_REFERENCED_DELIVERY_NOTES_EXPANDED,
      ) ?? false,
    outgoingInvoiceRows: [],
    outgoingInvoices: [],
    outgoingInvoicesFromBackendVersion: 0,
    outgoingInvoicesLoading: LOADING_STATE.NOT_LOADED,
    outgoingInvoicesVersion: 0,
  },
  name: 'invoices',
  reducers: {
    replaceBrowsableInvoices(state, parameter) {
      const { payload } = parameter;

      state.browsableInvoices = payload;
    },
    replaceFilteredIncomingInvoices(state, parameter) {
      const { payload } = parameter;

      state.filteredIncomingInvoices = payload;
      state.filteredIncomingInvoiceRows = payload.map((invoice) =>
        MapperService.mapInvoiceRow(invoice),
      );
      state.filteredIncomingInvoicesVersion += 1;
    },
    replaceFilteredOutgoingInvoices(state, parameter) {
      const { payload } = parameter;

      state.filteredOutgoingInvoices = payload;
      state.filteredOutgoingInvoiceRows = payload.map((invoice) =>
        MapperService.mapInvoiceRow(invoice),
      );
      state.filteredOutgoingInvoicesVersion += 1;
    },
    replaceIncomingInvoices(state, parameter) {
      const { payload } = parameter;

      for (const [index, incomingInvoice] of payload.entries()) {
        state.incomingInvoices[index] = incomingInvoice;
        state.incomingInvoiceRows[index] =
          MapperService.mapInvoiceRow(incomingInvoice);
        state.incomingInvoicesVersion += 1;
      }
    },
    replaceInvoiceCheckIgnoredArticles(state, parameter) {
      const { payload } = parameter;

      state.invoiceCheckIgnoredArticles = payload;
      state.invoiceCheckIgnoredArticlesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceOutgoingInvoices(state, parameter) {
      const { payload } = parameter;

      for (const [index, outgoingInvoice] of payload.entries()) {
        state.outgoingInvoices[index] = outgoingInvoice;
        state.outgoingInvoiceRows[index] =
          MapperService.mapInvoiceRow(outgoingInvoice);
        state.outgoingInvoicesVersion += 1;
      }
    },
    saveIncomingInvoices(state, parameter) {
      const { payload } = parameter;

      state.incomingInvoices = [...state.incomingInvoices, ...payload];
      state.incomingInvoiceRows = [
        ...state.incomingInvoiceRows,
        ...payload.map((invoice) => MapperService.mapInvoiceRow(invoice)),
      ];
      state.incomingInvoicesLoading = LOADING_STATE.SUCCEEDED;
      state.incomingInvoicesVersion += 1;
    },
    saveIncomingInvoicesFromBackend(state, parameter) {
      const { payload } = parameter;

      state.incomingInvoices = [...state.incomingInvoices, ...payload];
      state.incomingInvoiceRows = [
        ...state.incomingInvoiceRows,
        ...payload.map((invoice) => MapperService.mapInvoiceRow(invoice)),
      ];
      state.incomingInvoicesLoading = LOADING_STATE.SUCCEEDED;
      state.incomingInvoicesFromBackendVersion += 1;
    },
    saveOutgoingInvoices(state, parameter) {
      const { payload } = parameter;

      state.outgoingInvoices = [...state.outgoingInvoices, ...payload];
      state.outgoingInvoiceRows = [
        ...state.outgoingInvoiceRows,
        ...payload.map((invoice) => MapperService.mapInvoiceRow(invoice)),
      ];
      state.outgoingInvoicesLoading = LOADING_STATE.SUCCEEDED;
      state.outgoingInvoicesVersion += 1;
    },
    saveOutgoingInvoicesFromBackend(state, parameter) {
      const { payload } = parameter;

      state.outgoingInvoices = [...state.outgoingInvoices, ...payload];
      state.outgoingInvoiceRows = [
        ...state.outgoingInvoiceRows,
        ...payload.map((invoice) => MapperService.mapInvoiceRow(invoice)),
      ];
      state.outgoingInvoicesLoading = LOADING_STATE.SUCCEEDED;
      state.outgoingInvoicesFromBackendVersion += 1;
    },
    setIncomingInvoicesLoading(state, parameter) {
      const { payload } = parameter;

      state.incomingInvoicesLoading = payload;
    },
    setInvoiceCheckIgnoredArticlesLoading(state, parameter) {
      const { payload } = parameter;

      state.invoiceCheckIgnoredArticlesLoading = payload;
    },
    setInvoiceReferencedDeliveryNotesExpanded(state, parameter) {
      const { payload } = parameter;

      state.invoiceReferencedDeliveryNotesExpanded = payload;
      LocalStorageService.setLocalStorage(
        LocalStorageService.INVOICE_REFERENCED_DELIVERY_NOTES_EXPANDED,
        payload,
      );
    },
    setOutgoingInvoicesLoading(state, parameter) {
      const { payload } = parameter;

      state.outgoingInvoicesLoading = payload;
    },
  },
});

const { actions, reducer } = invoicesSlice;
export const {
  replaceBrowsableInvoices,
  replaceFilteredIncomingInvoices,
  replaceFilteredOutgoingInvoices,
  replaceIncomingInvoices,
  replaceInvoiceCheckIgnoredArticles,
  replaceOutgoingInvoices,
  saveIncomingInvoices,
  saveIncomingInvoicesFromBackend,
  saveOutgoingInvoices,
  saveOutgoingInvoicesFromBackend,
  setIncomingInvoicesLoading,
  setInvoiceCheckIgnoredArticlesLoading,
  setInvoiceReferencedDeliveryNotesExpanded,
  setOutgoingInvoicesLoading,
} = actions;
export default reducer;
