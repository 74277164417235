import { cloneDeep } from 'lodash-es';

class ES6ClassFactory {
  // Function is needed because redux tends to make plain objects out of ES6 class objects.
  // Thus, methods of the ES6 classes get lost.
  // With this class it is ensured that when retrieving ES6 classes from the store, that they have the right methods.
  convertToES6Class(plainObjects, es6Object) {
    if (!plainObjects) {
      return [];
    }

    return plainObjects.map((object) => {
      // replicate the ES6 Class so that it is not mutated
      const newObject = cloneDeep(es6Object);

      // clone all properties from the existing object in the new object
      for (const property of Object.keys(object)) {
        newObject[property] = cloneDeep(object[property]);
      }

      // return the new object as it is now a ES6 object with the corresponding methods
      return newObject;
    });
  }
}

export const es6ClassFactory = new ES6ClassFactory();
