import palettes from 'iwanthue/precomputed';

// import palettes from 'iwanthue/precomputed/k-means';
// import palettes from 'iwanthue/precomputed/force-vector';

/**
 * Returns a palette of optimally distinct colors taken from the iwanthue library.
 * @param differentColors - The number of distinct colors needed (max 15)
 * @see https://medialab.github.io/iwanthue/
 * @returns An array of hex color codes
 */
export const getOptimallyDistinctColorsPalette = (differentColors = 5) => {
  const paletteSize = Math.min(differentColors, 15);

  // We could as well calculate the palette ourselves (https://github.com/medialab/iwanthue?tab=readme-ov-file#usage), but using precomputed palettes is faster.

  return palettes[paletteSize];
};

/**
 * Gets a single color from an optimally distinct color palette.
 * @param colorIndex - The index of the color to retrieve
 * @param totalColors - The total number of colors in the palette (default: 7)
 * @returns A hex color code
 */
export const getOptimallyDistinctColor = (
  colorIndex: number,
  totalColors = 7,
): string => {
  const palette = getOptimallyDistinctColorsPalette(totalColors);

  const color = palette[colorIndex % palette.length];

  return color;
};
