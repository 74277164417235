export const DataExchangeSuccessIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10.5969 13.8001L8.44689 11.6501C8.26355 11.4668 8.03022 11.3751 7.74689 11.3751C7.46355 11.3751 7.23022 11.4668 7.04689 11.6501C6.86355 11.8335 6.77189 12.0668 6.77189 12.3501C6.77189 12.6335 6.86355 12.8668 7.04689 13.0501L9.89689 15.9001C10.0969 16.1001 10.3302 16.2001 10.5969 16.2001C10.8636 16.2001 11.0969 16.1001 11.2969 15.9001L16.9469 10.2501C17.1302 10.0668 17.2219 9.83346 17.2219 9.55012C17.2219 9.26679 17.1302 9.03346 16.9469 8.85012C16.7636 8.66679 16.5302 8.57512 16.2469 8.57512C15.9636 8.57512 15.7302 8.66679 15.5469 8.85012L10.5969 13.8001ZM11.9969 22.0001C10.6136 22.0001 9.31355 21.7375 8.09689 21.2121C6.88022 20.6868 5.82189 19.9745 4.92189 19.0751C4.02189 18.1751 3.30955 17.1168 2.78489 15.9001C2.26022 14.6835 1.99755 13.3835 1.99689 12.0001C1.99689 10.6168 2.25955 9.31679 2.78489 8.10012C3.31022 6.88346 4.02255 5.82512 4.92189 4.92512C5.82189 4.02512 6.88022 3.31279 8.09689 2.78812C9.31355 2.26346 10.6136 2.00079 11.9969 2.00012C13.3802 2.00012 14.6802 2.26279 15.8969 2.78812C17.1136 3.31346 18.1719 4.02579 19.0719 4.92512C19.9719 5.82512 20.6846 6.88346 21.2099 8.10012C21.7352 9.31679 21.9976 10.6168 21.9969 12.0001C21.9969 13.3835 21.7342 14.6835 21.2089 15.9001C20.6836 17.1168 19.9712 18.1751 19.0719 19.0751C18.1719 19.9751 17.1136 20.6878 15.8969 21.2131C14.6802 21.7385 13.3802 22.0008 11.9969 22.0001Z"
      fill="currentColor"
    />
  </svg>
);
