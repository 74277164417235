import { useQuery, type UseQueryResult } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/logging';

import { queryKeysCostCenter } from './queryKeys';

export type CostCenter = {
  name: string;
  type: string;
  companyId: UUID;
  orgUnits: UUID[];
  orgUnitsSyncKeys: string[];
  companySyncKey: string;
  syncKey: string;
  start: string;
  end: string;
  id: UUID;
  isActive: boolean;
  companyAccountId: UUID;
  orgUnitPaths: UUID[][];
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
};

/**
 * Fetches cost center data from the API.
 * @param {UUID} costCenterId - The ID of the cost center to fetch.
 * @returns {Promise<CostCenter|undefined>} The cost center data if successful, undefined otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Accounting-Reference/operation/get_accounting_reference_accounting_reference__acc_ref_uuid__get
 */
export const fetchCostCenter = async (
  costCenterId: UUID,
): Promise<CostCenter | undefined> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.COST_CENTER.GET(costCenterId))
      .json<CostCenter>();

    return response;
  } catch (error) {
    Log.error('Error fetching cost center', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Generates the query options for the cost center query.
 *
 * @param {UUID} costCenterId - The ID of the cost center to fetch.
 * @param {Object} options - Additional options for the query.
 * @returns {Object} The query options including queryKey, queryFn, and other settings.
 */
export const getCostCenterQueryOptions = ({
  costCenterId,
  options,
}: {
  costCenterId: UUID;
  options?: Parameters<typeof useQuery>[0];
}) => {
  return {
    queryFn: async () => fetchCostCenter(costCenterId),
    queryKey: queryKeysCostCenter.get(costCenterId),
    ...options,
  };
};

/**
 * React Query based custom hook for getting the data for a cost center with a given costCenterId.
 * @param {UUID} costCenterId - The ID of the cost center to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<CostCenter | undefined>} The result of the useQuery hook.
 */
export const useQueryCostCenter = (
  costCenterId: UUID,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<CostCenter | undefined> =>
  useQuery(getCostCenterQueryOptions({ costCenterId, options }));
