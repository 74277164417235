import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { camelcaseKeysFromApi } from '~/services/kyClient';

import { unique } from '~/utils/array';
import Log from '~/utils/logging';

export default class BilledItem {
  constructor(billedItem) {
    billedItem = camelcaseKeysFromApi(billedItem);

    this.path = billedItem?.path ?? [];
    this.totalBilledQuotient = billedItem?.totalBilledQuot
      ? Number(billedItem?.totalBilledQuot)
      : 0;
    this.billingDetails =
      billedItem?.billingDetails?.map((billingDetail) => {
        return {
          amount: {
            unit: billingDetail?.billedUnit ?? null,
            value: billingDetail?.billedAmount ?? null,
          },
          invoiceId: billingDetail?.invoiceUuid ?? null,
        };
      }) ?? [];
    this.invoiceIds = unique(
      this.billingDetails.map(({ invoiceId }) => invoiceId).filter(Boolean),
    );
    this.settledStatus = this.getSettledStatus();
  }

  getSettledStatus() {
    if (!this.totalBilledQuotient) {
      return BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (this.totalBilledQuotient === 1) {
      return BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  }

  static calculateCombinedSettledStatus(settledStatusList) {
    if (
      settledStatusList.every(
        (settledStatus) =>
          settledStatus === BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY,
      )
    ) {
      return BilledItem.SETTLED_STATUS.NOT_SETTLED.KEY;
    }

    if (
      settledStatusList.every(
        (settledStatus) =>
          settledStatus === BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY,
      )
    ) {
      return BilledItem.SETTLED_STATUS.FULLY_SETTLED.KEY;
    }

    return BilledItem.SETTLED_STATUS.PARTLY_SETTLED.KEY;
  }

  static getSettledStatusDescription(key) {
    const settledStatus = Object.keys(BilledItem.SETTLED_STATUS).find(
      (x) => BilledItem.SETTLED_STATUS[x].KEY === key,
    );

    if (!settledStatus) {
      Log.error(
        null,
        new EnumValueNotFoundException('Invalid settled status key: ' + key),
      );
      return null;
    }

    return BilledItem.SETTLED_STATUS[settledStatus].DESCRIPTION;
  }

  static getSettledStatusOptions() {
    return [
      BilledItem.SETTLED_STATUS.NOT_SETTLED.DESCRIPTION,
      BilledItem.SETTLED_STATUS.PARTLY_SETTLED.DESCRIPTION,
      BilledItem.SETTLED_STATUS.FULLY_SETTLED.DESCRIPTION,
    ];
  }

  static SETTLED_STATUS = {
    FULLY_SETTLED: {
      DESCRIPTION: 'Komplett abgerechnet',
      KEY: 'fully-settled',
    },
    NOT_SETTLED: {
      DESCRIPTION: 'Nicht abgerechnet',
      KEY: 'not-settled',
    },
    PARTLY_SETTLED: {
      DESCRIPTION: 'Teilweise abgerechnet',
      KEY: 'partly-settled',
    },
  };
}
