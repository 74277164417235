import Article from '~/models/articles/Article';

import UnitUtils from '~/utils/unitUtils';

/**
 * The main article is displayed to the user as item in the delivery notes list.
 * It is determined by the biggest weight or whether it is of type concrete.
 */
export const getMainArticle = (articles) => {
  if (!articles?.length > 0) {
    return new Article();
  }

  // Prioritize item that is of type concrete.
  const concreteItem = articles.find((article) => article?.isConcrete?.());
  if (concreteItem) {
    return concreteItem;
  }

  // Return item with the biggest weight.
  let mainIndex = 0;
  let mainWeight;
  let mainUnit;

  for (let index = 0; index < articles?.length; index++) {
    if (!articles[index].weight?.value || !articles[index].weight?.unit) {
      continue;
    }

    if (!mainWeight || !mainUnit) {
      mainIndex = index;
      mainWeight = articles[index]?.weight?.value;
      mainUnit = articles[index]?.weight?.unit;
    }

    const articleWeight = UnitUtils.calculateWeightInTargetUnit(
      articles[index].weight?.value,
      articles[index].weight?.unit,
      mainUnit,
    );

    if (articleWeight > mainWeight) {
      mainIndex = index;
      mainWeight = articleWeight;
    }
  }

  return articles[mainIndex];
};
