import { BRAND_COLOR } from './constants';

const fontSize = {
  lg: '5vw',
  xl: '7vw',
};

type P = {
  readonly size?: 'lg' | 'xl';
  readonly text: string;
} & ComponentStyling;

export const DLNNumber = ({ size = 'xl', text }: P) => (
  <div
    className="inline-block flex-0 rounded-full whitespace-nowrap"
    style={{
      background: 'rgba(0, 0, 0, 0.08)',
      color: BRAND_COLOR,
      fontSize: fontSize[size] ?? '2vw',
      lineHeight: '1.2',
      mixBlendMode: 'multiply',
      padding: '0 1.5vw',
      width: 'calc(4ch + 2 * 1.5vw)',
    }}
  >
    {text}
  </div>
);
