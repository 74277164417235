import {
  Add as AddIcon,
  Check as CheckIcon,
  Edit as EditIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';
import { Button, Chip } from '@mui/material';
import clsx from 'clsx';

import FeatureService from '~/services/feature.service';

import { LightTooltip } from '~/utils/componentUtils';

import BasicForm from '~/components/BasicForm';
import TextField from '~/components/deliveries/deliveryNoteForm/TextField';
import { ClientPortalTooltip } from '~/components/salesPackages/clientPortal/ClientPortalTooltip';
import { PackageBasicRestrictionTooltip } from '~/components/salesPackages/packageBasicRestriction/PackageBasicRestrictionTooltip';

import { Badge } from './Badge';

export const FilterGroupsList = ({
  abortNewFilterGroup,
  baseTestId,
  filterGroupHasChanges,
  filterGroupMarks,
  filterGroups,
  hideAccordion,
  hideEditNameIcon,
  isCollapsed,
  isCreating,
  isSubmitting,
  onChangeName,
  onClickChangeFilterGroupName,
  onClickExpandFilterGroup,
  onClickFilterGroup,
  onClickNewFilterGroup,
  saveNewFilterGroup,
  selectedFilterGroup,
  state,
  withExpandableFilterGroup,
}) => {
  const getFilterGroupMark = (filterGroupMark) => {
    if (!filterGroupMark) {
      return null;
    }

    return filterGroupMark.tooltipTitle ? (
      <LightTooltip title={filterGroupMark.tooltipTitle}>
        <Badge text={filterGroupMark.mark} />
      </LightTooltip>
    ) : (
      <Badge text={filterGroupMark.mark} />
    );
  };

  const getLabel = (id, name) => {
    const filterGroupMark = filterGroupMarks?.find(
      (filterGroupMark) => filterGroupMark.id === id,
    );

    return (
      <div>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <div>{name}</div>
            {id === selectedFilterGroup && !hideEditNameIcon ? (
              <EditIcon
                className="cursor-pointer"
                onMouseDown={onClickChangeFilterGroupName}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              />
            ) : null}
          </div>
          {id === selectedFilterGroup &&
          withExpandableFilterGroup &&
          !hideAccordion ? (
            <div
              className="flex cursor-pointer items-center border-l border-white/80 pl-4"
              data-testid={`${baseTestId}_filter_group_expand_button`}
              onMouseDown={onClickExpandFilterGroup}
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <div className="w-28 font-bold underline">
                {isCollapsed ? 'Filter einblenden' : 'Filter ausblenden'}
              </div>
              <ExpandLessIcon
                className={clsx('transition-transform', {
                  'rotate-180': isCollapsed,
                })}
              />
            </div>
          ) : null}
        </div>
        {getFilterGroupMark(filterGroupMark)}
      </div>
    );
  };

  return (
    <div
      className="flex items-center gap-4"
      data-testid={`${baseTestId}_container`}
    >
      <div className="flex flex-1 items-center gap-2 overflow-x-auto py-4">
        {filterGroups.map(({ disabled, id, name }, index) => (
          <Chip
            key={index}
            data-testid={`${baseTestId}_group_${name?.replaceAll(' ', '_')}`}
            label={getLabel(id, name)}
            icon={
              id === selectedFilterGroup ? (
                <CheckIcon className="ml-2 text-white" />
              ) : null
            }
            className={clsx(
              'cursor-pointer',
              id === selectedFilterGroup ? 'bg-primary500 text-white' : '',
            )}
            disabled={disabled}
            onMouseDown={() => onClickFilterGroup(id)}
            onClick={(event) => event.stopPropagation()}
          />
        ))}
      </div>

      <PackageBasicRestrictionTooltip>
        <ClientPortalTooltip>
          <Button
            variant="outlined"
            data-testid={`${baseTestId}_new_group_button`}
            startIcon={<AddIcon />}
            disabled={
              FeatureService.clientPortal() ||
              FeatureService.packageBasicRestriction()
            }
            className="flex-none cursor-pointer"
            onClick={onClickNewFilterGroup}
          >
            {filterGroupHasChanges
              ? 'Als neue Filtergruppe speichern'
              : 'Neue Filtergruppe'}
          </Button>
        </ClientPortalTooltip>
      </PackageBasicRestrictionTooltip>

      <BasicForm
        testId={`${baseTestId}_form_modal`}
        title={
          isCreating ? 'Filtergruppe speichern' : 'Filtergruppe umbenennen'
        }
        open={state.formOpen}
        formAbort={abortNewFilterGroup}
        formSuccess={saveNewFilterGroup}
        submittingForm={isSubmitting}
      >
        <div className="w-96">
          <TextField
            autoFocus
            data-testid={`${baseTestId}_group_name_input`}
            title="Name"
            value={state.newFilterGroupName}
            placeholder="Bitte eingeben"
            autoComplete="off"
            onChange={(event) => onChangeName(event.target.value)}
          />
        </div>
      </BasicForm>
    </div>
  );
};
