import { cn } from '~/utils/tailwind';

type P = {
  readonly primaryInformation: string;
  readonly secondaryInformation?: string;
} & ComponentStyling;

/**
 * Component for displaying (select) options with primary and optional secondary information.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} props.primaryInformation - The main information to be displayed in the left column
 * @param {string} [props.secondaryInformation=''] - Optional secondary information to be displayed in the right column
 * @param {string} [props.className] - Additional CSS class names to be applied to the component
 * @param {React.CSSProperties} [props.style] - Inline styles to be applied to the component
 */
export const OptionPrimarySecondary = ({
  className,
  primaryInformation,
  secondaryInformation = '',
  style,
}: P) => (
  <div
    className={cn('flex w-full flex-col overflow-hidden', className)}
    style={style}
  >
    <div
      className={cn('w-full overflow-hidden', {
        'line-clamp-2': !secondaryInformation,
        truncate: secondaryInformation,
      })}
    >
      {primaryInformation}
    </div>
    {secondaryInformation ? (
      <div className="w-full truncate text-gray-400">
        {secondaryInformation}
      </div>
    ) : null}
  </div>
);
