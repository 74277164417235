import { FILTER_DATA_TYPE_BACKEND } from '~/models/filters/constants';

export const PROPERTY = {
  ACCEPT_STATE: {
    KEY: 'acceptState',
    STRING: 'Angenommen',
  },

  ARTICLE: {
    KEY: 'article',
    STRING: 'Artikel',
  },

  ARTICLE_NUMBER: {
    KEY: 'articleNumber',
    STRING: 'Artikel-Nr.',
  },

  ARTICLE_NUMBERS: {
    KEY: 'articleNumbers',
    STRING: 'Artikel-Nr.',
  },

  ARTICLES: {
    KEY: 'articles',
    STRING: 'Artikel',
  },

  BUYER_ID: {
    KEY: 'buyerId',
    STRING: 'Kundennummer',
  },

  BUYER_ORDER_REFERENCES: {
    KEY: 'buyerOrderReferences',
    STRING: 'Bestellreferenz Auftraggeber',
  },

  CARRIER: {
    KEY: 'carrier',
    STRING: 'Spediteur',
  },

  CARRIER_ID: {
    KEY: 'carrierId',
    STRING: 'Spediteur-Nr',
  },

  CATEGORY: {
    KEY: 'category',
    STRING: 'Kategorie',
  },

  CHANGED_AT: {
    DATA_TYPE: FILTER_DATA_TYPE_BACKEND.DATE,
    KEY: 'changedDate',
    STRING: 'Letzte Änderung am',
  },

  CHANGED_BY: {
    KEY: 'changedBy',
    STRING: 'Änderungen von',
  },

  // delivery changes
  CHANGED_FIELDS: {
    KEY: 'changedFields',
    STRING: 'Änderungen',
  },

  COMMENTS: {
    KEY: 'comments',
    STRING: 'Kommentare',
  },

  CONSTRUCTION_COMPONENTS: {
    KEY: 'constructionComponents',
    STRING: 'Bauteil',
  },

  CONSTRUCTION_PLANS: {
    KEY: 'constructionPlans',
    STRING: 'Plan',
  },

  // costCenter is actually deprecated and should be removed.
  // The cleanest solution would be to migrate it to the Article model.
  // However, due to "being pragmatic" this will only be done in the future.
  // Some goes for article.
  COST_CENTER: {
    KEY: 'costCenter',
    STRING: 'Kostenstelle',
  },

  COST_CENTERS: {
    KEY: 'costCenters',
    STRING: 'Kostenstellen',
  },

  CREATION_DATE: {
    DATA_TYPE: FILTER_DATA_TYPE_BACKEND.DATE,
    KEY: 'createdOn',
    STRING: 'Erstellt am',
  },

  CUSTOM_DATA: {
    KEY: 'customData',
    STRING: 'Weitere Informationen',
  },

  DELIVERY_SHARED_WITH: {
    KEY: 'deliveryNoteSharedWith',
    STRING: 'Geteilt mit',
  },

  DELIVERY_TYPE: {
    KEY: 'deliveryType',
    STRING: 'Lieferungsart',
  },

  DESCRIPTION: {
    KEY: 'description',
    STRING: 'Beschreibung',
  },

  DLN_DATE: {
    DATA_TYPE: FILTER_DATA_TYPE_BACKEND.DATE,
    KEY: 'dlnDate',
    STRING: 'LFS-Datum',
  },

  FROM_SITE: {
    KEY: 'fromSite',
    STRING: 'Beladeort',
  },

  FROM_SITE_ISSUER_ASSIGNED_ID: {
    KEY: 'fromSiteIssuerAssignedId',
    STRING: 'Beladeort-Nummer',
  },

  LICENSE_PLATE: {
    KEY: 'licensePlate',
    STRING: 'Kennzeichen',
  },

  MAIN_ARTICLE_AMOUNT_UNIT: {
    KEY: 'mainArticleAmountUnit',
    STRING: 'Einheit',
  },

  MAIN_ARTICLE_AMOUNT_VALUE: {
    DATA_TYPE: FILTER_DATA_TYPE_BACKEND.NUMBER,
    KEY: 'mainArticleAmountValue',
    STRING: 'Menge',
  },

  MAIN_ARTICLE_NUMBER: {
    KEY: 'mainArticleNumber',
    STRING: 'Artikel-Nr.',
  },

  MAIN_ARTICLE_TYPE: {
    KEY: 'mainArticleType',
    STRING: 'Artikel',
  },

  MODIFIED_DATE: {
    DATA_TYPE: FILTER_DATA_TYPE_BACKEND.DATE,
    KEY: 'modifiedOn',
    STRING: 'Zuletzt geändert',
  },

  MOVEMENT_MEANS: {
    KEY: 'movementMeans',
    STRING: 'Zufuhrart',
  },

  NUMBER: {
    KEY: 'number',
    STRING: 'LFS-Nr.',
  },

  // FIXME: ORGANIZATIONAL_UNIT and S_OUS target Strabag-specific field 's_ous';
  // ORGANIZATIONAL_UNIT should probably refer to a general ouId field.
  ORGANIZATIONAL_UNIT: {
    KEY: 'sOus',
    STRING: 'Organisations-Gruppen',
  },
  S_OUS: {
    KEY: 'sOus',
    STRING: 'Organisations-Gruppen',
  },
  OU_ID: {
    KEY: 'ouId',
    STRING: 'OuId',
  },

  PERMITTED_COST_CENTER_NAMES: {
    KEY: 'permittedCostCenterNames',
    STRING: 'Berechtigte Kostenstellen',
  },

  PERMITTED_COST_CENTERS: {
    KEY: 'permittedCostCenters',
    STRING: 'Berechtigte Kostenstellen',
  },

  // Needed to access different property in mapped delivery notes.
  // i.e. filter groups in delivery and dashboard overview.
  PERMITTED_TO_SITE_NAMES: {
    KEY: 'permittedToSiteNames',
    STRING: 'Berechtigte Lieferorte',
  },

  PERMITTED_TO_SITES: {
    KEY: 'permittedToSites',
    STRING: 'Berechtigte Lieferorte',
  },

  PROCESS_STATE: {
    KEY: 'processState',
    STRING: 'Status',
  },

  PROCESS_STATE_CHANGE_USER: {
    KEY: 'processStateChangeUser',
    STRING: 'Signiert durch',
  },

  PROJECT: {
    KEY: 'project',
    STRING: 'Projekt',
  },

  RECIPIENT: {
    KEY: 'recipient',
    STRING: 'Abnehmer',
  },

  SELLER_ORDER_REFERENCES: {
    KEY: 'sellerOrderReferences',
    STRING: 'Bestellreferenz Auftragnehmer',
  },

  SETTLED_STATUS: {
    KEY: 'settledStatus',
    STRING: 'Abgerechnet',
  },

  SIGNATURES_REQUESTED_FROM: {
    KEY: 'signaturesRequestedFrom',
    STRING: 'Signaturen angefordert von',
  },

  STATUS: {
    KEY: 'status',
    STRING: 'Status',
  },

  SUPPLIER: {
    KEY: 'supplier',
    STRING: 'Lieferant',
  },

  TO_SITE_RECIPIENT: {
    KEY: 'toSiteRecipient',
    STRING: 'Bestätigter Lieferort',
  },

  TO_SITE_SUPPLIER: {
    KEY: 'toSiteSupplier',
    STRING: 'Lieferort (Lieferantenbezeichnung)',
    STRING_SHORT: 'Lieferort (Lieferantenbez.)',
  },
  TO_SITE_SUPPLIER_ADDRESS: {
    KEY: 'toSiteSupplierAddress',
    STRING: 'Lieferort (Adresse)',
  },
  TO_SITE_SUPPLIER_TRADE_CONTACT: {
    KEY: 'toSiteSupplierTradeContact',
    STRING: 'Ansprechperson des Lieferorts',
  },

  // total line item weights
  TOTAL_WEIGHT_GROSS: {
    KEY: 'totalWeightGross',
    STRING: 'Brutto-Gewicht',
  },

  TOTAL_WEIGHT_NET: {
    KEY: 'totalWeightNet',
    STRING: 'Netto-Gewicht',
  },
  TOTAL_WEIGHT_TARE: {
    KEY: 'totalWeightTare',
    STRING: 'Tara-Gewicht',
  },
  TRADER: {
    KEY: 'trader',
    STRING: 'Händler',
  },
} as const;
