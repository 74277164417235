import { memo } from 'react';

import { LogoWithText } from '~/ui/atoms/VestigasLogo';

export const MainNavLogo = memo(() => (
  <div className="flex items-center px-4 py-3">
    <LogoWithText className="h-10" />
  </div>
));

MainNavLogo.displayName = 'MainNavLogo';
