import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import ObjectUtils from '~/utils/objectUtils';

export default class IdsCredentials {
  constructor(companyId, ids) {
    this.companyId = companyId ?? null;
    // this.shopName = ids?.name ?? null; // Currently not used
    this.kndnr = ids?.query_parameters?.kndnr ?? null;
    this.nameKunde = ids?.query_parameters?.name_kunde ?? null;
    this.pwKunde = ids?.query_parameters?.pw_kunde ?? null;
    this.queryParams = this.getQueryParams(ids?.query_parameters);
    this.redirectUrl = this.getRedirectUrl();
  }

  getQueryParams(ids) {
    if (!ids) {
      return [];
    }

    return ObjectUtils.entries(ids).map((entry) => entry);
  }

  getRedirectUrl() {
    const shopKey = Object.keys(IdsCredentials.SHOP).find(
      (key) => IdsCredentials.SHOP[key].COMPANY_ID === this.companyId,
    );

    if (!shopKey) {
      throw new EnumValueNotFoundException(
        'Invalid company id: ' + this.companyId,
      );
    }

    return IdsCredentials.SHOP[shopKey].REDIRECT_URL;
  }

  static SHOP = {
    BAYWA: {
      COMPANY_ID: '3e8f17a3-24ba-4f70-9f61-5ca06c81014f',
      NAME: 'BayWa',
      REDIRECT_URL:
        'https://www.baywa-baustoffe.de/b2b/de/integration/baywa-ids',
    },
    WUERTH: {
      COMPANY_ID: '3c28526d-16fc-49a3-a293-57f7277a1526',
      NAME: 'Würth',
      REDIRECT_URL:
        'https://eshop.wuerth.de/is-bin/INTERSHOP.enfinity/WFS/1401-B1-Site/de_DE/-/EUR/ViewIDSCatalogService-IDSInBound',
    },
  };
}
