import { intersection } from '~/utils/array';

import Invoice from './Invoice';
import InvoiceCheckCategory from './InvoiceCheckCategory';
import InvoiceCheckResult from './InvoiceCheckResult';

export default class InvoicePosition {
  constructor(item, checkResults, version) {
    if (version === Invoice.VERSION.V1) {
      this.init_v1(item, checkResults);
    } else if (version === Invoice.VERSION.V2) {
      this.init_v2(item, checkResults);
    }
  }

  init_v1(item, checkResults) {
    this.positionNumber = 0;
    this.name = item.name;
    this.number = item.seller_assigned_id;
    this.amount = {
      unit: item.unit_type,
      value: Number.parseFloat(item.quantity),
    };
    this.taxPercent = Number.parseFloat(item.tax_percent) / 100;
    this.currency = item.currency;
    this.netPricePerQuantity = Number.parseFloat(item.net_price_per_quantity);
    this.itemPriceNet = Number.parseFloat(item.net_price);
    this.itemPriceGross =
      Number.parseFloat(item.net_price) * (1 + this.taxPercent);
    this.totalPriceNet = Number.parseFloat(item.price_total);
    this.totalPriceGross =
      Number.parseFloat(item.price_total) * (1 + this.taxPercent);

    this.deliveryNotes = item.delivery_note_id
      ? [
          {
            id: item.delivery_note_asset_id ?? null,
            number: item.delivery_note_id ?? null,
          },
        ]
      : [];

    this.errorChecks = this.assignErrorChecks(checkResults);
    this.chartCategory = this.assignChartCategory();
  }

  init_v2(item, checkResults) {
    this.positionNumber = item.position_nr;
    this.number = item.seller_assigned_id;
    this.name = item.name;
    this.amount = {
      unit: item.unit_type,
      value: Number.parseFloat(item.quantity),
    };
    this.taxPercent = Number.parseFloat(item.tax_percent) / 100;
    this.currency = item.currency;
    this.netPricePerQuantity = Number.parseFloat(item.net_price_per_quantity);
    this.itemPriceNet = Number.parseFloat(item.net_price);
    this.itemPriceGross =
      Number.parseFloat(item.net_price) * (1 + this.taxPercent);
    this.totalPriceNet = Number.parseFloat(item.price_total);
    this.totalPriceGross =
      Number.parseFloat(item.price_total) * (1 + this.taxPercent);

    this.deliveryNotes =
      item?.delivery_note_nrs?.map((delivery_note_nr, index) => {
        return {
          id: item?.delivery_note_asset_ids?.[index],
          number: delivery_note_nr,
        };
      }) ?? [];

    this.errorChecks = this.assignErrorChecks(checkResults);
    this.chartCategory = this.assignChartCategory();
  }

  assignErrorChecks(checkResults) {
    return checkResults
      .filter((checkResult) => {
        // We only want to assign check results that are errors
        if (checkResult.status !== InvoiceCheckResult.STATUS.ERROR) {
          return false;
        }

        // Catch a special case:
        // In case of credit notes (Gutschriften), the dln number isn't read from the invoice. Thus, the "DeliveryNoteExists" error throws an error but without a check_result.delivery_note_id.
        // To match the error with the invoice position which also has a dlnNumber of null, this special case is caught by checking whether:
        // - the check is "DeliveryNoteExists" and
        // - the delivery_note_id of the check is not set and
        // - the dln number of the invoice position is not set
        // This case can be seen at a Würth - Rödl invoice: bceb0b5b-c1f9-4362-834a-3c2178c63917
        if (
          checkResult.name === 'DeliveryNoteExists' &&
          !checkResult.deliveryNotes[0]?.number &&
          !this.deliveryNotes[0]?.number
        ) {
          return true;
        }

        if (
          intersection(
            checkResult.getDeliveryNoteNumbers(),
            this.getDeliveryNoteNumbers(),
          ).length === 0
        ) {
          return false;
        }

        // Catch another special case:
        // If a dln doesn't exist, the "DeliveryNoteExists" error is only thrown once for the first invoice position of the dln. For the next invoice positions, the error isn't throw anymore.
        // If the "assignErrorChecks" would assign no error check to the invoice position, the position would be displayed incorrectly as "approved" in the invoice chart.
        // Thus, assign the "DeliveryNoteExists" already if the dln number of the check result and the invoice position equal.
        if (checkResult.name === 'DeliveryNoteExists') {
          return true;
        }

        if (checkResult.articleName && checkResult.articleName !== this.name) {
          return false;
        }

        return true;
      })
      .map((checkResult) => checkResult.name);
  }

  assignChartCategory() {
    if (this.errorChecks.length === 0) {
      return InvoiceCheckCategory.CHART_CATEGORIES.APPROVED.NAME;
    }

    // due to the find operation, the first value from the InvoiceCheckCategory.CHART_CATEGORIES is taken in case of multiple matches
    const chartCategory = Object.keys(
      InvoiceCheckCategory.CHART_CATEGORIES,
    ).find((x) => {
      return (
        intersection(
          InvoiceCheckCategory.CHART_CATEGORIES[x].CHECKS,
          this.errorChecks,
        ).length > 0
      );
    });

    if (!chartCategory) {
      return InvoiceCheckCategory.CHART_CATEGORIES.APPROVED.NAME;
    }

    return InvoiceCheckCategory.CHART_CATEGORIES[chartCategory].NAME;
  }

  getDeliveryNoteNumbers() {
    return this.deliveryNotes.map((deliveryNote) => deliveryNote.number ?? '');
  }
}
