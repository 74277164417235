import { forwardRef } from 'react';

export const AttentionIcon = forwardRef<SVGSVGElement, ComponentStyling>(
  ({ className, style }, ref) => (
    <svg
      ref={ref}
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <circle cx="13" cy="13" r="13" fill="#DF7411" />
      <path
        d="M13 22C12.5167 22 12.1042 21.8292 11.7625 21.4875C11.4208 21.1458 11.25 20.7333 11.25 20.25C11.25 19.7667 11.4208 19.3542 11.7625 19.0125C12.1042 18.6708 12.5167 18.5 13 18.5C13.4833 18.5 13.8958 18.6708 14.2375 19.0125C14.5792 19.3542 14.75 19.7667 14.75 20.25C14.75 20.7333 14.5792 21.1458 14.2375 21.4875C13.8958 21.8292 13.4833 22 13 22ZM11.25 16V4H14.75V16H11.25Z"
        fill="white"
      />
    </svg>
  ),
);
