import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
} from '@mui/x-data-grid-pro';

import { getFilterContext } from '~/utils/filters';

import DeliveryNote from '../deliveries/DeliveryNote';

import FilterNew from './FilterNew';
import FilterProps from './FilterProps';

export default class MuiDataGridFilter {
  /**
   * Get all filter operators for a specific data type in MUI Data Grid that can be used in MUI Data Grid when data is loaded from the backend.
   */
  static getApplicableMuiDataGridOperatorsForMuiDataGridDataType(
    muiDataGridDataType,
  ) {
    const muiDataGridDefaultOperators =
      MuiDataGridFilter.getMuiDataGridDefaultOperators(muiDataGridDataType);

    const applicableOperators =
      MuiDataGridFilter.getApplicableMuiDataGridOperators();

    return muiDataGridDefaultOperators.filter(({ value }) =>
      applicableOperators.includes(value),
    );
  }

  /**
   * Get all default filter operators for a specific data type in MUI Data Grid.
   *
   * @param {any} muiDataGridDataType - The MUI Data Grid data type to get default operators for.
   * @return {any} The default operators for the specified MUI Data Grid data type.
   */
  static getMuiDataGridDefaultOperators(muiDataGridDataType) {
    const defaultOperatorMapping = {
      actions: null,
      boolean: getGridBooleanOperators(),
      date: getGridDateOperators(),
      dateTime: getGridDateOperators(true),
      number: getGridNumericOperators(),
      singleSelect: getGridSingleSelectOperators(),
      string: getGridStringOperators(),
    };

    return defaultOperatorMapping[muiDataGridDataType];
  }

  /**
   * Get all MUI Data Grid operators that can be used in MUI Data Grid when data is loaded from the backend.
   *
   * @return {array} The list of applicable MUI Data Grid operators.
   */
  static getApplicableMuiDataGridOperators() {
    return FilterProps.OPERATOR_STRING_MAPPING.filter(({ BACKEND_OPERATOR }) =>
      Boolean(BACKEND_OPERATOR),
    ).map(({ MUI_DATA_GRID_OPERATOR }) => MUI_DATA_GRID_OPERATOR);
  }

  static getParsedMuiDataGridFilters(filterModel) {
    return filterModel.items
      .map((muiDataGridFilterModel) => {
        return MuiDataGridFilter.getParsedMuiDataGridFilter(
          muiDataGridFilterModel,
          null,
          filterModel.logicOperator,
        );
      })
      .filter((filter) => !FilterNew.filterContainsEmptyValue(filter));
  }

  static getParsedMuiDataGridFilter(
    muiDataGridFilter,
    filterContext = null,
    logicOperator,
  ) {
    // Due to legacy filters, there might still be a filter on the delivery list actions;
    // FIXME: fix with VGS-7341 data migration!
    if (muiDataGridFilter.field === 'deliveryListActions') {
      return new FilterNew();
    }

    // Use provided filterContext or create default one
    const context =
      filterContext ||
      getFilterContext({
        component: 'filterModel',
        page: 'delivery',
      });

    let { field } = muiDataGridFilter;

    if (!field && muiDataGridFilter.columnField) {
      // FIXME: fix with VGS-7341 data migration!
      field = muiDataGridFilter.columnField;
    }

    const operator = FilterProps.getOperatorFromMuiDataGridOperator(
      muiDataGridFilter.operatorValue,
    );

    // Handle 'isEmpty' operatorValue by setting 'eq' operator with empty string value
    const value =
      muiDataGridFilter.operatorValue === 'isEmpty'
        ? ''
        : muiDataGridFilter.value;

    // This is a workaround so that article filter from the mui data grid filter model also works on the archive mode.
    if (field === DeliveryNote.PROPERTY.MAIN_ARTICLE_TYPE.KEY) {
      field = DeliveryNote.PROPERTY.ARTICLE.KEY;
    }

    return new FilterNew({
      field,
      filterContext: context,
      logicOperator,
      operator,
      value,
    });
  }
}
