export const InvoiceOutlinedIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M29.25 5.25L27 3L24.75 5.25L22.5 3L20.25 5.25L18 3L15.75 5.25L13.5 3L11.25 5.25L9 3L6.75 5.25L4.5 3V33L6.75 30.75L9 33L11.25 30.75L13.5 33L15.75 30.75L18 33L20.25 30.75L22.5 33L24.75 30.75L27 33L29.25 30.75L31.5 33V3L29.25 5.25ZM28.5 28.5H7.5V7.5H28.5V28.5ZM9 22.5H27V25.5H9M9 16.5H27V19.5H9M9 10.5H27V13.5H9V10.5Z"
      fill="currentColor"
    />
  </svg>
);
