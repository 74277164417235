export const UserSignatureIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M43.4 26.7L41.4 28.7L37.3 24.6L39.3 22.6C39.5056 22.3985 39.7821 22.2856 40.07 22.2856C40.3579 22.2856 40.6344 22.3985 40.84 22.6L43.4 25.16C43.82 25.58 43.82 26.28 43.4 26.7ZM24 37.88L36.12 25.76L40.22 29.86L28.12 42H24V37.88ZM24 28C15.16 28 8 31.58 8 36V40H20V36.22L28 28.22C26.68 28.06 25.34 28 24 28ZM24 8C21.8783 8 19.8434 8.84285 18.3431 10.3431C16.8429 11.8434 16 13.8783 16 16C16 18.1217 16.8429 20.1566 18.3431 21.6569C19.8434 23.1571 21.8783 24 24 24C26.1217 24 28.1566 23.1571 29.6569 21.6569C31.1571 20.1566 32 18.1217 32 16C32 13.8783 31.1571 11.8434 29.6569 10.3431C28.1566 8.84285 26.1217 8 24 8Z"
      fill="currentColor"
    />
  </svg>
);
