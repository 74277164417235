import { configureStore } from '@reduxjs/toolkit';

import articleMaster from './articleMasterSlice';
import backdrop from './backdropSlice';
import companies from './companiesSlice';
import companyAccount from './companyAccountSlice';
import costCenters from './costCentersSlice';
import customerSubscriptions from './customerSubscriptionsSlice';
import customFields from './customFieldsSlice';
import dataExchanges from './dataExchangesSlice';
import dataSubscriptions from './dataSubscriptionsSlice';
import deliveryNotes from './deliveryNotesSlice';
import filters from './filtersSlice';
import invoices from './invoicesSlice';
import organisationalGroups from './organisationalGroupsSlice';
import pdfTemplates from './pdfTemplatesSlice';
import signatureFields from './signatureFieldsSlice';
import sites from './sitesSlice';
import userGroups from './userGroupsSlice';
import userinfo from './userinfoSlice';
import users from './usersSlice';
import vehicles from './vehiclesSlice';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    articleMaster,
    backdrop,
    companies,
    companyAccount,
    costCenters,
    customerSubscriptions,
    customFields,
    dataExchanges,
    dataSubscriptions,
    deliveryNotes,
    filters,
    invoices,
    organisationalGroups,
    pdfTemplates,
    signatureFields,
    sites,
    userGroups,
    userinfo,
    users,
    vehicles,
  },
});

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  globalThis.store = store; // Assuming `store` is your Redux store instance
}

export default store;
