import { type UncefactUnitType, UNITS } from '~/constants/units';

import { joinComponents } from './array';
import { isValidNumber } from './number';

/**
 * Formats a number according to German locale standards
 */
export const formatDe = (number: number) => {
  return number.toLocaleString('de-DE', {
    maximumFractionDigits: 20,
    minimumFractionDigits: 0,
  });
};

/**
 * Safely formats a number according to German locale standards
 */
export const formatDeSafe = (number: number | undefined) => {
  if (number == null) {
    return null;
  }

  return formatDe(number);
};

/**
 * Rounds a number to 2 decimal places
 */
export const round = (number: number) => Math.round(number * 100) / 100;

/**
 * Safely rounds a number to 2 decimal places
 */
export const roundSafe = (number: number | undefined) => {
  if (number == null) {
    return null;
  }

  const roundedNumber = round(number);

  if (!isValidNumber(roundedNumber)) {
    return null;
  }

  return roundedNumber;
};

/**
 * Formats a number with German locale and rounds to 2 decimal places
 */
export const roundAndFormatDe = (number: number) => formatDe(round(number));

/**
 * Safely formats a number with German locale and rounds to 2 decimal places
 */
export const roundAndFormatDeSafe = (number?: number) =>
  formatDeSafe(roundSafe(number));

/**
 * Gets the descriptive (long) name of a unit
 */
export const getDescriptiveUnit = (standardisedUnit: UncefactUnitType | '') => {
  if (!standardisedUnit) {
    return '';
  }

  return UNITS[standardisedUnit]?.labelLong ?? standardisedUnit;
};

/**
 * Gets the abbreviated name of a unit
 */
export const getAbbreviatedUnit = (standardisedUnit: UncefactUnitType | '') => {
  if (!standardisedUnit) {
    return '';
  }

  return UNITS[standardisedUnit]?.label ?? standardisedUnit;
};

/**
 * Gets abbreviated names for multiple units
 */
export const getAbbreviatedUnits = (standardisedUnits: string) => {
  if (!standardisedUnits) {
    return '';
  }

  const units = standardisedUnits.split(', ');
  const abbreviatedUnits = units.map((unit) =>
    getAbbreviatedUnit(unit as UncefactUnitType),
  );

  return joinComponents(abbreviatedUnits);
};

/**
 * Gets the type of a unit (weight, volume, amount, etc)
 */
export const getUnitType = (standardisedUnit: UncefactUnitType | '') => {
  if (!standardisedUnit) {
    return null;
  }

  return UNITS[standardisedUnit]?.type ?? null;
};

/**
 * Safely gets the type of a unit, defaulting to 'amount'
 */
export const getUnitTypeSafe = (standardisedUnit: UncefactUnitType | '') =>
  getUnitType(standardisedUnit) ?? 'amount';

/**
 * Checks if a unit is a volume unit
 */
export const isVolumeUnit = (standardisedUnit: UncefactUnitType | '') =>
  getUnitType(standardisedUnit) === 'volume';

/**
 * Checks if a unit is a weight unit
 */
export const isWeightUnit = (standardisedUnit: UncefactUnitType | '') =>
  getUnitType(standardisedUnit) === 'weight';

/**
 * Checks if a unit is an amount unit
 */
export const isAmountUnit = (standardisedUnit: UncefactUnitType | '') =>
  getUnitType(standardisedUnit) === 'amount';

/**
 * Converts a value from one unit to another
 */
export const calculateWeightInTargetUnit = (
  value: number,
  unit: UncefactUnitType,
  targetUnit: UncefactUnitType,
) => {
  if (unit === targetUnit) {
    return value;
  }

  const sourceUnitData = UNITS[unit];
  const targetUnitData = UNITS[targetUnit];

  if (!sourceUnitData?.factor || !targetUnitData?.factor) {
    return 0;
  }

  return (value * sourceUnitData.factor) / targetUnitData.factor;
};

/**
 * Formats a value-unit pair safely as a string
 */
export const formatValueUnitPairAsString = (
  value: number | undefined,
  unit: UncefactUnitType | '',
  unitFormatter: (unit: UncefactUnitType | '') => string,
) => {
  const formattedValue = formatDeSafe(value);
  const formattedUnit = unitFormatter(unit);

  if (!formattedValue || !formattedUnit) {
    return null;
  }

  return [formattedValue, formattedUnit].join(' ');
};

/**
 * Parses a string to a number, returning null if invalid
 */
export const parseToNumber = (value: string | number | undefined) => {
  if (value == null) {
    return null;
  }

  const number = Number(value);

  if (!isValidNumber(number)) {
    return null;
  }

  return number;
};

/**
 * Handles leading and trailing decimal separators in number strings
 */
const handleLeadingAndFollowingDelimiter = (
  string: string,
  separator: string,
) => {
  if (string.endsWith(separator)) {
    string = string.slice(0, -1); // Remove last character if string ends with separator
  }

  if (string.startsWith(separator)) {
    string = `0${string}`; // Prepend 0 if string starts with separator
  }

  return string;
};

/**
 * Validates if a string represents a valid German format number
 */
const isDeNumber = (string: string) => {
  string = handleLeadingAndFollowingDelimiter(string, ',');

  if (string === '') {
    return true;
  }

  if (string.includes('.')) {
    return /^-?\d{1,3}(?:\.\d{3})*(?:,\d+)?$/.test(string);
  }

  return /^\d+,?\d*\b$/.test(string);
};

/**
 * Validates if a string represents a valid English format number
 */
const isEnNumber = (string: string) => {
  string = handleLeadingAndFollowingDelimiter(string, '.');

  if (string === '') {
    return true;
  }

  if (string.includes(',')) {
    return /^-?\d{1,3}(?:,\d{3})*(?:\.\d+)?$/.test(string);
  }

  return /^\d+\.?\d*\b$/.test(string);
};

/**
 * Formats a complex number string to German format, handling both German and English input formats
 * @throws {Error} If the input string is not a valid number format
 */
export const complexFormatDe = (string: string) => {
  let deNumberExpected = !(!string.includes(',') && string.includes('.'));

  if (
    string.includes(',') &&
    string.includes('.') &&
    string.indexOf(',') < string.indexOf('.')
  ) {
    // e.g. 2,971.97
    deNumberExpected = false;
  }

  if (deNumberExpected) {
    if (!isDeNumber(string)) {
      throw new Error('Invalid de number');
    }

    // Cut out all chars that are neither a number nor a comma.
    return string.replaceAll(/[^\d,-]/g, '');
  }

  if (!isEnNumber(string)) {
    throw new Error('Invalid en number');
  }

  // Cut out all chars that are neither a number nor a dot and format to de.
  return string.replaceAll(/[^\d.-]/g, '').replace('.', ',');
};

/**
 * Safely formats a money amount with exactly two decimal places and thousand separators
 */
export const formatDeMoneyAmount = (number: number) => {
  let amount = roundAndFormatDe(number);
  const [integers, decimals] = amount.split(',');

  if (decimals) {
    // Add trailing zeros if needed
    amount = amount.padEnd(amount.length + (2 - decimals.length), '0');
  } else {
    amount = `${integers},00`;
  }

  // Add dot as thousands separator
  return amount.replaceAll(/\B(?=(\d{3})+(?!\d))/g, '.');
};

/**
 * Safely formats a money amount, handling undefined values
 */
export const formatDeMoneyAmountSafe = (number: number | undefined) => {
  if (number == null) {
    return null;
  }

  const roundedNumber = roundSafe(number);
  if (roundedNumber == null) {
    return null;
  }

  return formatDeMoneyAmount(roundedNumber);
};

/**
 * Formats a number with German locale and specified precision
 */
export const formatDeWithPrecision = (number: number, precision: number) => {
  return number.toLocaleString('de-DE', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
};

/**
 * Safely formats a number with German locale and specified precision
 */
export const formatDeWithPrecisionSafe = (
  number: number | undefined,
  precision: number,
) => {
  if (number == null) {
    return null;
  }

  if (!isValidNumber(number)) {
    return null;
  }

  return formatDeWithPrecision(number, precision);
};

/**
 * Formats a string to German number format
 */
export const formatStringDe = (value: string) => {
  if (!value) {
    return '';
  }

  return complexFormatDe(value);
};

/**
 * Safely formats a string to German number format
 */
export const formatStringDeSafe = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  try {
    return formatStringDe(value);
  } catch {
    return null;
  }
};

/**
 * Safely formats a string value-unit pair
 */
export const formatStringUnitPairSafe = (
  value: string | undefined,
  unit: UncefactUnitType | '',
  unitFormatter: (unit: UncefactUnitType | '') => string,
) => {
  if (!value) {
    return null;
  }

  try {
    const formattedValue = formatStringDe(value);
    const formattedUnit = unitFormatter(unit);

    if (!formattedValue || !formattedUnit) {
      return null;
    }

    return `${formattedValue} ${formattedUnit}`;
  } catch {
    return null;
  }
};

/**
 * Safely formats a value-unit pair
 */
export const formatValueUnitPairSafe = (
  value: number | undefined,
  unit: UncefactUnitType | '',
  unitFormatter: (unit: UncefactUnitType | '') => string,
) => {
  const formattedValue = formatDeSafe(value);
  const formattedUnit = unitFormatter(unit);

  if (!formattedValue || !formattedUnit) {
    return null;
  }

  return `${formattedValue} ${formattedUnit}`;
};

/**
 * Safely formats a value-unit pair as a string
 */
export const formatValueUnitPairAsStringSafe = (
  value: number | undefined,
  unit: UncefactUnitType | '',
  unitFormatter: (unit: UncefactUnitType | '') => string,
) => {
  const formattedValue = formatDeSafe(value);
  const formattedUnit = unitFormatter(unit);

  if (!formattedValue || !formattedUnit) {
    return null;
  }

  return [formattedValue, formattedUnit].join(' ');
};

/**
 * Gets the biggest unit from a list of units
 */
export const getBiggestUnit = (units: UncefactUnitType[]) => {
  if (!units?.length) {
    return null;
  }

  let biggest = units.find(Boolean);

  if (!biggest) {
    return null;
  }

  for (const current of units) {
    const biggestUnit = UNITS[biggest];
    const currentUnit = UNITS[current];

    if (!biggestUnit?.factor || !currentUnit?.factor) {
      continue;
    }

    if (currentUnit.factor > biggestUnit.factor) {
      biggest = current;
    }
  }

  return biggest;
};

/**
 * Converts coordinates between different units
 */
export const getConvertedCoordinate = (
  value: number,
  fromUnit: UncefactUnitType,
  toUnit: UncefactUnitType,
) => calculateWeightInTargetUnit(value, fromUnit, toUnit);

/**
 * Validates if a string represents valid coordinates
 */
export const isValidCoordinate = (value: string) => {
  if (!value) {
    return false;
  }

  try {
    const formattedValue = complexFormatDe(value);
    const number = parseToNumber(formattedValue);

    return number !== null;
  } catch {
    return false;
  }
};
