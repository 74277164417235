import { type PropsWithChildren } from 'react';

type P = PropsWithChildren<{
  readonly isFinished: boolean;
  readonly animationDuration: number;
  readonly zIndex?: number;
}>;

export const Container = ({
  animationDuration,
  children,
  isFinished,
  zIndex = 1501,
}: P) => (
  <div
    style={{
      left: 0,
      opacity: isFinished ? 0 : 1,
      pointerEvents: 'none',
      position: 'fixed',
      top: 0,
      transition: `opacity ${animationDuration}ms linear`,
      width: '100%',
      zIndex,
    }}
  >
    {children}
  </div>
);
