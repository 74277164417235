import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';
import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';
import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import {
  replaceInvoiceCheckIgnoredArticles,
  saveIncomingInvoicesFromBackend,
  saveOutgoingInvoicesFromBackend,
  setInvoiceCheckIgnoredArticlesLoading,
} from '~/redux/invoicesSlice';
import store from '~/redux/store';

import InvoiceModel from '~/models/invoices/Invoice';
import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import InvoiceCheckIgnoredArticle from '~/models/invoices/InvoiceCheckIgnoredArticle';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import axios from '~/utils/api-client';
import { dateUtils } from '~/utils/dateUtils';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Log from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import ToastService from './toast.service';

const INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL =
  apiUrl + '/invoice_verification/ignored_article';

// time to wait before next query
const waitTime = ms('50s');

const limit = 1000;

class InvoiceService {
  async getInvoiceById(id, direction) {
    let invoice = null;

    if (direction === InvoiceModel.DIRECTION.INCOMING) {
      invoice = store
        .getState()
        .invoices?.incomingInvoices?.find((inv) => inv.id === id);
    } else if (direction === InvoiceModel.DIRECTION.OUTGOING) {
      invoice = store
        .getState()
        .invoices?.outgoingInvoices?.find((inv) => inv.id === id);
    }

    if (invoice) {
      return ES6ClassFactory.convertToES6Class(
        [invoice],
        new InvoiceModel(),
      )[0];
    }

    return axios.get(apiUrl + '/asset/invoice/' + id).then((response) => {
      const newInvoice = new InvoiceModel(response.data, direction);
      newInvoice.initWithReferencedDeliveryNotes(true, true);
      return newInvoice;
    });
  }

  async getAllIncomingInvoices(
    url = '/asset/invoice?limit=' + limit + '&incoming=true',
  ) {
    return axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.INCOMING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              `Failed to initialize invoice. id: ${data.assets[index]?._id}`,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveIncomingInvoicesFromBackend(invoices));

        const { updateLink } = data;
        if (updateLink) {
          setTimeout(
            function () {
              this.runIncomingInvoiceUpdater(updateLink);
            }.bind(this),
            waitTime,
          );
        }

        const { nextLink } = data;
        if (nextLink) {
          this.getIncomingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoice',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  async runIncomingInvoiceUpdater(url) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const { updateLink } = data;

        if (!updateLink) {
          return;
        }

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.INCOMING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              `Failed to initialize invoice. id: ${data.assets[index]?._id}`,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveIncomingInvoicesFromBackend(invoices));

        setTimeout(
          function () {
            this.runIncomingInvoiceUpdater(updateLink);
          }.bind(this),
          waitTime,
        );
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }

  async getIncomingInvoiceNextLink(
    url = ENDPOINT.INVOICE.GET_ALL({ incoming: true, limit }),
  ) {
    try {
      const response = await axios.get(apiUrl + url);
      const { data } = response;
      const assets = data.assets || [];
      const invoices = [];
      let index = 0;

      const processNextBatch = () => {
        return new Promise((resolve) => {
          requestAnimationFrame(() => {
            const endIndex = Math.min(index + 10, assets.length); // Process 10 invoices per frame

            while (index < endIndex) {
              try {
                const invoice = new InvoiceModel(
                  assets[index],
                  InvoiceModel.DIRECTION.INCOMING,
                );
                invoice.initWithReferencedDeliveryNotes(true, true);
                invoices.push(invoice);
              } catch (error) {
                Log.error(
                  `Failed to initialize invoice. id: ${assets[index]?._id}`,
                  error,
                );
                Log.productAnalyticsEvent(
                  'Failed to initialize invoice',
                  Log.FEATURE.INVOICE,
                  Log.TYPE.ERROR,
                );
              }

              index++;
            }

            if (index < assets.length) {
              // Schedule next batch with setTimeout to give UI a chance to respond
              setTimeout(() => {
                processNextBatch().then(resolve);
              }, 0);
            } else {
              resolve();
            }
          });
        });
      };

      await processNextBatch();

      store.dispatch(saveIncomingInvoicesFromBackend(invoices));

      const { nextLink } = data;
      if (nextLink) {
        this.getIncomingInvoiceNextLink(nextLink);
      }
    } catch (error) {
      ToastService.warning([
        'Eingangsrechnungen konnten nicht vollständig geladen werden.',
      ]);

      Log.error('Failed to load invoices.', error);
      Log.productAnalyticsEvent(
        'Failed to load invoices',
        Log.FEATURE.INVOICE,
        Log.TYPE.ERROR,
      );
    }
  }

  async getAllOutgoingInvoices(
    url = '/asset/invoice?limit=' + limit + '&outgoing=true',
  ) {
    return axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const { updateLink } = data;

        if (!updateLink) {
          return;
        }

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        setTimeout(
          function () {
            this.runOutgoingInvoiceUpdater(updateLink);
          }.bind(this),
          waitTime,
        );

        const { nextLink } = data;
        if (nextLink) {
          this.getOutgoingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
        throw error;
      });
  }

  async runOutgoingInvoiceUpdater(url) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        const { updateLink } = data;
        if (updateLink) {
          setTimeout(
            function () {
              this.runOutgoingInvoiceUpdater(updateLink);
            }.bind(this),
            waitTime,
          );
        }
      })
      .catch((error) => {
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }

  async getOutgoingInvoiceNextLink(
    url = '/asset/invoice?limit=' + limit + '&outgoing=true',
  ) {
    axios
      .get(apiUrl + url)
      .then(async (response) => {
        const { data } = response;

        const invoices = [];

        for (let index = 0; index < data.assets?.length; index++) {
          try {
            const invoice = new InvoiceModel(
              data.assets[index],
              InvoiceModel.DIRECTION.OUTGOING,
            );
            invoice.initWithReferencedDeliveryNotes(true, true);

            invoices.push(invoice);
          } catch (error) {
            Log.error(
              'Failed to initialize invoice. id: ' + data.assets[index]?._id,
              error,
            );
            Log.productAnalyticsEvent(
              'Failed to initialize invoice',
              Log.FEATURE.INVOICE,
              Log.TYPE.ERROR,
            );
          }
        }

        store.dispatch(saveOutgoingInvoicesFromBackend(invoices));

        const { nextLink } = data;
        if (nextLink) {
          this.getOutgoingInvoiceNextLink(nextLink);
        }
      })
      .catch((error) => {
        ToastService.warning([
          'Ausgangsrechnungen konnten nicht vollständig geladen werden.',
        ]);
        Log.error('Failed to load invoices.', error);
        Log.productAnalyticsEvent(
          'Failed to load invoices',
          Log.FEATURE.INVOICE,
          Log.TYPE.ERROR,
        );
      });
  }

  getAllInvoiceCheckIgnoredArticles() {
    return axios
      .get(INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL)
      .then((response) => {
        if (response.status !== 200) {
          return [];
        }

        return response.data.items.map(
          (item) => new InvoiceCheckIgnoredArticle(item),
        );
      });
  }

  async createInvoiceCheckIgnoredArticle(body) {
    return axios
      .post(INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL, body)
      .then((response) => {
        return response.data?.id;
      });
  }

  async updateInvoiceCheckIgnoredArticle(id, body) {
    return axios.put(
      INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL + '/' + id,
      body,
    );
  }

  async deleteInvoiceCheckIgnoredArticle(id) {
    return axios.delete(
      INVOICE_VERIFICATION_IGNORED_ARTICLE_API_URL + '/' + id,
    );
  }

  loadInvoiceCheckIgnoredArticles = async () => {
    // to not load articles again when they are already loading or have already been loaded
    if (
      store.getState().invoices?.invoiceCheckIgnoredArticlesLoading !==
      LOADING_STATE.NOT_LOADED
    ) {
      return;
    }

    this.refreshInvoiceCheckIgnoredArticles();
  };
  refreshInvoiceCheckIgnoredArticles = async () => {
    store.dispatch(
      setInvoiceCheckIgnoredArticlesLoading(LOADING_STATE.LOADING),
    );

    const [invoiceCheckIgnoredArticles, error] = await promiseHandler(
      this.getAllInvoiceCheckIgnoredArticles(),
    );

    if (error) {
      store.dispatch(
        setInvoiceCheckIgnoredArticlesLoading(LOADING_STATE.FAILED),
      );
      Log.error('Failed to load invoice check ignored articles.', error);
      Log.productAnalyticsEvent(
        'Failed to load invoice check ignored articles',
        Log.FEATURE.INVOICE_CHECK,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(
      replaceInvoiceCheckIgnoredArticles(invoiceCheckIgnoredArticles),
    );
  };

  filterRows(data) {
    if (!data) {
      return [];
    }

    let timeframe = null;
    if (data.dateRange) {
      timeframe = dateUtils.extractTimeframe(data.dateRange);
    }

    return data.rows.filter((row) => {
      // queryMatches should filter out most of the rows if query is given.
      // Thus, the other filters don't need to be evaluated and a better performance should be achieved.
      if (data.query !== '') {
        let queryMatches = true;

        if (data.selectField === 'all') {
          queryMatches = row.searchString.includes(data.query.toLowerCase());
        } else if (data.selectField) {
          if (row[data.selectField] === undefined) {
            Log.error(
              'Failed to find select field of free text filter in rows of invoice overview table.',
            );
          }

          queryMatches = String(row[data.selectField])
            .toLowerCase()
            .includes(data.query.toLowerCase());
        }

        if (!queryMatches) {
          return false;
        }
      }

      if (timeframe) {
        const rowDate = Date.parse(row.date);

        const inDateRange =
          rowDate >= timeframe.from && rowDate <= timeframe.to;
        if (!inDateRange) {
          return false;
        }
      }

      const correctSeller =
        data.selectedSeller.length === 0 ||
        data.selectedSeller.includes(row.seller) ||
        (data.selectedSeller.includes(EMPTY_DROPDOWN_OPTION) && !row.seller);
      if (!correctSeller) {
        return false;
      }

      const correctBuyer =
        data.selectedBuyer.length === 0 ||
        data.selectedBuyer.includes(row.buyer) ||
        (data.selectedBuyer.includes(EMPTY_DROPDOWN_OPTION) && !row.buyer);
      if (!correctBuyer) {
        return false;
      }

      const correctNumber =
        data.selectedNumber.length === 0 ||
        data.selectedNumber.includes(row.number) ||
        (data.selectedNumber.includes(EMPTY_DROPDOWN_OPTION) && !row.number);
      if (!correctNumber) {
        return false;
      }

      const correctToSite =
        data.selectedToSite.length === 0 ||
        data.selectedToSite.includes(row.toSite) ||
        (data.selectedToSite.includes(EMPTY_DROPDOWN_OPTION) && !row.toSite);
      if (!correctToSite) {
        return false;
      }

      let correctStatus = false;

      if (data.selectedStatus.length === 0) {
        correctStatus = true;
      } else {
        if (
          data.selectedStatus.includes(InvoiceModel.FILTER_CATEGORY.CORRECT) &&
          row.status === InvoiceCheckResult.STATUS.SUCCESS
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.DELAYED_SIGNED,
          ) &&
          row.delayedSigned
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.NO_CHECKING_POSSIBLE,
          ) &&
          row.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.FORMAL_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.FORMAL_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.DLN_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.SIGNATURE_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.ARTICLE_EXISTS_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.ARTICLE_EXISTS_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.AMOUNT_CHECK_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.AMOUNT_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }

        if (
          data.selectedStatus.includes(
            InvoiceModel.FILTER_CATEGORY.AMOUNT_APPROVED_CHECK_ERROR,
          ) &&
          row.errorAndWarningCategories.includes(
            InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY,
          )
        ) {
          correctStatus = true;
        }
      }

      return correctStatus;
    });
  }
}

export default new InvoiceService();
