import { DateRange as DateRangeIcon } from '@mui/icons-material';
import {
  FormControl,
  MenuItem,
  Select,
  type SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';

import { type DateRange } from '~/types/common';

import { dayjs } from '~/utils/datetime';
import { dateFull } from '~/utils/l10n';

import { withErrorBoundary } from '~/ui/atoms';

import {
  type DateRangeOption,
  dateRangeOptions,
  dateRangesOrder,
} from './constants';
import { DateRangePicker } from './DateRangePicker';

type AllDateRangeOptions = DateRangeOption | 'individual';

type P = {
  dateRange: DateRange;
  isDisabled?: boolean;
  isIndividualDateRange?: boolean;
  onDateRangeChange: (dateRange: DateRange) => void;
  onPredefinedDateRangeChange: (
    predefinedDateRange: DateRangeOption | undefined,
  ) => void;
  predefinedDateRange: DateRangeOption | undefined;
  testId?: string;
};

export const DateRangeSelect = withErrorBoundary(
  ({
    dateRange,
    isDisabled,
    isIndividualDateRange,
    onDateRangeChange,
    onPredefinedDateRangeChange,
    predefinedDateRange,
    testId,
  }: P) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleChangePredefinedDateRange = (value: AllDateRangeOptions) => {
      onPredefinedDateRangeChange(value === 'individual' ? undefined : value);
      setIsOpen(false);
    };

    const handleChangeIndividualDateRange = (dateRange: DateRange) => {
      onDateRangeChange(dateRange);
    };

    const renderValue = () => (
      <div>
        {isIndividualDateRange
          ? dateRange.map((date) => dayjs(date).format(dateFull)).join(' - ')
          : predefinedDateRange
            ? dateRangeOptions[predefinedDateRange]
            : 'Select date range'}
      </div>
    );

    return (
      <FormControl>
        <div className="flex items-center gap-4">
          <Select
            key="date-range-select-0"
            className="min-w-48 bg-white pl-2"
            data-testid={testId}
            disabled={isDisabled}
            id="date-range-select"
            labelId="date-range-label"
            open={isOpen}
            renderValue={renderValue}
            size="small"
            startAdornment={
              isDisabled ? null : (
                <DateRangeIcon
                  className="mr-1 cursor-pointer text-gray-500"
                  disabled={isDisabled}
                  onClick={() => {
                    setIsOpen(true);
                  }}
                />
              )
            }
            value={predefinedDateRange ?? ''}
            onChange={(event: SelectChangeEvent) => {
              handleChangePredefinedDateRange(
                event.target.value as AllDateRangeOptions,
              );
            }}
            onClose={() => {
              setIsOpen(false);
            }}
            onOpen={() => {
              setIsOpen(true);
            }}
          >
            {dateRangesOrder.map((value) => (
              <MenuItem
                key={value}
                value={value}
                className="border-b border-gray-200 px-3 py-2"
              >
                {dateRangeOptions[value]}
              </MenuItem>
            ))}
            <div key="individual" className="px-2 pt-3">
              <DateRangePicker
                value={dateRange}
                isDisabled={isDisabled}
                onChange={handleChangeIndividualDateRange}
              />
            </div>
          </Select>
        </div>
      </FormControl>
    );
  },
  null,
);

DateRangeSelect.displayName = 'DateRangeSelect';
