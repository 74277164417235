import {
  AddLocation as AddLocationIcon,
  BackupTable as BackupTableIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  PersonAdd as PersonAddIcon,
  PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import {
  Button,
  type ButtonProps,
  Menu,
  MenuItem as MuiMenuItem,
} from '@mui/material';
import { memo, type MouseEvent, useCallback, useMemo, useState } from 'react';

import { Spinner } from '~/ui/atoms/Spinner';

import { SignatureIcon, UserSignatureIcon } from '~/assets/icons';

const ICON = {
  csv: <BackupTableIcon fontSize="small" />,
  excel: <BackupTableIcon fontSize="small" />,
  mapDirectDeliveryNote: <AddLocationIcon fontSize="small" />,
  pdf: <PictureAsPdfIcon fontSize="small" />,
  permissionGrant: <LockOpenOutlinedIcon fontSize="small" />,
  requestSignature: <UserSignatureIcon className="size-5" />,
  shareDeliveryNote: <PersonAddIcon fontSize="small" />,
  signature: <SignatureIcon className="size-5" />,
} as const;

type MenuItemType = {
  isLoading?: boolean;
  name: string;
  onClick: () => void;
  selected?: boolean;
};

type DatagridToolbarCustomComponentProps = {
  readonly buttonProps?: ButtonProps;
  readonly icon?: keyof typeof ICON;
  readonly menuItems: MenuItemType[];
  readonly title?: string;
};

export const DatagridToolbarCustomComponent = memo(
  ({
    buttonProps,
    icon,
    menuItems,
    title,
  }: DatagridToolbarCustomComponentProps) => {
    const [anchorElement, setAnchorElement] = useState<HTMLElement | undefined>(
      undefined,
    );

    const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
      setAnchorElement(event.currentTarget);
    }, []);

    const handleCloseMenu = useCallback(() => {
      setAnchorElement(undefined);
    }, []);

    const Icon = useMemo(() => {
      if (icon) {
        return ICON[icon];
      }

      return null;
    }, [icon]);

    const SingleButton = useMemo(() => {
      if (menuItems.length !== 1) return null;
      const menuItem = menuItems[0];
      if (!menuItem) return null;

      return (
        <Button
          variant="outlined"
          size="small"
          startIcon={Icon}
          onMouseDown={() => {
            menuItem.onClick();
          }}
          {...buttonProps}
        >
          {menuItem.isLoading ? <Spinner /> : null}
          {menuItem.name}
        </Button>
      );
    }, [menuItems, Icon, buttonProps]);

    const MenuButton = useMemo(
      () => (
        <Button
          className="datagrid-toolbar-button"
          aria-controls="simple-menu"
          aria-haspopup="true"
          size="small"
          startIcon={Icon}
          onClick={handleOpenMenu}
          {...buttonProps}
        >
          {title}
        </Button>
      ),
      [Icon, buttonProps, handleOpenMenu, title],
    );

    const MenuItems = useMemo(
      () =>
        menuItems.map((menuItem) => (
          <MuiMenuItem
            key={menuItem.name}
            selected={menuItem.selected}
            disabled={menuItem.isLoading}
            className="flex items-center gap-2"
            onMouseDown={() => {
              menuItem.onClick();
              handleCloseMenu();
            }}
          >
            {menuItem.isLoading ? <Spinner /> : null}
            {menuItem.name}
          </MuiMenuItem>
        )),
      [handleCloseMenu, menuItems],
    );

    if (menuItems.length === 1) {
      return SingleButton;
    }

    return (
      <>
        {MenuButton}
        <Menu
          keepMounted
          id="simple-menu"
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
          onClose={handleCloseMenu}
        >
          {MenuItems}
        </Menu>
      </>
    );
  },
);

DatagridToolbarCustomComponent.displayName = 'DatagridToolbarCustomComponent';
