export const SettingsIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M14.55 33L13.8 28.275C13.325 28.1 12.825 27.8625 12.3 27.5625C11.775 27.2625 11.3125 26.95 10.9125 26.625L6.4875 28.65L3 22.5L7.05 19.5375C7 19.3125 6.96875 19.0562 6.95625 18.7687C6.94375 18.4812 6.9375 18.225 6.9375 18C6.9375 17.775 6.94375 17.5188 6.95625 17.2313C6.96875 16.9438 7 16.6875 7.05 16.4625L3 13.5L6.4875 7.35L10.9125 9.375C11.3125 9.05 11.775 8.7375 12.3 8.4375C12.825 8.1375 13.325 7.9125 13.8 7.7625L14.55 3H21.45L22.2 7.725C22.675 7.9 23.1813 8.13125 23.7188 8.41875C24.2562 8.70625 24.7125 9.025 25.0875 9.375L29.5125 7.35L33 13.5L28.95 16.3875C29 16.6375 29.0312 16.9062 29.0437 17.1937C29.0562 17.4812 29.0625 17.75 29.0625 18C29.0625 18.25 29.0562 18.5125 29.0437 18.7875C29.0312 19.0625 29 19.325 28.95 19.575L33 22.5L29.5125 28.65L25.0875 26.625C24.6875 26.95 24.2313 27.2688 23.7188 27.5813C23.2062 27.8938 22.7 28.125 22.2 28.275L21.45 33H14.55ZM18 22.875C19.35 22.875 20.5 22.4 21.45 21.45C22.4 20.5 22.875 19.35 22.875 18C22.875 16.65 22.4 15.5 21.45 14.55C20.5 13.6 19.35 13.125 18 13.125C16.65 13.125 15.5 13.6 14.55 14.55C13.6 15.5 13.125 16.65 13.125 18C13.125 19.35 13.6 20.5 14.55 21.45C15.5 22.4 16.65 22.875 18 22.875Z"
      fill="currentColor"
    />
  </svg>
);
