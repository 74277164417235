import { useDispatch } from 'react-redux';

import { LOADING_STATE } from '~/constants/LoadingState';

import { replaceCompanies, setCompaniesLoading } from '~/redux/companiesSlice';
import {
  replaceCostCenters,
  setCostCentersLoading,
} from '~/redux/costCentersSlice';
import {
  replaceCustomerSubscriptions,
  setCustomerSubscriptionsLoading,
} from '~/redux/customerSubscriptionsSlice';
import {
  replaceOrganisationalGroups,
  setOrganisationalGroupsLoading,
} from '~/redux/organisationalGroupsSlice';
import {
  replacePdfTemplates,
  setPdfTemplatesLoading,
} from '~/redux/pdfTemplatesSlice';
import { replaceSites, setSitesLoading } from '~/redux/sitesSlice';
import {
  replaceUserGroups,
  setUserGroupsLoading,
} from '~/redux/userGroupsSlice';
import { replaceUsers, setUsersLoading } from '~/redux/usersSlice';

import Log from '~/utils/logging';

/**
 * Custom hook to handle passed master data from window.redux_state.
 * This only applies when the user opens an item in a new tab using
 * BrowserUtils.openMenuItemInNewTab or BrowserUtils.openNewTab.
 *
 * This hook provides a function to check and update the Redux store with
 * master data that might have been passed via the window.redux_state object.
 * It checks for various data types (users, sites, cost centers, etc.) and
 * updates the Redux store accordingly. If the data is successfully loaded,
 * it replaces the existing data in the store. If not, it sets the loading
 * state to NOT_LOADED.
 *
 * @returns {Object} An object containing the handlePassedMasterData function
 */
export const useHandlePassedMasterData = () => {
  const dispatch = useDispatch();

  async function handlePassedMasterData() {
    if (!globalThis.redux_state) {
      return;
    }

    const { NOT_LOADED, SUCCEEDED } = LOADING_STATE;
    const {
      companies: { companies, companiesLoading },
      costCenters: { costCenters, costCentersLoading },
      customerSubscriptions: {
        customerSubscriptions,
        customerSubscriptionsLoading,
      },
      organisationalGroups: {
        organisationalGroups,
        organisationalGroupsLoading,
      },
      pdfTemplates: { pdfTemplates, pdfTemplatesLoading },
      sites: { sites, sitesLoading },
      userGroups: { userGroups, userGroupsLoading },
      users: { users, usersLoading },
    } = globalThis.redux_state;

    if (companiesLoading === SUCCEEDED) {
      Log.info('Companies state passed via window.redux_state.');
      dispatch(replaceCompanies(companies));
    } else {
      dispatch(setCompaniesLoading(NOT_LOADED));
    }

    if (costCentersLoading === SUCCEEDED) {
      Log.info('Cost centers state passed via window.redux_state.');
      dispatch(replaceCostCenters(costCenters));
    } else {
      dispatch(setCostCentersLoading(NOT_LOADED));
    }

    if (customerSubscriptionsLoading === SUCCEEDED) {
      Log.info('Customer subscriptions state passed via window.redux_state.');
      dispatch(replaceCustomerSubscriptions(customerSubscriptions));
    } else {
      dispatch(setCustomerSubscriptionsLoading(NOT_LOADED));
    }

    if (organisationalGroupsLoading === SUCCEEDED) {
      Log.info('Organisational groups state passed via window.redux_state.');
      dispatch(replaceOrganisationalGroups(organisationalGroups));
    } else {
      dispatch(setOrganisationalGroupsLoading(NOT_LOADED));
    }

    if (pdfTemplatesLoading === SUCCEEDED) {
      Log.info('Pdf templates state passed via window.redux_state.');
      dispatch(replacePdfTemplates(pdfTemplates));
    } else {
      dispatch(setPdfTemplatesLoading(NOT_LOADED));
    }

    if (sitesLoading === SUCCEEDED) {
      Log.info('Sites state passed via window.redux_state.');
      dispatch(replaceSites(sites));
    } else {
      dispatch(setSitesLoading(NOT_LOADED));
    }

    if (userGroupsLoading === SUCCEEDED) {
      Log.info('User groups state passed via window.redux_state.');
      dispatch(replaceUserGroups(userGroups));
    } else {
      dispatch(setUserGroupsLoading(NOT_LOADED));
    }

    if (usersLoading === SUCCEEDED) {
      Log.info('Users state passed via window.redux_state.');
      dispatch(replaceUsers(users));
    } else {
      dispatch(setUsersLoading(NOT_LOADED));
    }
  }

  return {
    handlePassedMasterData,
  };
};
