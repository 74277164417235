export default class CustomData {
  constructor(additionalPartyData) {
    this.setData(additionalPartyData);
  }

  setData(additionalPartyData) {
    if (!additionalPartyData) {
      return;
    }

    for (const entry of Object.entries(additionalPartyData)) {
      const key = entry[0].slice(0, 36); // Make sure the key only consists of a UUID
      const value = entry[1];

      this[key] = value;
    }
  }

  getEntries() {
    return Object.entries(this);
  }

  getLength() {
    return Object.keys(this).length;
  }
}
