import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import Log from '~/utils/logging';

import {
  DataExchangeConfirmedIcon,
  DataExchangeCurrentIssuesIcon,
  DataExchangeInterestedIcon,
  DataExchangeLiveIcon,
  DataExchangeOnHoldIcon,
  DataExchangeQrCodeIcon,
  DataExchangeQualificationIcon,
  DataExchangeSuccessIcon,
  DataExchangeSupplierIcon,
  DataExchangeTestDataIcon,
  DataExchangeTestPhaseIcon,
  DataExchangeVestigasIcon,
} from '~/assets/icons';

export const DataExchangeStatusIcon = ({
  status,
}: {
  readonly status: string;
}) => {
  switch (status) {
    case DataExchangeCompany.STATUS.ACTIVATED.KEY: {
      return <DataExchangeSuccessIcon className="text-successBase size-6" />;
    }

    case DataExchangeCompany.STATUS.LIVE.KEY: {
      return <DataExchangeLiveIcon className="text-successBase size-6" />;
    }

    case DataExchangeCompany.STATUS.QR_CODE.KEY: {
      return <DataExchangeQrCodeIcon className="text-successBase size-6" />;
    }

    case DataExchangeCompany.STATUS.TEST_PHASE.KEY: {
      return <DataExchangeTestPhaseIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.IMPLEMENTATION_SUPPLIER.KEY: {
      return <DataExchangeSupplierIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.IMPLEMENTATION_VESTIGAS.KEY: {
      return <DataExchangeVestigasIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.TEST_DATA_EXCHANGED.KEY: {
      return <DataExchangeTestDataIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.CONFIRMED.KEY: {
      return <DataExchangeConfirmedIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.ON_HOLD_SUPPLIER.KEY: {
      return <DataExchangeOnHoldIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.IN_QUALIFICATION.KEY: {
      return <DataExchangeQualificationIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.INTERESTED.KEY: {
      return <DataExchangeInterestedIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.NO_STATUS.KEY: {
      return <DataExchangeInterestedIcon className="size-6 text-gray-600" />;
    }

    case DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY: {
      return <DataExchangeCurrentIssuesIcon className="size-6 text-red-700" />;
    }

    default: {
      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid data exchange status: ${this.props.status}`,
        ),
      );

      return null;
    }
  }
};
