import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { sgwUrl } from '~/constants/environment';

import AuthService from '~/services/auth.service';

const axiosInstance = axios.create();

createAuthRefreshInterceptor(axiosInstance, AuthService.refreshTokens);

axiosInstance.interceptors.request.use((request) => {
  // If the access or id token as already been set manually, don't overwrite it. This is the case in the ids flow. (see ids.service.js)
  if (request.headers.Authorization) {
    return request;
  }

  // All calls to SGW and thus id token have been deprecated due to issues with SGW. Only use if approved by Yannick.
  const token = request.url.includes(sgwUrl)
    ? AuthService.getIdToken()
    : AuthService.getAccessToken();

  request.headers.Authorization = `Bearer ${token}`;

  return request;
});

export default axiosInstance;
