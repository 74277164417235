import { keepPreviousData } from '@tanstack/react-query';
import { useMemo } from 'react';

// import { KPI } from '~/ui/molecules/KPI';

// import UnitUtils from '~/utils/unitUtils';

import { type DateRange } from '~/types/common';

import { useQueryDeliveryNoteAnalytics } from '~/data/deliveryNote';

import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';

import { type AggregationInterval } from '../types';
import { generatePieChartData, generateTimeSeriesChartData } from '../utils';

import { DashboardDetailOverview } from './DashboardDetailOverview';
import { DoughnutChart } from './DoughnutChart';
import { TimeSeriesChart } from './TimeSeriesChart';

type P = {
  aggregationInterval: AggregationInterval;
  searchBody: any;
  selectedDateRange: DateRange;
  selectedUnit: string;
};

export const DashboardCharts = withErrorBoundary(
  ({ aggregationInterval, searchBody, selectedDateRange, selectedUnit }: P) => {
    const { data, isFetching, isLoading } = useQueryDeliveryNoteAnalytics(
      {
        ...searchBody,
        timeAggregate: aggregationInterval,
        unitType: selectedUnit,
      },
      {
        enabled: Boolean(selectedUnit) && Boolean(aggregationInterval),
        placeholderData: keepPreviousData,
      },
    );

    const distributionData = useMemo(() => generatePieChartData(data), [data]);

    // const articlesGrandTotal = distributionData?.datasets?.[0]?.data?.reduce(
    //   (accumulator, current) => accumulator + current,
    //   0,
    // );

    const timeSeriesData = useMemo(
      () =>
        generateTimeSeriesChartData(
          data,
          selectedDateRange,
          aggregationInterval,
        ),
      [aggregationInterval, data, selectedDateRange],
    );

    const noData = useMemo(
      () =>
        (!data || data.length === 0) &&
        !isLoading && (
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <p className="h-auto w-auto bg-white px-4 py-3">
              Keine Lieferungen gefunden für die gewählten Filtereinstellungen.
            </p>
          </div>
        ),
      [data, isLoading],
    );

    const timeSeriesChart = useMemo(
      () => (
        <TimeSeriesChart
          aggregationInterval={aggregationInterval}
          className="rounded-md border border-gray-200 px-4 pt-4 pb-2"
          data={timeSeriesData}
          selectedDateRange={selectedDateRange}
          selectedUnit={selectedUnit}
          isLoading={isFetching}
        />
      ),
      [data, selectedDateRange, selectedUnit, isFetching],
    );

    const detailOverview = useMemo(
      () => (
        <DashboardDetailOverview
          data={data}
          selectedUnit={selectedUnit}
          isLoading={isFetching}
        />
      ),
      [data, selectedUnit, isFetching],
    );

    const doughnutChart = useMemo(
      () =>
        distributionData && (
          <>
            <DoughnutChart
              chartData={distributionData}
              className="h-full w-full"
              selectedUnit={selectedUnit}
            />
            {/* <KPI
              value={articlesGrandTotal}
              label={'Gesamt'}
              unit={UnitUtils.getAbbreviatedUnitString(selectedUnit)}
              className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -space-y-1"
            /> */}
          </>
        ),
      [distributionData],
    );

    if (!selectedUnit) {
      return (
        <div className="flex h-96 w-full flex-col items-center justify-center">
          <div>
            Bitte wähle eine Einheit aus, für die die Diagramme erstellt werden
            sollen.
          </div>
        </div>
      );
    }

    const hasDistributionData =
      distributionData?.datasets?.[0]?.data?.length > 0;

    return (
      <div className="flex flex-col gap-8 pt-4">
        <div className="flex flex-col gap-1">
          <h2 className="text-xl font-medium">Verteilung</h2>
          <div className="flex w-full flex-col gap-4 md:flex-row">
            <div className="w-full md:w-2/3 lg:w-1/2">{detailOverview}</div>
            <div className="relative flex min-h-80 w-full items-center justify-center rounded-md border border-gray-200 p-4 md:w-1/3 lg:w-1/2">
              {isFetching && (
                <div className="absolute inset-0 z-10 flex items-center justify-center">
                  <Spinner />
                </div>
              )}
              {hasDistributionData && doughnutChart}
              {noData}
            </div>
          </div>
        </div>
        <div className="relative flex flex-col gap-1">
          <h2 className="text-xl font-medium">Zeitlicher Verlauf</h2>
          {timeSeriesChart}
          {noData}
        </div>
      </div>
    );
  },
  'Diagramme konnten nicht geladen werden.',
);

DashboardCharts.displayName = 'DashboardCharts';
