import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/logging';

import { queryKeysCustomField } from './queryKeys';
import { type CustomFieldOption } from './useQueryCustomFieldOption';

type CustomFieldOptionsResponse = {
  count: number;
  items: CustomFieldOption[];
};

/**
 * Fetches options for a specific CustomField from the API.
 * @param customFieldId - The ID of the custom field
 * @returns The custom field options data
 * @throws Error if the API request fails
 * @see https://app.dev.vestigas.com/redoc#tag/Custom-Field/operation/create_custom_field_option_custom_field__custom_field_id__option_post
 */
export const fetchCustomFieldOptions = async (
  customFieldId: UUID,
): Promise<CustomFieldOptionsResponse> => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.CUSTOM_FIELD.GET_OPTIONS(customFieldId))
      .json<CustomFieldOptionsResponse>();

    return response;
  } catch (error) {
    Log.error('Error fetching CustomField options', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the options of a specific CustomField.
 *
 * @param customFieldId - The ID of the custom field
 * @param options - Additional options for the useQuery hook
 * @returns The result of the useQuery hook containing the custom field options
 */
export const useQueryCustomFieldOptions = (
  customFieldId: UUID,
  options?: Omit<
    UseQueryOptions<CustomFieldOptionsResponse>,
    'queryKey' | 'queryFn'
  >,
) =>
  useQuery({
    queryFn: async () => fetchCustomFieldOptions(customFieldId),
    queryKey: queryKeysCustomField.getOptions(customFieldId),
    enabled: Boolean(customFieldId),
    ...options,
  });
