import CostCenterService from '~/services/costCenter.service';
import SiteService from '~/services/site.service';
import UserService from '~/services/user.service';

import { uniqueBy } from '~/utils/array';
import { promiseAllThrottled } from '~/utils/promise';
import { promiseHandler } from '~/utils/promiseHandler';

export const initPermittedUsers = async (
  permittedUsers,
  permittedToSites,
  permittedCostCenters,
) => {
  const entityPromises = [
    ...permittedToSites.map(({ id }) => ({
      getEntity: SiteService.getSite,
      getEntityById: SiteService.getSiteById,
      id,
    })),
    ...permittedCostCenters.map(({ id }) => ({
      getEntity: CostCenterService.getCostCenter,
      getEntityById: CostCenterService.getCostCenterById,
      id,
    })),
  ];

  const userPromises = entityPromises.map(({ id, getEntity, getEntityById }) =>
    promiseHandler(
      UserService.getPermittedUsersOfEntity(id, getEntity, getEntityById),
    ),
  );

  const results = await promiseAllThrottled(userPromises);

  const allPermittedUsers = results.reduce((users, [newUsers, error]) => {
    if (error) {
      throw error;
    }

    return [...users, ...newUsers];
  }, []);

  const newPermittedUsers = uniqueBy(
    [...permittedUsers, ...allPermittedUsers],
    ({ id }) => id,
  );

  return newPermittedUsers;
};
