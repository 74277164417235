import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import IdsService from '~/services/ids.service';

import Log from '~/utils/logging';

import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';

export const IdsRedirectPage = withErrorBoundary(() => {
  const [errorMessage, setErrorMessage] = useState('');

  const { action, company_id: companyId, token: accessToken } = useParams();

  const handleRedirect = async () => {
    if (!companyId || !accessToken || !action) {
      setErrorMessage(
        'Ungültige URL: IDs des IDS Shops ("company_id"), access token ("token") und action ("action") werden als query parameter benötigt.',
      );
      return;
    }

    try {
      await IdsService.redirectWithIdsCredentials(
        companyId,
        accessToken,
        action,
      );
    } catch {
      Log.productAnalyticsEvent(
        'Failed to redirect to shop',
        Log.FEATURE.IDS,
        Log.TYPE.ERROR,
      );

      setErrorMessage('Weiterleitung konnte nicht durchgeführt werden.');
    }
  };

  useEffect(() => {
    void handleRedirect();
  }, [accessToken, action, companyId]);

  if (errorMessage) {
    return (
      <div className="flex h-screen items-center justify-center">
        {errorMessage}
      </div>
    );
  }

  return (
    <div className="h-screen">
      <Spinner title="Du wirst weitergeleitet..." />
    </div>
  );
}, 'Weiterleitung konnte nicht durchgeführt werden.');

IdsRedirectPage.displayName = 'IdsRedirectPage';
