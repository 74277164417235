import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import { type UncefactUnitType } from '~/constants/units';

import { vestigasApi } from '~/services/kyClient';

import { type AggregationInterval } from '~/modules/dashboard';

import { queryKeysDeliveryNote } from './queryKeys';
import { type FilterConfig } from './types';

type DeliveryNoteAnalyticsParameters = {
  filterConfig: FilterConfig | undefined;
  timeAggregate: AggregationInterval;
  unitType: UncefactUnitType;
};

export type DeliveryNoteAnalyticsData = {
  article: string;
  dates: string[];
  total: number;
  values: Array<number | undefined>;
};

type DeliveryNoteAnalyticsResponse = {
  values: DeliveryNoteAnalyticsData[];
};

/**
 * Fetches analytics data for delivery notes based on the provided parameters.
 *
 * @param {DeliveryNoteAnalyticsParams} params - Parameters for filtering and configuring analytics
 * @returns {Promise<any>} The analytics data values
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_delivery_note_analytics_analytics_post
 */
export const fetchDeliveryNoteAnalytics = async (
  parameters: DeliveryNoteAnalyticsParameters,
): Promise<DeliveryNoteAnalyticsData[]> => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.DELIVERY_NOTE.GET_ANALYTICS(), {
        json: parameters,
      })
      .json<DeliveryNoteAnalyticsResponse>();

    return response.values;
  } catch (error) {
    console.error('Failed to get delivery note analytics data:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for fetching delivery note analytics data.
 *
 * @param {DeliveryNoteAnalyticsParameters} parameters - Parameters for filtering and configuring analytics
 * @param {Object} options - Additional options for the useQuery hook
 * @returns {UseQueryResult<any>} The result of the useQuery hook containing the analytics data
 */
export const useQueryDeliveryNoteAnalytics = (
  parameters: DeliveryNoteAnalyticsParameters,
  options?: Omit<UseQueryOptions<any>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery<DeliveryNoteAnalyticsData[]>({
    queryFn: async () => fetchDeliveryNoteAnalytics(parameters),
    queryKey: queryKeysDeliveryNote.getAnalytics(parameters),
    ...options,
  });
};
