import { forwardRef } from 'react';

import { cn } from '~/utils/tailwind';

type P = {
  readonly text: string;
} & ComponentStyling;

export const Badge = forwardRef(({ className, style, text }: P, ref) => (
  <div
    ref={ref}
    className={cn(
      'bg-error500 absolute -top-1.5 -right-1 h-4 min-w-4 rounded-lg px-1! text-center text-xs leading-4 font-semibold text-white shadow-sm',
      className,
    )}
    style={style}
  >
    {text}
  </div>
));
