import { createBrowserInspector } from '@statelyai/inspect';
import { createStoreWithProducer } from '@xstate/store';
import { useSelector } from '@xstate/store/react';
import { create } from 'mutative';

import { type FeatureFlag } from '~/constants/featureFlags';

import { type Permission } from '~/types/permission';

import { type User as UserQueryResponse } from '~/data/user';

import { isLocalhost } from '~/utils/environment';

type CurrentUser = Omit<UserQueryResponse, 'totalPermissions'> & {
  featureFlags: Record<FeatureFlag, boolean>;
  hasLoggedInViaSso: boolean;
  name: string;
  permissions: Permission[];
};

export const globalState = createStoreWithProducer(create, {
  context: {
    currentUser: undefined as CurrentUser | undefined,
    pageTitle: '',
  },
  on: {
    setCurrentUser(
      context,
      event: {
        currentUser: UserQueryResponse;
        hasLoggedInViaSso: boolean;
      },
    ) {
      const { companyAccount, firstName, lastName, totalPermissions, ...rest } =
        event.currentUser;

      context.currentUser = {
        ...rest,
        featureFlags: companyAccount?.data?.featureFlags,
        firstName,
        hasLoggedInViaSso: Boolean(event.hasLoggedInViaSso),
        lastName,
        name: [firstName, lastName].filter(Boolean).join(' '),
        permissions: totalPermissions,
      };
    },
    setPageTitle(context, event: { pageTitle: string }) {
      context.pageTitle = event.pageTitle;
    },
  },
});

/**
 * Hook to get all user information of the current user.
 */
export const useUserInfo = () =>
  useSelector(globalState, (state) => state.context.currentUser);

const EMPTY_FEATURE_FLAGS: Record<FeatureFlag, boolean> = {} as Record<
  FeatureFlag,
  boolean
>;

/**
 * Hook to get the feature flags of the current user.
 */
export const useUserFeatureFlags = () =>
  useSelector(
    globalState,
    (state) => state.context.currentUser?.featureFlags ?? EMPTY_FEATURE_FLAGS,
  );

/**
 * Hook to get the permissions of the current user.
 */
export const useUserPermissions = () =>
  useSelector(
    globalState,
    (state) => state.context.currentUser?.permissions ?? [],
  );

const showInspector = isLocalhost && false;

if (showInspector) {
  const { inspect } = createBrowserInspector();

  globalState.inspect(inspect);
}
