import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import { sortByKey } from '~/utils/array';

const companiesSlice = createSlice({
  initialState: {
    companies: [],
    companiesLoading: LOADING_STATE.NOT_LOADED,
    companyLogos: [],
  },
  name: 'companies',
  reducers: {
    removeCompanyLogo(state, parameter) {
      const { payload } = parameter;

      state.companyLogos = state.companyLogos.filter(
        ({ companyId }) => companyId !== payload,
      );
    },
    replaceCompanies(state, parameter) {
      const { payload } = parameter;

      state.companies = sortByKey(payload, 'name');
      state.companiesLoading = LOADING_STATE.SUCCEEDED;
    },
    replaceCompany(state, parameter) {
      const { payload } = parameter;

      const newCompanies = state.companies.filter(
        ({ id }) => id !== payload.id,
      );
      newCompanies.push(payload);

      state.companies = newCompanies;
    },
    saveCompanyLogo(state, parameter) {
      const { payload } = parameter;

      state.companyLogos = [...state.companyLogos, payload];
    },
    setCompaniesLoading(state, parameter) {
      const { payload } = parameter;

      state.companiesLoading = payload;
    },
  },
});

const { actions, reducer } = companiesSlice;
export const {
  removeCompanyLogo,
  replaceCompanies,
  replaceCompany,
  saveCompanyLogo,
  setCompaniesLoading,
} = actions;
export default reducer;
