import UniversalCommunication from '~/models/masterdata/UniversalCommunication';

export const ClientPortalMessage = () => {
  const { email, phone } = UniversalCommunication.getSalesContact();

  return (
    <span>
      Das ist eine Funktion in der VESTIGAS Vollversion. Bei Interesse an einer
      Vorstellung, kontaktiere uns über{' '}
      <span
        className="cursor-pointer font-bold"
        onClick={() =>
          (globalThis.location =
            'mailto:' + email + '?subject=VESTIGAS-Vollversion')
        }
      >
        {email}
      </span>{' '}
      oder{' '}
      <span
        className="cursor-pointer font-bold"
        onClick={() => (globalThis.location = 'tel:' + phone)}
      >
        {phone}
      </span>
      .
    </span>
  );
};
