import { useQueryClient } from '@tanstack/react-query';
import { type ReactNode, useCallback } from 'react';

import { type UUID } from '~/types/common';

import {
  type CustomFieldOption,
  fetchCustomFieldOption,
  fetchCustomFieldOptions,
  queryKeysCustomField,
} from '~/data/customField';

import { type Option, SelectServerDriven } from './SelectServerDriven';
import { createGetItemData, createLoadOptions } from './utils';

const getOptionLabel = ({ displayName }) => displayName;

type P = {
  readonly customFieldId: UUID;
  readonly formatOptionLabel?: (option: Option<CustomFieldOption>) => ReactNode;
  readonly isDisabled?: boolean;
  readonly isMultiSelect?: boolean;
  readonly onChange: (
    value: Array<{ id: string }> | { id: string },
    data: CustomFieldOption[] | CustomFieldOption,
  ) => void;
  readonly placeholder?: string;
  readonly value: string[];
} & ComponentStyling;

/**
 * MultiSelect component for working with options for custom fields with predefined values (type "enumeration").
 */
export const MultiSelectCustomFieldValues = ({
  className,
  customFieldId,
  formatOptionLabel,
  isDisabled = false,
  isMultiSelect = true,
  onChange,
  placeholder = 'Werte auswählen',
  style,
  value,
}: P) => {
  const queryClient = useQueryClient();

  /**
   * Loads the options for the multi-select dropdown.
   * @param {string} searchString - The search string entered by the user.
   * @param {unknown} loadedOptions - The already loaded options.
   * @param {{ page: number }} - The current page number.
   */
  const loadOptions = createLoadOptions<CustomFieldOption>({
    createQueryKey(arguments_) {
      return queryKeysCustomField.getOptions(customFieldId);
    },
    async fetchItems() {
      const response = await fetchCustomFieldOptions(customFieldId);

      return {
        data: response?.items ?? [],
        hasNextPage: false, // custom field options doesn't support pagination
      };
    },
    getOptionLabel,
    queryClient,
  });

  /**
   * Gets custom field option data for a given custom field option with a given customFieldOptionId.
   * Attempts to get it from the query cache and fetches it from the API
   * if the custom field option data is not in the cache.
   * @param {string} customFieldId - The ID of the custom field to get the option for.
   * @param {string} customFieldOptionId - The ID of the custom field option to get.
   */
  const getItemData = createGetItemData<CustomFieldOption>({
    createQueryKey: (customFieldOptionId) =>
      queryKeysCustomField.getOption(customFieldId, customFieldOptionId),
    async fetchItem(customFieldOptionId) {
      return fetchCustomFieldOption(customFieldId, customFieldOptionId);
    },
    queryClient,
    queryKeyBase: [queryKeysCustomField.base()],
  });

  // This is just a glue function b/c PermissionGrantMultiPicker currently expects to pick the ID from an object in its onChange function. Should be refactored.
  const handleChange = useCallback(
    (
      newValues: string[] | string,
      data: CustomFieldOption[] | CustomFieldOption,
    ) => {
      onChange(
        Array.isArray(newValues)
          ? newValues.map((id) => ({ id }))
          : { id: newValues },
        data,
      );
    },
    [onChange],
  );

  return (
    <SelectServerDriven<CustomFieldOption>
      className={className}
      customFieldId={customFieldId}
      formatOptionLabel={formatOptionLabel}
      getItemData={getItemData}
      getOptionLabel={getOptionLabel}
      isDisabled={isDisabled}
      isItemSelectionAllowed={() => true}
      isMultiSelect={isMultiSelect}
      loadOptions={loadOptions}
      placeholder={placeholder}
      style={style}
      value={value}
      onChange={handleChange}
    />
  );
};
