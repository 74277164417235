import { FEATURE_FLAG } from '~/constants/featureFlags';

import store from '~/redux/store';

class FeatureService {
  showUserInDlnHistory() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.USER_IN_DLN_HISTORY
    ];
  }

  sentryGoogleAnalyticsDisabled() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.SENTRY_GOOGLE_ANALYTICS_DISABLED
    ];
  }

  createDeliveryNote() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.CREATE_DELIVERY_NOTE
    ];
  }

  concreteDiary() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.CONCRETE_DIARY
    ];
  }

  createDemoDlns() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.CREATE_DEMO_DLNS
    ];
  }

  clientPortal() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.CLIENT_PORTAL
    ];
  }

  moduleInvoiceRestriction() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.MODULE_INVOICE_RESTRICTION
    ];
  }

  packageBasicRestriction() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.PACKAGE_BASIC_RESTRICTION
    ];
  }

  deactivatedCompanyAccount() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.DEACTIVATED_COMPANY_ACCOUNT
    ];
  }

  productAnalyticsTools() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.PRODUCT_ANALYTICS_TOOLS
    ];
  }

  serviceNotes() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.SERVICE_NOTES
    ];
  }

  accessArchive() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.ACCESS_ARCHIVE
    ];
  }

  supplierKanban() {
    return store?.getState()?.companyAccount?.companyAccount?.featureFlags?.[
      FEATURE_FLAG.NEW_SUPPLIERS_KANBAN
    ];
  }
}

export default new FeatureService();
