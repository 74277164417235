type P = {
  readonly animationDuration: number;
  readonly color?: string;
  readonly height?: string;
  readonly progress: number;
  readonly zIndex?: number;
};

export const Bar = ({
  animationDuration,
  color = '#3f8ebb',
  height = '4px',
  progress,
  zIndex = 1501,
}: P) => {
  return (
    <div
      style={{
        background: color,
        height,
        left: 0,
        marginLeft: `${(-1 + progress) * 100}%`,
        position: 'fixed',
        top: 0,
        transition: `margin-left ${animationDuration}ms linear`,
        width: '100%',
        zIndex,
      }}
    >
      <div
        style={{
          display: 'block',
          height: '100%',
          opacity: 1,
          position: 'absolute',
          right: 0,
          transform: 'rotate(3deg) translate(0, -4px)',
          width: 100,
        }}
      />
    </div>
  );
};
