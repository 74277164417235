import dayjs from 'dayjs';
/* eslint-disable-next-line import/no-unassigned-import */
import 'dayjs/locale/de';
/* eslint-disable-next-line import/no-unassigned-import */
import 'dayjs/locale/en';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Register plugins
dayjs.extend(customParseFormat);
dayjs.extend(localeData);
dayjs.extend(localizedFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(utc);

dayjs.locale('de'); // Globally select German locale by default -> TODO: link this to language switch

// Create a wrapper function to ensure consistent timezone handling
export const createDayjs = (date: string | number | Date | dayjs.Dayjs) => {
  return dayjs(date).tz('Europe/Berlin');
};

export { default as dayjs } from 'dayjs';
