/**
 * @see https://mui.com/x/api/data-grid/data-grid-pro/
 */
import {
  DataGridPro,
  type DataGridProProps,
  type GridApi,
  type GridCallbackDetails,
  type GridCellParams,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridFeatureMode,
  type GridHeaderSelectionCheckboxParams,
  type GridPaginationModel,
  type GridRowParams,
  type GridRowSelectionModel,
  type GridRowsProp,
  type GridSortModel,
  type MuiEvent,
} from '@mui/x-data-grid-pro';
import { memo, type ReactElement, useMemo } from 'react';

import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';
import { ComponentTestIds } from '~/constants/test-ids';

import { noop } from '~/utils/lang';

import {
  DatagridToolbar,
  Footer,
  LoadingState,
  NoResults,
  Pagination,
} from './components';
import { exportCsvClientSide } from './toolbarItems';

export type ToolbarAction = {
  isLoading?: boolean;
  name: string;
  onClick: (apiRef: GridApi) => void;
  selected?: boolean;
};

export type ToolbarItem = {
  id: string;
  icon?:
    | 'csv'
    | 'excel'
    | 'mapDirectDeliveryNote'
    | 'pdf'
    | 'permissionGrant'
    | 'requestSignature'
    | 'shareDeliveryNote'
    | 'signature';
  title?: string;
  actions: ToolbarAction[];
};

type P = {
  readonly apiRef: GridApi;
  readonly checkboxSelection?: DataGridProProps['checkboxSelection'];
  readonly columns: GridColDef[];
  readonly columnVisibilityModel?: GridColumnVisibilityModel;
  readonly disableColumnMenu?: DataGridProProps['disableColumnMenu'];
  readonly disableRowSelectionOnClick?: DataGridProProps['disableRowSelectionOnClick'];
  readonly filterMode?: GridFeatureMode;
  readonly headerSelectionCheckboxProps?: GridHeaderSelectionCheckboxParams;
  readonly isFetching?: boolean;
  readonly isLoading?: boolean;
  readonly onCellClick?: (
    params: GridCellParams,
    event: MuiEvent,
    details: GridCallbackDetails,
  ) => void;
  readonly onPaginationModelChange?: (model: GridPaginationModel) => void;
  readonly onRowClick?: (
    params: GridRowParams,
    event: MuiEvent,
    details: GridCallbackDetails,
  ) => void;
  readonly onRowSelectionModelChange?: (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails,
  ) => void;
  readonly onSortModelChange?: (
    model: GridSortModel,
    details: GridCallbackDetails,
  ) => void;
  readonly pageSizeOptions: number[];
  readonly pagination?: boolean;
  readonly paginationMeta?: { totalRowCount?: number };
  readonly paginationMode?: GridFeatureMode;
  readonly paginationModel: GridPaginationModel;
  readonly paginationText?: string;
  readonly propsPassthrough?: DataGridProProps;
  readonly rows: GridRowsProp;
  readonly selectAllRowsCount?: number;
  readonly slotProps?: Record<string, unknown>;
  readonly slots?: Record<string, ReactElement>;
  readonly sortingMode?: GridFeatureMode;
  readonly sortModel: GridSortModel;
  readonly toolbarItems?: ToolbarItem[];
} & ComponentStyling;

const DEFAULT_PAGINATION_MODEL = {
  page: 0,
  pageSize: PAGINATION_PAGE_SIZE_DEFAULT,
};

const DEFAULT_COLUMNS: GridColDef[] = [];

const DEFAULT_TOOLBAR_ITEMS: ToolbarItem[] = [exportCsvClientSide];

export const DatagridServerDriven = memo(
  ({
    apiRef,
    checkboxSelection,
    className,
    columns = DEFAULT_COLUMNS,
    columnVisibilityModel,
    disableColumnMenu = true,
    disableRowSelectionOnClick,
    filterMode = 'server',
    headerSelectionCheckboxProps,
    isFetching,
    isLoading,
    onCellClick = noop,
    onPaginationModelChange = noop,
    onRowClick = noop,
    onRowSelectionModelChange = noop,
    onSortModelChange = noop,
    pageSizeOptions = [],
    pagination = true,
    paginationMeta,
    paginationMode = 'server',
    paginationModel = DEFAULT_PAGINATION_MODEL,
    paginationText,
    propsPassthrough, // For explicitly passing DataGridPro props directly to the DataGridPro component
    rows = [],
    selectAllRowsCount,
    slotProps,
    slots,
    sortingMode = 'server',
    sortModel,
    style,
    toolbarItems = DEFAULT_TOOLBAR_ITEMS,
  }: P) => {
    const toolbarComponents = useMemo(
      () =>
        toolbarItems?.map(({ actions, icon, title }) => ({
          icon,
          menuItems: actions.map((action) => ({
            ...action,
            onClick() {
              action.onClick(apiRef);
            },
          })),
          title,
        })) ?? [],
      [toolbarItems, apiRef],
    );

    const datagridSlots = useMemo(
      () => ({
        footer: Footer,
        loadingOverlay: LoadingState,
        noResultsOverlay: NoResults,
        pagination: Pagination,
        toolbar: DatagridToolbar,
        ...slots,
      }),
      [slots],
    );

    const datagridSlotProps = useMemo(
      () => ({
        footer: {
          isFetching: isFetching ?? false,
          paginationText,
          selectedRowCount: selectAllRowsCount,
        },
        pagination: {
          'data-testid': ComponentTestIds.TABLE.PAGINATION,
          isFetching: isFetching ?? false,
          paginationText,
        },
        toolbar: {
          components: toolbarComponents,
        },
        ...slotProps,
      }),
      [
        isFetching,
        paginationText,
        selectAllRowsCount,
        slotProps,
        toolbarComponents,
      ],
    );

    const dataGridProps: DataGridProProps = {
      apiRef,
      checkboxSelection,
      className,
      columnHeaderHeight: 64,
      columns,
      columnVisibilityModel,
      density: 'compact',
      disableColumnFilter: true,
      disableColumnMenu,
      disableColumnResize: false,
      disableDensitySelector: true,
      disableRowSelectionOnClick,
      filterMode,
      headerSelectionCheckboxProps,
      loading: isLoading,
      onCellClick,
      onPaginationModelChange,
      onRowClick,
      onRowSelectionModelChange,
      onSortModelChange,
      pageSizeOptions,
      pagination,
      paginationMode,
      paginationModel,
      rowCount: paginationMeta?.totalRowCount ?? 0,
      rows,
      slots: datagridSlots,
      slotProps: datagridSlotProps,
      sortingMode,
      sortModel,
      style,
      ...propsPassthrough,
    };

    return <DataGridPro {...dataGridProps} />;
  },
);
