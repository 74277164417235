import { type PropsWithChildren } from 'react';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import { LightTooltip } from '~/utils/componentUtils';

type P = PropsWithChildren & { readonly status: string };

export const DataExchangeStatusTooltip = ({ children, status }: P) => {
  const tooltipTitle = (
    <div>
      <div className="font-bold">
        {DataExchangeCompany.getStatusLabel(status)}
      </div>
      <div>{DataExchangeCompany.getStatusDescription(status)}</div>
    </div>
  );

  return (
    <LightTooltip title={tooltipTitle} enterDelay={1000} enterNextDelay={1000}>
      {children}
    </LightTooltip>
  );
};
