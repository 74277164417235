import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/logging';

export default class ShippingMark {
  constructor(shippingMark) {
    this.value = shippingMark?.value ?? null;
    this.code = shippingMark?.code ?? null;
  }

  static getShippingMarkString(code) {
    const shippingMark = Object.keys(ShippingMark.CODE).find(
      (x) => ShippingMark.CODE[x].KEY === code,
    );

    if (!shippingMark) {
      Log.error(
        null,
        new EnumValueNotFoundException(
          `Invalid ShippingMark code. code: ${code}`,
        ),
      );
      return null;
    }

    return ShippingMark.CODE[shippingMark].STRING;
  }

  static CODE = {
    COLLI_NUMBER: {
      KEY: 'COL',
      STRING: 'Collinummern',
    },
    NVE_NUMBER: {
      KEY: '39',
      STRING: 'NVE Nummern',
    },
  };
  static FALLBACK_STRING = 'Versandnummern';
}
