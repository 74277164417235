export const CostCenterIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    fill="none"
  >
    <circle cx="10" cy="10" r="9" stroke="currentColor" strokeWidth="2" />
    <path
      d="M10.6667 13.6111C10.0062 13.6117 9.35837 13.4306 8.79397 13.0877C8.22957 12.7447 7.77038 12.2531 7.46667 11.6667H10.6667V10.5556H7.1C7.07222 10.3722 7.05556 10.1889 7.05556 10C7.05556 9.81111 7.07222 9.62778 7.1 9.44445H10.6667V8.33333H7.46667C7.77092 7.74733 8.23023 7.2561 8.79451 6.91322C9.35879 6.57034 10.0064 6.38897 10.6667 6.38889C11.5611 6.38889 12.3833 6.71667 13.0167 7.26111L14 6.27778C13.0852 5.45415 11.8976 4.99889 10.6667 5C8.48889 5 6.64444 6.39445 5.95556 8.33333H4V9.44445H5.7C5.65501 9.81345 5.65501 10.1866 5.7 10.5556H4V11.6667H5.95556C6.64444 13.6056 8.48889 15 10.6667 15C11.95 15 13.1167 14.5167 14 13.7222L13.0111 12.7389C12.3833 13.2833 11.5667 13.6111 10.6667 13.6111Z"
      fill="currentColor"
    />
  </svg>
);
