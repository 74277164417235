import { difference } from '~/utils/array';

import PermissionGrant from './PermissionGrant';

export default class CostCenter {
  constructor(costCenter, additionalDataInitiated) {
    this.id = costCenter?.id ?? null;
    this.name = costCenter?.name ?? '';
    this.company = costCenter?.company_id ?? costCenter?.companyId ?? ''; // FIXME: VGS-6881: we have a duplication of names here: companyId and company; both are a UUID; we should only have companyId
    this.companyId = costCenter?.company_id ?? costCenter?.companyId ?? '';
    this.active = costCenter?.is_active ?? costCenter?.isActive ?? true; // FIXME: it is so bad that this is renamed to a much worse name!
    this.isActive = costCenter?.is_active ?? costCenter?.isActive ?? true;
    this.start = costCenter?.start || null;
    this.end = costCenter?.end || null;

    // CRUD timestamps
    this.createdOn = costCenter?.created_on ?? '';
    this.modifiedOn = costCenter?.modified_on ?? '';

    this.organisationalGroups =
      costCenter?.org_units ?? costCenter?.orgUnits ?? []; // deprecated - use parentOrganizationalUnits instead
    this.parentOrganizationalUnits =
      costCenter?.parent_organizational_units ??
      costCenter?.parentOrganizationalUnits ??
      [];
    this.organisationalGroupPaths =
      costCenter?.org_unit_paths ?? costCenter?.orgUnitPaths ?? []; // deprecated - use parentOrganizationalUnitPaths instead
    this.parentOrganizationalUnitPaths =
      costCenter?.parentOrganizationalUnitPaths ?? [];

    this.permissionGrantsFrom =
      (costCenter?.permissions_from ?? costCenter?.permissionsFrom)?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.COST_CENTER.KEY,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getDifferentValues(costCenterA, costCenterB) {
    const differentValues = [];

    if (costCenterA?.id !== costCenterB?.id) {
      differentValues.push('ID');
    }

    if (costCenterA?.name !== costCenterB?.name) {
      differentValues.push('Name');
    }

    if (costCenterA?.active !== costCenterB?.active) {
      differentValues.push('Aktiv');
    }

    if (costCenterA?.company !== costCenterB?.company) {
      differentValues.push('Firma');
    }

    const differentOrganisationalGroups = difference(
      costCenterA?.organisationalGroups,
      costCenterB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }
}
