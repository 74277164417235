import { type DeliveryNoteAnalyticsData } from '~/data/deliveryNote';

import { getOptimallyDistinctColor } from '~/utils/color';
import UnitUtils from '~/utils/unitUtils';

import {
  MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED,
  MAXIMUM_NUMBER_OF_LEGEND_ITEMS,
} from '../constants';

type ChartDataset = {
  backgroundColor: string[];
  data: number[];
};

type LegendItem = {
  amount: string;
  color: string;
  label: string;
};

type PieChartData = {
  datasets: ChartDataset[];
  labels: string[];
  legendData: LegendItem[];
};

/**
 * Generates data for a pie chart visualization from delivery note analytics data, formatted for Chart.js.
 *
 * @param analyticsData - Array of delivery note analytics data containing article names and totals
 * @returns Formatted data structure for rendering a pie chart, including:
 *          - datasets: Array containing chart data and background colors
 *          - labels: Array of article names
 *          - legendData: Array of formatted legend items (limited to MAXIMUM_NUMBER_OF_LEGEND_ITEMS)
 *
 * The function:
 * - Sorts data by total in descending order
 * - Limits displayed articles to MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED
 * - Generates distinct colors for each segment
 * - Formats totals using German number formatting
 */
export const generatePieChartData = (
  analyticsData: DeliveryNoteAnalyticsData[],
): PieChartData => {
  if (!analyticsData?.length) {
    return {
      datasets: [],
      labels: [],
      legendData: [],
    };
  }

  const renderedData = analyticsData
    .sort((a, b) => b.total - a.total)
    .slice(0, MAXIMUM_NUMBER_OF_ARTICLES_DISPLAYED);

  const labels: string[] = [];
  const chartData: number[] = [];
  const backgroundColor: string[] = [];
  const legendData: LegendItem[] = [];

  for (const [index, { article, total }] of renderedData.entries()) {
    const color = getOptimallyDistinctColor(index, renderedData.length);

    labels.push(article);
    chartData.push(total);
    backgroundColor.push(color);

    if (index < MAXIMUM_NUMBER_OF_LEGEND_ITEMS) {
      legendData.push({
        amount: UnitUtils.roundAndFormatDe_safe(total) ?? '',
        color,
        label: article,
      });
    }
  }

  return {
    datasets: [
      {
        backgroundColor,
        data: chartData,
      },
    ],
    labels,
    legendData,
  };
};
