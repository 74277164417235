import snakecaseKeys from 'snakecase-keys';

import { apiUrl } from '~/constants/environment';
import { LOADING_STATE } from '~/constants/LoadingState';

import { replaceSite, replaceSites, setSitesLoading } from '~/redux/sitesSlice';
import store from '~/redux/store';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import Site from '~/models/masterdata/Site';

import axios from '~/utils/api-client';
import { es6ClassFactory as ES6ClassFactory } from '~/utils/ES6ClassFactory';
import Log from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';

import CacheService from './cache.service';

const API_URL = apiUrl + '/site';

class SiteService {
  constructor() {
    this.sitesLoading = LOADING_STATE.NOT_LOADED;
    this.sites = [];
    this.sitesBulk = [];
  }

  async getAllSites() {
    return axios.get(API_URL + '/all').then((response) => {
      if (response.status !== 200) {
        return [];
      }

      return response.data.items.map((item) => new Site(item));
    });
  }

  // search for site in store. if not found, load all sites from backend
  // return empty site if no site could be found
  getSiteById = async (siteId) => {
    let site = store
      .getState()
      .sites?.sites?.find((site) => site.id === siteId);
    // As a backup, the sites are also loaded from the local cache in the SiteService. This is done due to the following reason:
    // During bulk load of dlns it happened that hundreds of dlns were loaded before the sites were dispatched to the redux store via store.dispatch(setSitesLoading(LOADING_STATE.FAILED));
    // This can't be prevented because dispatching to the redux store is not awaited by JS Promise await. It is kind of truly asynchronous.
    // As a result, the GET /sites/all endpoint was called hundreds of times before.
    // The solution is to store the sites in the local cache here.
    // However, to prevent incorrect data due to redundancy with the sites from the redux store, the site is always loaded from the redux store if data has already been loaded there.
    if (
      !site &&
      store.getState().sites?.sitesLoading === LOADING_STATE.NOT_LOADED
    ) {
      site = this.sites.find((site) => site.id === siteId);
    }

    if (site) {
      return ES6ClassFactory.convertToES6Class([site], new Site())[0];
    }

    if (
      store.getState().sites?.sitesLoading === LOADING_STATE.SUCCEEDED ||
      store.getState().sites?.sitesLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    if (
      this.sitesLoading === LOADING_STATE.SUCCEEDED ||
      this.sitesLoading === LOADING_STATE.FAILED
    ) {
      return null;
    }

    store.dispatch(setSitesLoading(LOADING_STATE.LOADING));
    this.sitesLoading = LOADING_STATE.LOADING;

    const [sites, error] = await promiseHandler(this.getAllSites());

    if (error) {
      store.dispatch(setSitesLoading(LOADING_STATE.FAILED));
      this.sitesLoading = LOADING_STATE.FAILED;
      throw error;
    }

    store.dispatch(replaceSites(sites));
    this.sitesLoading = LOADING_STATE.SUCCEEDED;
    this.sites = sites;

    return sites.find((site) => site.id === siteId) ?? null;
  };
  getSite = async (siteId, ignoreCache) => {
    const url = API_URL + '/' + siteId;

    if (!ignoreCache) {
      const [cachedValue, error] = CacheService.getCached(url);
      if (cachedValue) {
        return cachedValue;
      }

      if (error) {
        throw error;
      }
    }

    return axios
      .get(url)
      .then((response) => {
        if (response?.status !== 200) {
          Log.warn('GET /site did not return 200', {
            status: response?.status,
          });
        }

        const site = new Site(response?.data, true);
        CacheService.setCached(url, site);
        return site;
      })
      .catch((error) => {
        CacheService.setError(url, error);
        throw error;
      });
  };

  async createSite(body) {
    const snakeCaseBody = snakecaseKeys(body, { deep: true });

    return axios.post(API_URL, snakeCaseBody).then(({ data }) => data?.id);
  }

  async createSitesViaCSV(file) {
    const formData = new FormData();
    formData.append('data', file);

    return axios
      .post(API_URL + '/import_csv', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data.ids;
      });
  }

  async createSitesAndCostCentersViaCSV(file) {
    const formData = new FormData();
    formData.append('cs_cc_data', file);

    return axios
      .post(`${apiUrl}/admin/site_cc_csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        return response.data.ids;
      });
  }

  async updateSite(id, body) {
    return axios.put(API_URL + '/' + id, body);
  }

  /**
   * Adds a new site identifier to a given site based on a given site identifier proposal.
   * Allows to add a blacklisted site identifier.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/add_site_identifier_proposal_to_site_site__site_uuid__identifier_proposal_post
   *
   * @param {string} site_uuid - The UUID of the site.
   * @param {string} proposal_uuid - The UUID of the site identifier proposal.
   * @param {boolean} [update_dlns=false] - Whether to update the related delivery notes.
   * @param {boolean} [blacklisted=false] - Whether the site identifier is blacklisted for the site.
   * @return {Promise<AxiosResponse>} The Axios response of the POST request.
   */
  async addSiteIdentifierFromProposal(
    site_uuid,
    proposal_uuid,
    update_dlns = false,
    blacklisted = false,
  ) {
    return axios.post(
      API_URL + '/' + site_uuid + '/identifier/proposal',
      {
        proposal_uuid,
      },
      {
        params: {
          blacklisted,
          update_dlns,
        },
      },
    );
  }

  /**
   * Create a site identifier for a given site based on the provided data for the identifier.
   * Allows a site identifier being blacklisted with sites attached.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/create_site_identifier_site_identifier_post
   *
   * @param {string} issuerId - The ID of the issuer.
   * @param {string} identifier - The identifier to be created.
   * @param {string} siteId - The ID of the site.
   * @param {boolean} ignoreAddressInfo - Whether to ignore the address information.
   * @param {object} address - The address object.
   * @param {boolean} [shouldBlacklist=false] - Whether the site identifier is blacklisted.
   * @return {string} The ID of the newly created site identifier.
   */
  async postSiteIdentifier(
    issuerId,
    identifier,
    siteId,
    ignoreAddressInfo,
    address,
    shouldBlacklist = false,
  ) {
    return axios
      .post(API_URL + '/identifier', {
        ...(address ? { address } : {}),
        blacklisted: shouldBlacklist,
        identifier,
        ignore_address_info: ignoreAddressInfo,
        site_id: siteId,
        supplier_id: issuerId,
      })
      .then((response) => response.data.id);
  }

  /**
   * Add an identifier for a supplier site.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/add_identifier_for_supplier_site__site_uuid__identifier_post
   */
  async addIdentifierForSupplierSite({
    address,
    ignoreAddressInfo = false,
    issuerId,
    shouldBlacklist = true,
    siteId,
    tradeAddressIssuerAssignedId,
    tradeAddressLineOne,
  }) {
    const body = snakecaseKeys(
      {
        blacklisted: shouldBlacklist,
        identifier: tradeAddressLineOne,
        identifying_id: tradeAddressIssuerAssignedId,
        ignore_address_info: ignoreAddressInfo,
        supplier_id: issuerId,
        ...(address ? { address } : {}),
      },
      { deep: true },
    );

    return axios
      .post(`${API_URL}/${siteId}/identifier`, body)
      .then((response) => response);
  }

  /**
   * Blacklists / un-blacklists a given site identifier for a given site.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/blacklist_site_identifier_site__site_uuid___identifier_uuid__put
   *
   * @param {string} siteId - The ID of the site.
   * @param {string} identifierId - The ID of the site identifier.
   * @param {boolean} [shouldBlacklist=false] - Whether to blacklist the site identifier.
   * @return {Promise<AxiosResponse>} The Axios response of the PUT request.
   */
  async blacklistSiteIdentifier(siteId, identifierId, shouldBlacklist = false) {
    return axios.put(
      `${API_URL}/${siteId}/${identifierId}`,
      {},
      {
        params: {
          blacklisted: shouldBlacklist,
        },
      },
    );
  }

  async deleteSiteReference(site_uuid, identifier_uuid) {
    return axios.delete(
      API_URL + '/' + site_uuid + '/identifier/' + identifier_uuid,
    );
  }

  /**
   * Counts the number of affected delivery notes for a given site identifier and supplier.
   *
   * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/count_unaccepted_dlns_site_identifier_new_site_identifier_affected_dlns_count_get
   *
   * @param {string} identifier - The identifier of the site.
   * @param {string} supplierId - The ID of the supplier.
   * @returns {Promise<AxiosResponse>} The Axios response containing the count of affected delivery notes.
   */
  async countAffectedDlnsOfSiteIdentifier(identifier, supplierId) {
    const params = {
      identifier,
    };

    return axios.get(
      `${API_URL}/identifier/${supplierId}/affected_dlns/count`,
      { params },
    );
  }

  async updateAccountingReferencesOfSite(site_id, accounting_reference_ids) {
    return axios.post(API_URL + '/' + site_id + '/accounting_reference', {
      ids: accounting_reference_ids,
    });
  }

  loadSites = async () => {
    // to not load sites again when they are already loading or have already been loaded
    if (store.getState().sites?.sitesLoading !== LOADING_STATE.NOT_LOADED) {
      return;
    }

    this.refreshSites();
  };
  refreshSites = async () => {
    store.dispatch(setSitesLoading(LOADING_STATE.LOADING));

    const [sites, error] = await promiseHandler(this.getAllSites());

    if (error) {
      store.dispatch(setSitesLoading(LOADING_STATE.FAILED));
      Log.error('Failed to load sites.', error);
      Log.productAnalyticsEvent(
        'Failed to load sites',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceSites(sites));
  };
  refreshSite = async (siteId) => {
    const [site, error] = await promiseHandler(this.getSite(siteId, true));

    if (error) {
      Log.error('Failed to load site.', error);
      Log.productAnalyticsEvent(
        'Failed to load site',
        Log.FEATURE.SITE,
        Log.TYPE.ERROR,
      );
      return;
    }

    store.dispatch(replaceSite(site));
  };

  getUnassignedDeliveriesFilters() {
    return {
      items: [
        {
          field: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.KEY,
          id: 1,
          operator: 'isEmpty',
          value: '',
        },
        {
          field: DeliveryNote.PROPERTY.PERMITTED_TO_SITES.KEY,
          id: 2,
          operator: 'isEmpty',
          value: '',
        },
        {
          field: DeliveryNote.PROPERTY.PERMITTED_COST_CENTERS.KEY,
          id: 3,
          operator: 'isEmpty',
          value: '',
        },
      ],
      logicOperator: 'and',
    };
  }

  loadSitesBulk = async (ids) => {
    return axios.post(API_URL + '/query/bulk', { ids }).then((response) => {
      this.sitesBulk = response.data.items.map((item) => new Site(item));
    });
  };
  getSiteFromSitesBulk = (siteId) => {
    return this.sitesBulk.find((site) => site.id === siteId) ?? null;
  };
}

export default new SiteService();
