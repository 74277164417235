import { Check as CheckIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import {
  memo,
  type MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { type UncefactUnitType } from '~/constants/units';

import Log from '~/utils/logging';

import { selectableUnits } from '../constants';

import { UnitChip } from './UnitChip';

const PAGINATION_SIZE = 5;

type Unit = {
  label: string;
  value: UncefactUnitType;
};

type P = {
  readonly onUnitChange: (unit: UncefactUnitType | undefined) => void;
  readonly selectedUnit: UncefactUnitType | undefined;
};

export const DashboardFilterUnitChips = memo(
  ({ onUnitChange, selectedUnit }: P) => {
    const [displayedUnits, setDisplayedUnits] = useState<Unit[]>([]);

    const displayedUnitsList = useMemo(() => {
      const selectedUnitIndex = selectableUnits?.findIndex(
        ({ value }) => value === selectedUnit,
      );

      if (selectedUnit !== undefined && selectedUnitIndex === -1) {
        Log.error(
          'Failed to find selected unit in list of selectable units for pagination.',
          selectedUnit,
        );
      }

      return selectableUnits.slice(
        0,
        Math.max(PAGINATION_SIZE, Number(selectedUnitIndex) + 1),
      );
    }, [selectedUnit]);

    // Update state only if the calculation changed
    useEffect(() => {
      setDisplayedUnits(displayedUnitsList);
    }, [displayedUnitsList]);

    const handlePaginationClick = useCallback(
      (event: MouseEvent<HTMLDivElement>) => {
        event.currentTarget.blur();

        Log.info('Load the next paginated set of selectable units.', {
          cursor: displayedUnits.length,
        });
        Log.productAnalyticsEvent(
          'Load paginated selectable units',
          Log.FEATURE.DASHBOARD,
        );

        setDisplayedUnits(
          selectableUnits.slice(0, displayedUnits.length + PAGINATION_SIZE),
        );
      },
      [displayedUnits.length],
    );

    const handleUnitChange = useCallback(
      (unit: UncefactUnitType | undefined) => {
        onUnitChange(unit);
      },
      [onUnitChange],
    );

    return (
      <div className="flex flex-wrap items-center gap-2">
        <Chip
          label="Alle Einheiten"
          icon={selectedUnit ? undefined : <CheckIcon className="text-white" />}
          className={
            selectedUnit
              ? 'cursor-pointer'
              : 'bg-primary500 cursor-auto text-white'
          }
          onMouseDown={() => {
            handleUnitChange(undefined);
          }}
        />
        {displayedUnits.map(({ label, value }) => (
          <UnitChip
            key={value}
            isSelected={value === selectedUnit}
            label={label}
            value={value}
            onUnitChange={handleUnitChange}
          />
        ))}
        {displayedUnits.length < selectableUnits.length && (
          <Chip
            key="more"
            label={`+${
              selectableUnits.length - displayedUnits.length
            } weitere Einheiten`}
            className="cursor-pointer"
            onMouseDown={handlePaginationClick}
          />
        )}
      </div>
    );
  },
);

DashboardFilterUnitChips.displayName = 'DashboardFilterUnitChips';
