import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/logging';

import { queryKeysUser } from './queryKeys';

/**
 * Fetches user profile picture from the API.
 * @returns The profile picture as a Blob.
 * @throws Error if the API request fails.
 */
export const fetchUserProfilePicture = async (): Promise<Blob> => {
  try {
    return await vestigasApi.get(ENDPOINT.USER.GET_PROFILE_PICTURE()).blob();
  } catch (error) {
    Log.error('Error fetching user profile picture', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the profile picture of the current user.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useQueryUserProfilePicture = (
  options: Omit<UseQueryOptions<Blob>, 'queryKey' | 'queryFn'> = {},
) =>
  useQuery<Blob>({
    gcTime: ms('60m'),
    queryFn: fetchUserProfilePicture,
    queryKey: queryKeysUser.getProfilePicture(),
    select: (pictureData) =>
      pictureData.size > 0 ? URL.createObjectURL(pictureData) : null,
    staleTime: Infinity, // Don't refetch a cached file
    ...options,
  });
