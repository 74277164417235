import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

const vehiclesSlice = createSlice({
  initialState: {
    vehicles: [],
    vehiclesLoading: LOADING_STATE.NOT_LOADED,
  },
  name: 'vehicles',
  reducers: {
    replaceVehicle(state, { payload }) {
      const newVehicles = state.vehicles.filter(({ id }) => id !== payload.id);

      newVehicles.push(payload);

      state.vehicles = newVehicles;
    },
    replaceVehicles(state, { payload }) {
      const sortByNestedKey = (array, nestedKey, desc) => {
        try {
          return [...array].sort((a, b) => {
            const x = nestedKey.reduce((object, key) => object[key], a);
            const y = nestedKey.reduce((object, key) => object[key], b);
            if (desc) {
              return x > y ? -1 : x < y ? 1 : 0;
            }

            return x < y ? -1 : x > y ? 1 : 0;
          });
        } catch {
          return array;
        }
      };

      state.vehicles = sortByNestedKey(payload, ['licensePlate', 'name']);
      state.vehiclesLoading = LOADING_STATE.SUCCEEDED;
    },
    setVehiclesLoading(state, { payload }) {
      state.vehiclesLoading = payload;
    },
  },
});

const { actions, reducer } = vehiclesSlice;
export const { replaceVehicle, replaceVehicles, setVehiclesLoading } = actions;
export default reducer;
