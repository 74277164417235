import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';
import { PAGINATION_PAGE_SIZE_DEFAULT } from '~/constants/pagination';

import { vestigasApi } from '~/services/kyClient';

import AcceptStateCalculator from '~/models/acceptState/AcceptStateCalculator';
import DeliveryNote from '~/models/deliveries/DeliveryNote';

import { toSnakeCase } from '~/utils/string';

import { queryKeysDeliveryNote } from './queryKeys';
import { type FilterConfig } from './types';

type FilterEntity =
  | 'article_name'
  | 'article_number'
  | 'authorized_accounting_reference_id'
  | 'authorized_entity_type'
  | 'authorized_site_id'
  | 'billed_state'
  | 'buyer_id'
  | 'buyer_name'
  | 'confirmed_accounting_reference_id'
  | 'confirmed_accounting_reference_name'
  | 'confirmed_site_id'
  | 'confirmed_site_name'
  | 'custom_field_global_level'
  | 'custom_field_item_level'
  | 'dln_accept_state'
  | 'dln_created_on'
  | 'dln_date'
  | 'dln_modified_on'
  | 'dln_nr'
  | 'issuer_id'
  | 'issuer_name'
  | 'license_plate_number'
  | 'loading_location'
  | 'ou_id'
  | 'ou_name'
  | 'process_state'
  | 'recipient_id'
  | 'recipient_name'
  | 'search'
  | 'seller_id'
  | 'seller_name'
  | 'supplier_assigned_site_name'
  | 'supplier_id'
  | 'supplier_name';

type FilterSuggestionsParams = {
  entity: FilterEntity;
  filterConfig?: FilterConfig;
  limit?: number;
  offset?: number;
  searchValue?: boolean | number | string | undefined;
};

type FilterSuggestionsResponse = {
  items: string[];
};

const defaultQueryParams: FilterSuggestionsParams = {
  entity: undefined,
  filterConfig: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  searchValue: undefined,
};

/**
 * Fetches possible values for a delivery notes filter from the API.
 * Handles pagination and error cases while enforcing API limits.
 *
 * @param queryParams - Parameters for the filter suggestions query
 * @param queryParams.entity - The entity type to get suggestions for
 * @param queryParams.filterConfig - Optional filter configuration
 * @param queryParams.limit - Maximum number of suggestions to return (max 200)
 * @param queryParams.offset - Pagination offset
 * @param queryParams.searchValue - Optional search term to filter suggestions
 * @returns Promise containing array of suggestion strings
 * @throws Error if API request fails
 *
 * @see https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_dln_analytics_suggestions_analytics_suggestions_post
 */
export const fetchFilterSuggestions = async (
  queryParams: FilterSuggestionsParams,
): Promise<string[]> => {
  try {
    const qp = {
      ...defaultQueryParams,
      ...queryParams,
    };

    qp.entity = toSnakeCase(qp.entity);
    qp.limit = Math.min(qp.limit, 200); // API limit

    for (const key of Object.keys(qp)) {
      if (qp[key] === undefined) {
        delete qp[key];
      }
    }

    for (const filterGroup of qp.filterConfig.filterGroups) {
      // Remove the filter for the entity we're querying suggestions for,
      // because with a filter present, it would return 0 suggestions.
      filterGroup.filterClauses = filterGroup.filterClauses.filter(
        ({ name }) => name !== qp.entity,
      );
    }

    switch (
      toSnakeCase(qp.entity) as FilterEntity | 'accept_state' | 'settled_status'
    ) {
      case 'process_state': {
        // Don't query the API - this is only a short list of predefined options.
        return DeliveryNote.getProcessStateOptions();
      }

      case 'accept_state':
      case 'dln_accept_state': {
        // Don't query the API - this is only a short list of predefined options.
        return AcceptStateCalculator.getAcceptStates();
      }

      case 'settled_status': {
        // Don't query the API - this is only a short list of predefined options.
        return ['Ja', 'Nein'];
      }

      default: {
        const response = await vestigasApi
          .post(ENDPOINT.DELIVERY_NOTE.GET_FILTER_SUGGESTIONS(), {
            json: qp,
          })
          .json<FilterSuggestionsResponse>();

        return response.items;
      }
    }
  } catch (error) {
    console.error('Error fetching delivery note filter suggestions:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for fetching possible values for a delivery notes filter.
 *
 * @param queryParams - Parameters for the filter suggestions query
 * @param options - Additional options for the useQuery hook
 * @returns UseQueryResult containing an array of suggestion strings
 */
export const useQueryDeliveryNoteFilterSuggestions = (
  queryParams: FilterSuggestionsParams,
  options?: Omit<UseQueryOptions<string[]>, 'queryKey' | 'queryFn'>,
) => {
  return useQuery({
    async queryFn() {
      switch (toSnakeCase(queryParams.entity) as FilterEntity) {
        case 'process_state': {
          // Don't query the API - this is only a short list of predefined options.
          return DeliveryNote.getProcessStateOptions();
        }

        case 'dln_accept_state': {
          // Don't query the API - this is only a short list of predefined options.
          return AcceptStateCalculator.getAcceptStates();
        }

        default: {
          return fetchFilterSuggestions(queryParams);
        }
      }
    },
    queryKey: queryKeysDeliveryNote.getFilterSuggestions(queryParams),
    ...options,
  });
};
