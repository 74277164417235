import InvoiceCheckCategory from '~/models/invoices/InvoiceCheckCategory';
import InvoiceCheckResult from '~/models/invoices/InvoiceCheckResult';

import { unique } from '~/utils/array';
import { pluralizeString, pluralizeWord } from '~/utils/pluralize';

import { withErrorBoundary } from '~/ui/atoms';

import { InvoiceCheckListTooltip } from '../invoiceCheckTooltips/InvoiceCheckListTooltip';
import { InvoiceCheckTableTooltip } from '../invoiceCheckTooltips/InvoiceCheckTableTooltip';

export const InvoiceCheckSummaryText = withErrorBoundary(
  ({ category, withTooltip }) => {
    if (!category) {
      return null;
    }

    const getText = () => {
      switch (category.key) {
        case InvoiceCheckCategory.CATEGORIES.FORMAL_CHECK.KEY: {
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle formale Prüfung erforderlich.';
          }

          if (withTooltip) {
            return getFormalCheckTextTooltip();
          }

          return getFormalCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.DLN_CHECK.KEY: {
          // In case of the dln check, the descriptive error text should be displayed if the invoice status is "no checking possible"
          // because the dln check includes the explanation why further checking wasn't possible.
          if (category.status === InvoiceCheckResult.STATUS.MANUAL) {
            return 'Manuelle Prüfung erforderlich, ob Lieferungen vorliegen.';
          }

          if (withTooltip) {
            return getDlnCheckTextTooltip();
          }

          return getDlnCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.SIGNATURE_CHECK.KEY: {
          // In case of the other checks, "Manuelle Prüfung erforderlich" should be displayed in case of the status "no checking possible".
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle Prüfung erforderlich, ob Lieferungen signiert wurden.';
          }

          if (withTooltip) {
            return getSignatureCheckTextTooltip();
          }

          return getSignatureCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.ARTICLE_EXISTS_CHECK.KEY: {
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle Prüfung erforderlich, ob Artikel auf Lieferungen vorhanden sind.';
          }

          if (withTooltip) {
            return getArticleExistsCheckTextTooltip();
          }

          return getArticleExistsCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.AMOUNT_CHECK.KEY: {
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle Prüfung erforderlich, ob Mengen mit Lieferungen übereinstimmen.';
          }

          if (withTooltip) {
            return getAmountCheckTextTooltip();
          }

          return getAmountCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.AMOUNT_APPROVED_CHECK.KEY: {
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle Prüfung erforderlich, ob Reklamationen vorliegen.';
          }

          if (withTooltip) {
            return getAmountApprovedCheckTextTooltip();
          }

          return getAmountApprovedCheckText();
        }

        case InvoiceCheckCategory.CATEGORIES.PRICE_CHECK.KEY: {
          if (
            category.status === InvoiceCheckResult.STATUS.MANUAL ||
            category.status === InvoiceCheckResult.STATUS.NO_CHECKING_POSSIBLE
          ) {
            return 'Manuelle Preisprüfung erforderlich.';
          }

          return null;
        }
      }
    };

    const getFormalCheckText = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return 'Die Formale Rechnungsprüfung wurde erfolgreich bestanden.';
      }

      return 'Die formale Rechnungsprüfung ergab Fehler.';
    };

    const getFormalCheckTextTooltip = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getFormalCheckText();
      }

      return (
        <>
          Die formale Rechnungsprüfung ergab{' '}
          <span className="font-bold text-red-500">Fehler.</span>
        </>
      );
    };

    const getDlnCheckText = () => {
      const deliveryNoteExistsSuccessCount = category.successChecks.filter(
        (successCheck) => successCheck.name === 'DeliveryNoteExists',
      ).length;
      const deliveryNoteExistsErrorCount = category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteExists',
      ).length;
      const deliveryNoteNotAlreadySettledErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteNotAlreadySettled',
        ).length;
      const deliveryNoteItemAlreadyBilledErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteItemAlreadyBilled',
        ).length;
      const deliveryNoteReferencedExistsErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteReferenceExists',
        ).length;

      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return (
          pluralizeString(
            deliveryNoteExistsSuccessCount,
            'Lieferung liegt',
            true,
          ) +
          ' vor und ' +
          pluralizeWord(deliveryNoteExistsSuccessCount, 'wurde') +
          ' noch nicht abgerechnet.'
        );
      }

      const errorText = [];

      if (deliveryNoteExistsErrorCount > 0) {
        errorText.push(
          pluralizeString(
            deliveryNoteExistsErrorCount,
            'Lieferung liegt',
            true,
          ) + ' nicht vor.',
        );
      }

      if (deliveryNoteNotAlreadySettledErrorCount > 0) {
        errorText.push(
          pluralizeString(
            deliveryNoteNotAlreadySettledErrorCount,
            'Lieferung wurde',
            true,
          ) + ' bereits abgerechnet.',
        );
      }

      if (deliveryNoteItemAlreadyBilledErrorCount > 0) {
        errorText.push(
          pluralizeString(
            deliveryNoteItemAlreadyBilledErrorCount,
            'Artikel wurde',
            true,
          ) + ' bereits abgerechnet.',
        );
      }

      if (deliveryNoteReferencedExistsErrorCount > 0) {
        errorText.push(
          pluralizeString(
            deliveryNoteReferencedExistsErrorCount,
            'Artikel enthält',
            true,
          ) + ' keine Referenz auf eine Lieferung.',
        );
      }

      return errorText.join(' ');
    };

    const getDlnCheckTextTooltip = () => {
      // In case of the invoice status being "no checking possible", the text should be highlighted in grey instead of red.
      const textClasses =
        'font-bold ' + InvoiceCheckResult.getStatusColorClass(category.status);

      const deliveryNoteExistsErrorCount = category.errorChecks.filter(
        (errorCheck) => errorCheck.name === 'DeliveryNoteExists',
      ).length;
      const deliveryNoteNotAlreadySettledErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteNotAlreadySettled',
        ).length;
      const deliveryNoteItemAlreadyBilledErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteItemAlreadyBilled',
        ).length;
      const deliveryNoteReferencedExistsErrorCount =
        category.errorChecks.filter(
          (errorCheck) => errorCheck.name === 'DeliveryNoteReferenceExists',
        ).length;

      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getDlnCheckText();
      }

      const errorText = [];

      if (deliveryNoteExistsErrorCount > 0) {
        errorText.push(
          <>
            <InvoiceCheckListTooltip
              title="Nicht vorliegende Lieferungen"
              items={category.errorChecks.map(
                (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
              )}
            >
              <span className={textClasses}>
                {pluralizeWord(deliveryNoteExistsErrorCount, 'Lieferung', true)}
              </span>
            </InvoiceCheckListTooltip>
            {' ' +
              pluralizeWord(deliveryNoteExistsErrorCount, 'liegt') +
              ' nicht vor.'}
          </>,
        );
      }

      if (deliveryNoteNotAlreadySettledErrorCount > 0) {
        errorText.push(
          <>
            <InvoiceCheckListTooltip
              title="Bereits abgerechnete Lieferungen"
              items={category.errorChecks.map(
                (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
              )}
            >
              <span className={textClasses}>
                {pluralizeWord(
                  deliveryNoteNotAlreadySettledErrorCount,
                  'Lieferung',
                  true,
                )}
              </span>
            </InvoiceCheckListTooltip>
            {' ' +
              pluralizeWord(deliveryNoteNotAlreadySettledErrorCount, 'wurde') +
              ' bereits abgerechnet.'}
          </>,
        );
      }

      if (deliveryNoteItemAlreadyBilledErrorCount > 0) {
        errorText.push(
          <>
            <InvoiceCheckListTooltip
              title="Bereits abgerechnete Artikel"
              items={category.errorChecks.map(
                (errorCheck) => errorCheck.articleName ?? '',
              )}
            >
              <span className={textClasses}>
                {pluralizeWord(
                  deliveryNoteItemAlreadyBilledErrorCount,
                  'Artikel',
                  true,
                )}
              </span>
            </InvoiceCheckListTooltip>
            {' ' +
              pluralizeWord(deliveryNoteItemAlreadyBilledErrorCount, 'wurde') +
              ' bereits abgerechnet.'}
          </>,
        );
      }

      if (deliveryNoteReferencedExistsErrorCount > 0) {
        errorText.push(
          <>
            <InvoiceCheckListTooltip
              title="Artikel ohne Referenz auf eine Lieferung"
              items={category.errorChecks.map(
                (errorCheck) => errorCheck.articleName ?? '',
              )}
            >
              <span className={textClasses}>
                {pluralizeWord(
                  deliveryNoteReferencedExistsErrorCount,
                  'Artikel',
                  true,
                )}
              </span>
            </InvoiceCheckListTooltip>
            {' ' +
              pluralizeWord(deliveryNoteReferencedExistsErrorCount, 'enthält') +
              ' keine Referenz auf eine Lieferung.'}
          </>,
        );
      }

      // Special case needed to add space between the two sentences.
      if (errorText.length === 2) {
        return (
          <>
            {errorText[0]} {errorText[1]}
          </>
        );
      }

      return errorText[0];
    };

    // Attention: Little bug will occur here when some dlns have been delayed signed and some haven't been signed at all.
    // In this case, the delayed signed dlns are displayed as not signed at all because the overall status of the category is still InvoiceCheckResult.STATUS.ERROR.
    const getSignatureCheckText = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return (
          pluralizeString(
            category.successChecks.length,
            'Lieferung wurde',
            true,
          ) + ' signiert.'
        );
      }

      if (category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS) {
        return (
          pluralizeString(
            category.delayedSuccessChecks.length,
            'Lieferung wurde',
            true,
          ) + ' nachträglich signiert.'
        );
      }

      return (
        pluralizeString(category.errorChecks.length, 'Lieferung wurde', true) +
        ' nicht signiert.'
      );
    };

    const getSignatureCheckTextTooltip = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getSignatureCheckText();
      }

      if (category.status === InvoiceCheckResult.STATUS.DELAYED_SUCCESS) {
        return getSignatureCheckText();
      }

      return (
        <>
          <InvoiceCheckListTooltip
            title="Nicht signierte Lieferungen"
            items={category.errorChecks.map(
              (errorCheck) => errorCheck.deliveryNotes[0]?.number ?? '',
            )}
          >
            <span className="text-warningBase font-bold">
              {pluralizeWord(category.errorChecks.length, 'Lieferung', true)}
            </span>
          </InvoiceCheckListTooltip>
          {' ' +
            pluralizeWord(category.errorChecks.length, 'wurde') +
            ' nicht signiert.'}
        </>
      );
    };

    // Only display the distinct amount of articles because in some invoices it can happen that the error is thrown for the same article many times in different delivery notes.
    // By only displaying the distinct amount, a better aggregation of errors and thus a better understanding for the user should be achieved.
    const getArticleExistsCheckText = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        const articles = unique(
          category.successChecks.map(
            (errorCheck) => errorCheck.articleName ?? '',
          ),
        );
        return (
          articles.length +
          ' Artikel ' +
          pluralizeWord(articles.length, 'konnte') +
          ' in den Lieferungen gefunden werden.'
        );
      }

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      return (
        'Es gibt ' +
        articles.length +
        ' Artikel in der Rechnung, ' +
        pluralizeWord(articles.length, 'der') +
        ' in ' +
        pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
        ' nicht gefunden werden ' +
        pluralizeWord(articles.length, 'konnte') +
        '.'
      );
    };

    const getArticleExistsCheckTextTooltip = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getArticleExistsCheckText();
      }

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      if (articles.length === category.errorChecks.length) {
        return (
          <>
            {'Es gibt '}
            <InvoiceCheckListTooltip
              title="Nicht gefundene Artikel"
              items={articles}
            >
              <span className="font-bold text-red-500">
                {articles.length} Artikel
              </span>
            </InvoiceCheckListTooltip>
            {' in der Rechnung, ' +
              pluralizeWord(articles.length, 'der') +
              ' in '}
            <InvoiceCheckListTooltip
              title="In den Lieferungen"
              items={deliveryNotes}
            >
              <span className="font-bold text-red-500">
                {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
              </span>
            </InvoiceCheckListTooltip>
            {' nicht gefunden werden ' +
              pluralizeWord(articles.length, 'konnte') +
              '.'}
          </>
        );
      }

      const tooltipHeader = ['Artikel', 'fehlt in'];
      const tooltipRows = unique(
        category.errorChecks.map(({ articleName }) => articleName),
      ).map((articleName) => {
        const errorChecks = category.errorChecks.filter(
          (checkResult) => checkResult.articleName === articleName,
        );

        return [
          articleName,
          pluralizeWord(
            InvoiceCheckResult.getDeliveryNoteNumbers(errorChecks).length,
            'Lieferung',
            true,
          ),
        ];
      });

      return (
        <>
          {'Es gibt '}
          <InvoiceCheckTableTooltip
            title="Nicht gefundene Artikel"
            header={tooltipHeader}
            rows={tooltipRows}
          >
            <span className="font-bold text-red-500">
              {articles.length} Artikel
            </span>
          </InvoiceCheckTableTooltip>
          {' in der Rechnung, ' +
            pluralizeWord(articles.length, 'der') +
            ' in '}
          <InvoiceCheckListTooltip
            title="In den Lieferungen"
            items={deliveryNotes}
          >
            <span className="font-bold text-red-500">
              {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
            </span>
          </InvoiceCheckListTooltip>
          {' nicht gefunden werden ' +
            pluralizeWord(articles.length, 'konnte') +
            '.'}
        </>
      );
    };

    // Articles are not listed distinctly because if amounts differ, this must be checked manually for each article in each delivery note.
    // It is important that no wrong article amount isn't checked manually.
    // Additionally, displaying only the distinct amount of wrong articles isn't that significant because this error doesn't occur that often (e.g. compared to DeliveryNoteMaterialMatches).
    const getAmountCheckText = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        const articles = unique(
          category.successChecks.map(
            (errorCheck) => errorCheck.articleName ?? '',
          ),
        );
        const deliveryNotes = unique(
          InvoiceCheckResult.getDeliveryNoteNumbers(category.successChecks),
        );
        return (
          'Die Menge zwischen Rechnung und Lieferung stimmt für ' +
          articles.length +
          ' Artikel in ' +
          pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
          ' überein.'
        );
      }

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      return (
        'Die Menge zwischen Rechnung und Lieferung stimmt für ' +
        articles.length +
        ' Artikel in ' +
        pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
        ' nicht überein.'
      );
    };

    const getAmountCheckTextTooltip = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getAmountCheckText();
      }

      const tooltipHeader = [
        'Artikel',
        'in Lieferung',
        'Lieferung',
        'Rechnung',
      ];
      const tooltipRows = category.errorChecks.map((errorCheck) => [
        errorCheck.articleName ?? '',
        errorCheck.getDeliveryNoteNumbers().join(', '),
        errorCheck.expectedValue ?? '-',
        errorCheck.invoiceValue ?? '-',
      ]);

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      return (
        <>
          {'Die Menge zwischen Rechnung und Lieferung stimmt für '}
          <InvoiceCheckTableTooltip
            title="Nicht übereinstimmende Mengen bei den Artikeln"
            header={tooltipHeader}
            rows={tooltipRows}
          >
            <span className="font-bold text-red-500">
              {articles.length} Artikel
            </span>
            {' in '}
            <span className="font-bold text-red-500">
              {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
            </span>
          </InvoiceCheckTableTooltip>
          {' nicht überein.'}
        </>
      );
    };

    // Articles are not listed distinctly because if articles are declined, this must be checked manually for each article in each delivery note.
    // It is important that no wrong article amount isn't checked manually.
    // Additionally, displaying only the distinct amount of wrong articles isn't that significant because this error doesn't occur that often (e.g. compared to DeliveryNoteMaterialMatches).
    const getAmountApprovedCheckText = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return 'Es gab keine Reklamationen.';
      }

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      return (
        articles.length +
        ' Artikel ' +
        pluralizeWord(articles.length, 'wurde') +
        ' in ' +
        pluralizeWord(deliveryNotes.length, 'Lieferung', true) +
        ' reklamiert oder die Menge wurde nicht komplett bestätigt.'
      );
    };

    const getAmountApprovedCheckTextTooltip = () => {
      if (category.status === InvoiceCheckResult.STATUS.SUCCESS) {
        return getAmountApprovedCheckText();
      }

      const tooltipHeader = ['Artikel', 'in Lieferung'];
      const tooltipRows = category.errorChecks.map((errorCheck) => [
        errorCheck.articleName ?? '',
        errorCheck.getDeliveryNoteNumbers().join(', '),
      ]);

      const articles = unique(
        category.errorChecks.map((errorCheck) => errorCheck.articleName ?? ''),
      );
      const deliveryNotes = unique(
        InvoiceCheckResult.getDeliveryNoteNumbers(category.errorChecks),
      );

      return (
        <>
          <InvoiceCheckTableTooltip
            title="Reklamierte oder nicht bestätigte Artikel"
            header={tooltipHeader}
            rows={tooltipRows}
          >
            <span className="font-bold text-red-500">
              {articles.length} Artikel
            </span>
            {' ' + pluralizeWord(articles.length, 'wurde') + ' in '}
            <span className="font-bold text-red-500">
              {pluralizeWord(deliveryNotes.length, 'Lieferung', true)}
            </span>
          </InvoiceCheckTableTooltip>
          {' reklamiert oder die Menge wurde nicht komplett bestätigt.'}
        </>
      );
    };

    return getText();
  },
  'Die Zusammenfassung der Rechnungsprüfungsergebnisse konnte nicht geladen werden.',
);

InvoiceCheckSummaryText.displayName = 'InvoiceCheckSummaryText';
