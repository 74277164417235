import { LOADING_STATE } from '~/constants/LoadingState';
import { ComponentTestIds } from '~/constants/test-ids';

export const NoRowsOverlay = ({ loading }) => (
  <div
    className="flex h-full w-full items-center justify-center"
    data-testid={ComponentTestIds.TABLE.EMPTY_TABLE}
  >
    {loading === LOADING_STATE.FAILED
      ? 'Daten konnten nicht geladen werden.'
      : 'Keine Einträge'}
  </div>
);
