import { cloneDeep } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useQueryCustomFields } from '~/data/customField';
import { useQueryUserData } from '~/data/user';

import {
  setDashboard_filterGroups,
  setDashboard_filterRows,
  setDashboard_selectedAcceptState,
  setDashboard_selectedArticle,
  setDashboard_selectedArticleNumber,
  setDashboard_selectedCategory,
  setDashboard_selectedCostCenter,
  setDashboard_selectedCustomFields,
  setDashboard_selectedFilterGroup,
  setDashboard_selectedFromSite,
  setDashboard_selectedOuId,
  setDashboard_selectedPermittedCostCenters,
  setDashboard_selectedPermittedToSites,
  setDashboard_selectedProcessState,
  setDashboard_selectedRecipient,
  setDashboard_selectedSettledStatus,
  setDashboard_selectedSupplier,
  setDashboard_selectedToSiteRecipient,
  setDashboard_selectedToSiteSupplier,
  setDashboard_selectedToSiteSupplierTradeContact,
} from '~/redux/filtersSlice';

import ToastService from '~/services/toast.service';
import UserService from '~/services/user.service';

import DeliveryNote from '~/models/deliveries/DeliveryNote';
import FilterGroupFilter from '~/models/filters/FilterGroupFilter';
import FilterNew from '~/models/filters/FilterNew';

import { updateByKey } from '~/utils/array';
import { getFilterContext } from '~/utils/filters';
import Log from '~/utils/logging';
import { promiseHandler } from '~/utils/promiseHandler';
import UserUtils from '~/utils/userUtils';

import { withErrorBoundary } from '~/ui/atoms';
import { FilterGroups } from '~/ui/molecules/FilterGroups';

import { selectDashboardFilters } from '../utils';

import {
  emptyDashboardFilterGroup,
  getFilterGroupObject as getFilterGroupObjectUtil,
} from './utils/filterGroups/getFilterGroupObject';

export const DashboardFilterGroups = withErrorBoundary(({ onChangeValue }) => {
  const dispatch = useDispatch();

  const {
    dateRange,
    filterGroups,
    filterRows,
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCategory,
    selectedCostCenter,
    selectedCustomFields,
    selectedFilterGroup,
    selectedFromSite,
    selectedOuId,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
    selectedToSiteSupplierTradeContact,
  } = useSelector(selectDashboardFilters);

  const { data: customFields } = useQueryCustomFields();

  const { data: currentUser } = useQueryUserData(true);
  const featureFlags = currentUser?.featureFlags ?? {};

  const [state, setState] = useState({
    filterGroups: [],
    selectableFilters: [],
  });

  const getFilterGroups = () => {
    const newFilterGroups = cloneDeep(filterGroups)
      .map((filterGroup) => {
        const activeFilters = FilterNew.getActiveFiltersForFilterGroup(
          filterGroup,
          'dashboard',
        );

        const nonApplicableBackendFilters =
          FilterNew.getNonApplicableFilters(activeFilters);
        if (nonApplicableBackendFilters.length > 0) {
          Log.info(
            `"${filterGroup.name}" has non-applicable backend filters!`,
            {
              nonApplicableBackendFilters,
              filterGroup,
            },
          );
        }

        return {
          ...filterGroup,
          disabled: false, // nonApplicableBackendFilters.length > 0,
        };
      })
      .filter(Boolean);

    return newFilterGroups;
  };

  const getSelectableFilters = () => {
    const selectableFilters = [
      {
        id: FilterGroupFilter.FILTER.SELECTED_ACCEPT_STATE,
        name: DeliveryNote.PROPERTY.ACCEPT_STATE.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_ARTICLE,
        name: DeliveryNote.PROPERTY.ARTICLE.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_ARTICLE_NUMBER,
        name: DeliveryNote.PROPERTY.ARTICLE_NUMBER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_CATEGORY,
        name: DeliveryNote.PROPERTY.CATEGORY.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_COST_CENTER,
        name: DeliveryNote.PROPERTY.COST_CENTER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_FROM_SITE,
        name: DeliveryNote.PROPERTY.FROM_SITE.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_OU_ID,
        name: DeliveryNote.PROPERTY.ORGANIZATIONAL_UNIT.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_PROCESS_STATE,
        name: DeliveryNote.PROPERTY.PROCESS_STATE.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_RECIPIENT,
        name: DeliveryNote.PROPERTY.RECIPIENT.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_SETTLED_STATUS,
        name: DeliveryNote.PROPERTY.SETTLED_STATUS.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_SUPPLIER,
        name: DeliveryNote.PROPERTY.SUPPLIER.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_RECIPIENT,
        name: DeliveryNote.PROPERTY.TO_SITE_RECIPIENT.STRING,
      },
      {
        id: FilterGroupFilter.FILTER.SELECTED_TO_SITE_SUPPLIER,
        name: DeliveryNote.PROPERTY.TO_SITE_SUPPLIER.STRING,
      },
    ];

    if (
      featureFlags.accessPermittedSites ||
      UserUtils.isPermittedSiteAllowedUser()
    ) {
      selectableFilters.push(
        {
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_TO_SITES,
          name: DeliveryNote.PROPERTY.PERMITTED_TO_SITE_NAMES.STRING,
        },
        {
          id: FilterGroupFilter.FILTER.SELECTED_PERMITTED_COST_CENTERS,
          name: DeliveryNote.PROPERTY.PERMITTED_COST_CENTER_NAMES.STRING,
        },
      );
    }

    if (customFields && customFields.length > 0) {
      for (const customField of customFields) {
        selectableFilters.push({
          customField,
          disabled: false,
          id: customField.key,
          name: customField.displayName,
          type: customField.type,
        });
      }
    }

    return selectableFilters;
  };

  const getFilterGroupObject = useCallback(
    (
      id: string,
      name?: string,
      filterRows?: any[],
      isEmptyFilterGroup?: boolean,
    ) => {
      return getFilterGroupObjectUtil({
        filterGroupId: id,
        filterGroupName: name,
        filterGroups,
        filterRows,
        filterValues: {
          selectedAcceptState,
          selectedArticle,
          selectedArticleNumber,
          selectedCategory,
          selectedCostCenter,
          selectedCustomFields,
          selectedFromSite,
          selectedOuId,
          selectedPermittedCostCenters,
          selectedPermittedToSites,
          selectedProcessState,
          selectedRecipient,
          selectedSettledStatus,
          selectedSupplier,
          selectedToSiteRecipient,
          selectedToSiteSupplier,
          selectedToSiteSupplierTradeContact,
        },
        calculatedFilterModel: [],
        isEmptyFilterGroup: isEmptyFilterGroup ?? false,
      });
    },
    [
      filterGroups,
      selectedAcceptState,
      selectedArticle,
      selectedArticleNumber,
      selectedCategory,
      selectedCostCenter,
      selectedCustomFields,
      selectedFromSite,
      selectedOuId,
      selectedPermittedCostCenters,
      selectedPermittedToSites,
      selectedProcessState,
      selectedRecipient,
      selectedSettledStatus,
      selectedSupplier,
      selectedToSiteRecipient,
      selectedToSiteSupplier,
      selectedToSiteSupplierTradeContact,
    ],
  );

  const initSelectableFilters = () => {
    setState((previousState) => ({
      ...previousState,
      selectableFilters: getSelectableFilters(),
    }));
  };

  const resetFilterRows = () => {
    const firstSelectableFilter = getSelectableFilters()?.find(
      ({ disabled }) => !disabled,
    );
    const newFilterRows = [firstSelectableFilter?.id].filter(Boolean);

    handleUpdateFilterRows(newFilterRows);
  };

  const resetFilters = () => {
    dispatch(setDashboard_selectedAcceptState([]));
    dispatch(setDashboard_selectedArticle([]));
    dispatch(setDashboard_selectedArticleNumber([]));
    dispatch(setDashboard_selectedCategory([]));
    dispatch(setDashboard_selectedCostCenter([]));
    dispatch(setDashboard_selectedCustomFields([]));
    dispatch(setDashboard_selectedFromSite([]));
    dispatch(setDashboard_selectedOuId([]));
    dispatch(setDashboard_selectedPermittedCostCenters([]));
    dispatch(setDashboard_selectedPermittedToSites([]));
    dispatch(setDashboard_selectedProcessState([]));
    dispatch(setDashboard_selectedRecipient([]));
    dispatch(setDashboard_selectedSettledStatus([]));
    dispatch(setDashboard_selectedSupplier([]));
    dispatch(setDashboard_selectedToSiteRecipient([]));
    dispatch(setDashboard_selectedToSiteSupplier([]));
    dispatch(setDashboard_selectedToSiteSupplierTradeContact([]));

    resetFilterRows();
  };

  const initFilterGroups = () => {
    const newFilterGroups = getFilterGroups();

    setState((previousState) => ({
      ...previousState,
      filterGroups: newFilterGroups,
    }));

    if (
      newFilterGroups.find(({ id }) => id === selectedFilterGroup)?.disabled
    ) {
      dispatch(setDashboard_selectedFilterGroup(null));
      resetFilters();
    }
  };

  const handleClickFilterGroup = (id) => {
    if (id !== selectedFilterGroup) {
      resetFilters();
      dispatch(setDashboard_selectedFilterGroup(id));
      const filterGroup = filterGroups.find((group) => group.id === id);
      handleChangeFilterGroup(filterGroup);
      return;
    }

    dispatch(setDashboard_selectedFilterGroup(null));
    resetFilters();
  };

  const handleSaveNewFilterGroup = async (
    id,
    name,
    filterRows,
    emptyFilters,
  ) => {
    const newFilterGroups = [
      ...filterGroups,
      getFilterGroupObject(id, name, filterRows, emptyFilters),
    ];

    dispatch(setDashboard_filterGroups(newFilterGroups));
    dispatch(setDashboard_selectedFilterGroup(id));

    const [, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to save new filter group.', error);
      ToastService.error(['Filter konnte nicht hinzugefügt werden.']);
    }
  };

  const handleUpdateFilterGroupName = async (id, name) => {
    const newFilterGroups = cloneDeep(filterGroups);

    for (const filterGroup of newFilterGroups) {
      if (filterGroup.id === id) {
        filterGroup.name = name;
      }
    }

    dispatch(setDashboard_filterGroups(newFilterGroups));

    const [, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group name.', error);
      ToastService.error(['Filter konnte nicht umbenannt werden.']);
    }
  };

  const handleUpdateFilterGroup = async (filterRows) => {
    let newFilterGroups = cloneDeep(filterGroups);

    newFilterGroups = updateByKey(
      filterGroups,
      'id',
      selectedFilterGroup,
      getFilterGroupObject(selectedFilterGroup, null, filterRows),
    );

    dispatch(setDashboard_filterGroups(newFilterGroups));

    const [, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to update filter group.', error);
      ToastService.error(['Filter konnte nicht geändert werden.']);
    }
  };

  const handleUpdateFilterRows = (filterRows) => {
    dispatch(setDashboard_filterRows(filterRows));
  };

  const handleDeleteFilterGroup = async () => {
    let newFilterGroups = cloneDeep(filterGroups);

    newFilterGroups = filterGroups.filter(
      ({ id }) => id !== selectedFilterGroup,
    );

    dispatch(setDashboard_filterGroups(newFilterGroups));
    dispatch(setDashboard_selectedFilterGroup(null));
    resetFilters();

    const [, error] = await promiseHandler(
      UserService.updateDashboardFilterGroups(newFilterGroups),
    );

    if (error) {
      Log.error('Failed to delete filter group.', error);
      ToastService.error(['Filter konnte nicht gelöscht werden.']);
    }
  };

  const handleChangeFilterGroup = (filterGroupRaw?: Record<string, any>) => {
    const filterGroup =
      filterGroupRaw ??
      filterGroups.find(({ id }) => id === selectedFilterGroup);

    if (!filterGroup) {
      return;
    }

    // Update filter rows
    dispatch(setDashboard_filterRows(filterGroup.filterRows || []));

    // Update all filter states
    dispatch(
      setDashboard_selectedAcceptState(
        filterGroup.filters?.selectedAcceptState ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedArticle(filterGroup.filters?.selectedArticle ?? []),
    );
    dispatch(
      setDashboard_selectedArticleNumber(
        filterGroup.filters?.selectedArticleNumber ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedCategory(
        filterGroup.filters?.selectedCategory ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedCostCenter(
        filterGroup.filters?.selectedCostCenter ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedCustomFields(
        filterGroup.filters?.selectedCustomFields ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedFromSite(
        filterGroup.filters?.selectedFromSite ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedOuId(filterGroup.filters?.selectedOuId ?? []),
    );
    dispatch(
      setDashboard_selectedPermittedCostCenters(
        filterGroup.filters?.selectedPermittedCostCenters ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedPermittedToSites(
        filterGroup.filters?.selectedPermittedToSites ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedProcessState(
        filterGroup.filters?.selectedProcessState ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedRecipient(
        filterGroup.filters?.selectedRecipient ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedSettledStatus(
        filterGroup.filters?.selectedSettledStatus ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedSupplier(
        filterGroup.filters?.selectedSupplier ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedToSiteRecipient(
        filterGroup.filters?.selectedToSiteRecipient ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedToSiteSupplier(
        filterGroup.filters?.selectedToSiteSupplier ?? [],
      ),
    );
    dispatch(
      setDashboard_selectedToSiteSupplierTradeContact(
        filterGroup.filters?.selectedToSiteSupplierTradeContact ?? [],
      ),
    );
  };

  const initSelectedFilterGroup = () => {
    if (!selectedFilterGroup || filterGroups.length === 0) {
      resetFilterRows();
      return;
    }

    const filterGroup = filterGroups.find(
      ({ id }) => id === selectedFilterGroup,
    );
    if (filterGroup) {
      handleChangeFilterGroup(filterGroup);
    }
  };

  const resetSelectedFilterGroup = () => {
    if (filterGroups.length === 0) {
      dispatch(setDashboard_selectedFilterGroup(null));
      handleChangeFilterGroup();
      return;
    }

    const newSelectedFilterGroup = selectedFilterGroup ?? filterGroups[0]?.id;

    dispatch(setDashboard_selectedFilterGroup(newSelectedFilterGroup));
    handleChangeFilterGroup(newSelectedFilterGroup);
  };

  useEffect(() => {
    initSelectedFilterGroup();
    initSelectableFilters();
    initFilterGroups();
  }, []);

  useEffect(() => {
    initSelectedFilterGroup();
  }, [filterGroups.length]);

  useEffect(() => {
    initSelectableFilters();
  }, [
    dateRange,
    selectedAcceptState,
    selectedArticle,
    selectedArticleNumber,
    selectedCategory,
    selectedCostCenter,
    selectedCustomFields,
    selectedFilterGroup,
    selectedFromSite,
    selectedOuId,
    selectedPermittedCostCenters,
    selectedPermittedToSites,
    selectedProcessState,
    selectedRecipient,
    selectedSettledStatus,
    selectedSupplier,
    selectedToSiteRecipient,
    selectedToSiteSupplier,
    selectedToSiteSupplierTradeContact,
  ]);

  useEffect(() => {
    if (customFields) {
      initSelectableFilters();
    }
  }, [JSON.stringify(customFields)]);

  useEffect(() => {
    initFilterGroups();
  }, [filterGroups, dateRange]);

  const currentFilterGroupObject = useMemo(
    () => getFilterGroupObject(selectedFilterGroup),
    [getFilterGroupObject, selectedFilterGroup],
  );

  const originalFilterGroupObject = useMemo(
    () => filterGroups.find(({ id }) => id === selectedFilterGroup),
    [filterGroups, selectedFilterGroup],
  );

  return (
    <FilterGroups
      filterGroupOpen
      filterContext={getFilterContext({ page: 'dashboard' })}
      currentFilterGroupObject={currentFilterGroupObject}
      deleteFilterGroup={handleDeleteFilterGroup}
      emptyFilterGroupObject={emptyDashboardFilterGroup}
      filterGroups={state.filterGroups}
      filterRows={filterRows}
      originalFilterGroupObject={originalFilterGroupObject}
      resetSelectedFilterGroup={resetSelectedFilterGroup}
      saveNewFilterGroup={handleSaveNewFilterGroup}
      selectableFilters={state.selectableFilters}
      selectedFilterGroup={selectedFilterGroup}
      updateFilterGroup={handleUpdateFilterGroup}
      updateFilterGroupName={handleUpdateFilterGroupName}
      updateFilterRows={handleUpdateFilterRows}
      onChangeValue={onChangeValue}
      onClickFilterGroup={handleClickFilterGroup}
    />
  );
}, 'Filtergruppen konnten nicht geladen werden.');

DashboardFilterGroups.displayName = 'DashboardFilterGroups';
