import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { useQueryUserData } from '~/data/user';

import AuthService from '~/services/auth.service';

import Log from '~/utils/logging';
import UserUtils from '~/utils/userUtils';

export const DeactivatedAccountModal = () => {
  const { data: currentUser } = useQueryUserData(true);
  const featureFlags = currentUser?.featureFlags ?? {};
  const userInfo = currentUser?.userSettings ?? {};
  const userName = [userInfo?.firstName, userInfo?.lastName]
    .filter(Boolean)
    .join(' ');

  const logOut = () => {
    AuthService.logout();
  };

  const contactSupport = () => {
    Log.productAnalyticsEvent(
      'Contact support for account activation',
      Log.FEATURE.OTHER_FEATURE,
    );
    globalThis.location =
      'mailto:support@vestigas.com?subject=VESTIGAS-Vollversion';
  };

  return (
    <Dialog
      open={Boolean(
        featureFlags.deactivatedCompanyAccount &&
          !UserUtils.isVestigasAccount(),
      )}
      aria-labelledby="deactivated-account-modal-title"
      aria-describedby="deactivated-account-description"
      scroll="paper"
      maxWidth="lg"
    >
      <DialogTitle className="text-xl font-bold">
        {['Willkommen zurück', userName].filter(Boolean).join(', ')}
      </DialogTitle>
      <DialogContent>
        <div>
          Dein Account ist derzeit deaktiviert. Für den Fall, dass du
          <br />
          VESTIGAS weiterhin nutzen möchtest, kontaktiere bitte
          <br />
          den Support unter{' '}
          <span className="cursor-pointer font-bold" onClick={contactSupport}>
            support@vestigas.com
          </span>{' '}
          oder direkt
          <br />
          deine persönliche Ansprechperson bei VESTIGAS.
        </div>
        <div className="mt-4">Dein VESTIGAS Team</div>
        <div className="mt-4">
          <Button variant="text" className="mr-8" onClick={logOut}>
            Ausloggen
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="primary-button"
            onClick={contactSupport}
          >
            Support kontaktieren
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
