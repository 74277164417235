import { addressToString } from './Address';

export default class SiteReference {
  constructor(siteReference, assignedSiteId) {
    // Line breaks cause the mui data grid to crash. That's why the identifier must be "cleaned".
    siteReference.identifier = siteReference.identifier.replaceAll('\n', ' ');

    this.id = `${siteReference?.supplier_id || siteReference?.supplierId}-${siteReference.identifier}`;
    this.address = siteReference?.address;
    this.addressString = addressToString(this.address);
    this.assignedSiteId = assignedSiteId;
    this.assignedSiteName = null;
    this.identifier = siteReference?.identifier;
    this.identifierId =
      siteReference?.identifier_uuid || siteReference?.identifierUuid;
    this.ignoreAddressInfo =
      siteReference?.ignore_address_info ??
      siteReference?.ignoreAddressInfo ??
      true;
    this.issuerId = siteReference?.supplier_id || siteReference?.supplierId;
    this.isBlacklisted =
      siteReference?.blacklisted ?? siteReference?.isBlacklisted ?? false;
    this.issuerName =
      siteReference?.supplier_name || siteReference?.supplierName;
    this.proposalId =
      siteReference?.proposal_uuid || siteReference?.proposalUuid;
  }
}
