import { CircularProgress } from '@mui/material';

import { cn } from '~/utils/tailwind';

type P = {
  readonly alignLeft?: boolean;
  readonly size?: number;
  readonly title?: string;
  readonly white?: boolean;
} & ComponentStyling;

export const Spinner = ({
  alignLeft,
  className,
  size,
  title,
  white,
  style,
}: P) => (
  <div
    className={cn(
      'h-full',
      {
        'flex w-full items-center justify-center': !alignLeft,
        'text-white': white,
      },
      className,
    )}
    style={style}
  >
    <CircularProgress
      size={size ?? 20}
      style={{ color: white ? 'white' : 'currentColor' }}
    />
    {title ? <div className="ml-2">{title}</div> : null}
  </div>
);
