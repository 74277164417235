export const InvoiceIcon = ({ className, style }: ComponentStyling) => (
  <svg
    viewBox="0 0 36 36"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M4.5 33L6.75 30.75L9 33L11.25 30.75L13.5 33L15.75 30.75L18 33L20.25 30.75L22.5 33L24.75 30.75L27 33L29.25 30.75L31.5 33V3L29.25 5.25L27 3L24.75 5.25L22.5 3L20.25 5.25L18 3L15.75 5.25L13.5 3L11.25 5.25L9 3L6.75 5.25L4.5 3M27 13.5H9V10.5H27M27 19.5H9V16.5H27M27 25.5H9V22.5H27V25.5Z"
      fill="currentColor"
    />
  </svg>
);
