import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import Log from '~/utils/logging';

import { queryKeysCompany } from './queryKeys';

/**
 * Fetches company logo from the API.
 * @param companyId - The ID of the company to fetch the logo for.
 * @returns The company logo as a Blob.
 * @throws Error if the API request fails.
 */
export const fetchCompanyLogo = async (companyId: UUID): Promise<Blob> => {
  try {
    return await vestigasApi.get(ENDPOINT.COMPANY.GET_LOGO(companyId)).blob();
  } catch (error) {
    Log.error('Error fetching company logo', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the logo for a company with a given companyId.
 * @param companyId - The ID of the company to fetch the logo for.
 * @param options - Additional options for the useQuery hook.
 * @returns The result of the useQuery hook.
 */
export const useQueryCompanyLogo = (
  companyId: UUID,
  options: Omit<UseQueryOptions<Blob>, 'queryKey' | 'queryFn'> = {},
) =>
  useQuery<Blob>({
    enabled: Boolean(companyId),
    gcTime: ms('60m'),
    queryFn: async () => fetchCompanyLogo(companyId),
    queryKey: queryKeysCompany.getLogo(companyId),
    select: (logoData) =>
      logoData.size > 0 ? URL.createObjectURL(logoData) : null,
    staleTime: Infinity, // Don't refetch a cached file
    ...options,
  });
