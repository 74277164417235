import { useEffect, useRef } from 'react';

/**
 * A custom React hook that stores and returns the previous value of a variable
 * across re-renders.
 * Always returns the previous value, even if it is identical to the value before
 * and does not only change if the value changes.
 *
 * @template T - The type of the value being tracked
 * @param value - The current value to track
 * @returns The previous value (undefined on first render)
 *
 * @example
 * const [count, setCount] = useState(0);
 * const previousCount = usePrevious(count);
 * // If count is 5, previousCount will be 4
 */
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
