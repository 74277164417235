import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { difference } from '~/utils/array';

import Address from './Address';
import Coordinates from './Coordinates';
import CSVValidator from './CSVValidator';
import PermissionGrant from './PermissionGrant';
import SiteReference from './SiteReference';
import TradeContact from './TradeContact';

export default class Site {
  constructor(site, additionalDataInitiated) {
    this.id = site?.id ?? null;
    this.issuerAssignedId =
      site?.issuer_assigned_id ?? site?.issuerAssignedId ?? null;
    this.name = !site?.name || site?.name === 'null' ? '' : site?.name;
    this.type = site?.type ?? Site.TYPE.CONSTRUCTION_SITE.KEY;
    this.active = site?.is_active ?? site?.isActive ?? true; // FIXME: it is so bad that this is renamed to a much worse name!
    this.isActive = site?.is_active ?? site?.isActive ?? true;
    this.address = new Address(site?.address);
    this.coords = new Coordinates(site?.coords);
    this.costCenters =
      site?.accounting_reference_list ?? site?.accountingReferenceList ?? [];
    this.company = site?.company_id ?? site?.companyId ?? ''; // TODO: this can likely be removed!
    this.companyId = site?.company_id ?? site?.companyId ?? '';
    this.tradeContact = new TradeContact(
      site?.trade_contact ?? site?.tradeContact,
    );
    this.references =
      (site?.identifier_list ?? site?.identifierList)
        ? (site?.identifier_list ?? site?.identifierList).map(
            (item) => new SiteReference(item),
          )
        : [];
    this.start = site?.start || null;
    this.end = site?.end || null;

    // CRUD timestamps
    this.createdOn = site?.created_on ?? '';
    this.modifiedOn = site?.modified_on ?? '';

    this.organisationalGroups = site?.org_units ?? site?.orgUnits ?? []; // deprecated - use parentOrganizationalUnits instead
    this.parentOrganizationalUnits =
      site?.parent_organizational_units ??
      site?.parentOrganizationalUnits ??
      [];
    this.organisationalGroupPaths =
      site?.org_unit_paths ?? site?.orgUnitPaths ?? []; // deprecated - use parentOrganizationalUnitPaths instead
    this.parentOrganizationalUnitPaths =
      site?.parentOrganizationalUnitPaths ?? [];

    this.permissionGrantsFrom =
      site?.permissions_from?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
          }),
      ) ??
      site?.permissionsFrom?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.SITE.KEY,
          }),
      ) ??
      [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }
  }

  static getSiteType(typeKey) {
    const siteType = Object.keys(Site.TYPE).find(
      (x) => Site.TYPE[x].KEY === typeKey,
    );

    if (!siteType) {
      throw new EnumValueNotFoundException('invalid site type: ' + typeKey);
    }

    return Site.TYPE[siteType].STRING;
  }

  static getSiteTypes() {
    return Object.keys(Site.TYPE).map((x) => {
      return {
        id: Site.TYPE[x].KEY,
        name: Site.TYPE[x].STRING,
      };
    });
  }

  static getDifferentValues(siteA, siteB) {
    const differentValues = [];

    if (siteA?.id !== siteB?.id) {
      differentValues.push('ID');
    }

    if (siteA?.name !== siteB?.name) {
      differentValues.push('Name');
    }

    if (siteA?.type !== siteB?.type) {
      differentValues.push('Art');
    }

    if (siteA?.active !== siteB?.active) {
      differentValues.push('Aktiv');
    }

    if (siteA?.company !== siteB?.company) {
      differentValues.push('Firma');
    }

    if (Address.getDifferentValues(siteA?.address, siteB?.address).length > 0) {
      differentValues.push('Adresse');
    }

    if (
      Coordinates.getDifferentValues(siteA?.coords, siteB?.coords).length > 0
    ) {
      differentValues.push('Koordinaten');
    }

    // Difference check for trade contact is not needed currently as it can't be specified in site form.

    const differentCostCenters = difference(
      siteA?.costCenters,
      siteB?.costCenters,
    );
    if (
      differentCostCenters[0].length > 0 ||
      differentCostCenters[1].length > 0
    ) {
      differentValues.push('Kostenstellen');
    }

    const differentOrganisationalGroups = difference(
      siteA?.organisationalGroups,
      siteB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }

  static TYPE = {
    CONSTRUCTION_SITE: {
      KEY: 'construction_site',
      STRING: 'Baustelle',
    },
    DISPOSAL: {
      KEY: 'disposal',
      STRING: 'Entsorgung',
    },
    FACTORY: {
      KEY: 'factory',
      STRING: 'Fabrik',
    },
    PLANT: {
      KEY: 'plant',
      STRING: 'Werk',
    },
    WAREHOUSE: {
      KEY: 'warehouse',
      STRING: 'Lager',
    },
  };
  static CSV_ALLOWED_COLUMNS = [
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.NUMBER,
      NAME: 'latitude',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.NUMBER,
      NAME: 'longitude',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'city',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'country',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'post_code',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'street_name',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'building_number',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
      NAME: 'accounting_reference_list',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'name',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'type',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID,
      NAME: 'company_id',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
      NAME: 'org_units',
    },
  ];
  static CSV_WITH_COST_CENTERS_ALLOWED_COLUMNS = [
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.LIST,
      NAME: 'cost_centers',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'name',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'city',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'country',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'post_code',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'street_name',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.TEXT,
      NAME: 'building_number',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID,
      NAME: 'company_id',
    },
    {
      DATA_TYPE: CSVValidator.DATA_TYPE.UUID_LIST,
      NAME: 'org_units',
    },
  ];
}
