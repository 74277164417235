import { Check as CheckIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Log from '~/utils/logging';

import { selectableUnits } from '../constants';

const PAGINATION_SIZE = 5;

type Unit = {
  name: string;
  unit: string;
};

type P = {
  readonly onUnitChange: (unit: string | undefined) => void;
  readonly selectedUnit: string | undefined;
};

const UnitChip = memo(
  ({
    isSelected,
    name,
    onUnitChange,
    unit,
  }: {
    readonly isSelected: boolean;
    readonly name: string;
    readonly onUnitChange: (unit: string) => void;
    readonly unit: string;
  }) => (
    <Chip
      key={unit}
      label={name}
      icon={isSelected ? <CheckIcon className="!text-white" /> : null}
      className={
        isSelected ? 'bg-primary500 cursor-auto !text-white' : '!cursor-pointer'
      }
      onMouseDown={() => {
        onUnitChange(unit);
      }}
    />
  ),
);

UnitChip.displayName = 'UnitChip';

export const DashboardFilterUnitChips = memo(
  ({ onUnitChange, selectedUnit }: P) => {
    const [displayedUnits, setDisplayedUnits] = useState<Unit[]>([]);

    const displayedUnitsList = useMemo(() => {
      const selectedUnitIndex = selectableUnits?.findIndex(
        ({ unit }) => unit === selectedUnit,
      );

      if (selectedUnit !== undefined && selectedUnitIndex === -1) {
        Log.error(
          'Failed to find selected unit in list of selectable units for pagination.',
          selectedUnit,
        );
      }

      return selectableUnits.slice(
        0,
        Math.max(PAGINATION_SIZE, selectedUnitIndex + 1),
      );
    }, [selectedUnit]);

    // Update state only if the calculation changed
    useEffect(() => {
      setDisplayedUnits(displayedUnitsList);
    }, [displayedUnitsList]);

    const handlePaginationClick = useCallback(
      (event) => {
        event.currentTarget.blur();

        Log.info('Load the next paginated set of selectable units.', {
          cursor: displayedUnits.length,
        });
        Log.productAnalyticsEvent(
          'Load paginated selectable units',
          Log.FEATURE.DASHBOARD,
        );

        setDisplayedUnits(
          selectableUnits.slice(0, displayedUnits.length + PAGINATION_SIZE),
        );
      },
      [displayedUnits.length],
    );

    const handleUnitChange = useCallback(
      (unit: string | undefined) => {
        onUnitChange(unit);
      },
      [onUnitChange],
    );

    return (
      <div className="flex flex-wrap items-center gap-2">
        <Chip
          label="Alle Einheiten"
          icon={selectedUnit ? null : <CheckIcon className="text-white" />}
          className={
            selectedUnit
              ? 'cursor-pointer'
              : 'bg-primary500 cursor-auto text-white'
          }
          onMouseDown={() => {
            handleUnitChange(undefined);
          }}
        />
        {displayedUnits.map(({ name, unit }) => (
          <UnitChip
            key={unit}
            name={name}
            unit={unit}
            isSelected={unit === selectedUnit}
            onUnitChange={handleUnitChange}
          />
        ))}
        {displayedUnits.length < selectableUnits.length && (
          <Chip
            key="more"
            label={`+${
              selectableUnits.length - displayedUnits.length
            } weitere Einheiten`}
            className="cursor-pointer"
            onMouseDown={handlePaginationClick}
          />
        )}
      </div>
    );
  },
);

DashboardFilterUnitChips.displayName = 'DashboardFilterUnitChips';
