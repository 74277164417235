export const PROCESS_STATE = {
  ARRIVED: {
    API_KEY: 'arrived',
    CLASS: 'delivery-note-status-arrived',
    FILTER_DESC:
      'Diese Lieferungen wurden vom Fahrer für vollständig erklärt und zur Signatur freigegeben.',
    STRING: 'Übergabe',
    SUPPORT_DESC:
      'Sobald ein Fahrer die Lieferung für vollständig erklärt und zur Signatur freigegeben hat, ' +
      'befindet sie sich im Status "Übergabe". Die ausstehende Signatur kann sowohl mittels normaler ' +
      'Übergabe als auch verzögerter Abgabe geschehen. Anschließend erhalten relevante ' +
      'Abnahmeberechtigte eine Benachrichtigung und können die Lieferung bestätigen.',
  },
  CANCELLED: {
    API_KEY: 'cancelled',
    CLASS: 'delivery-note-status-cancelled',
    FILTER_DESC: 'Alle Lieferungen mit dem Status "Storniert".',
    STRING: 'Storniert',
    SUPPORT_DESC:
      'Lieferungen können direkt vom Lieferanten oder Abnehmer storniert werden. Stornierte Lieferungen ' +
      'können nicht abgerechnet werden, also führen zu Fehlern in der Rechnungsprüfung.',
  },
  DEFAULT: {
    CLASS: 'delivery-note-status-default',
    // as fallback if no other process state fits
    STRING: 'Nicht gefunden',
  },
  DELIVERED: {
    API_KEY: 'delivered',
    CLASS: 'delivery-note-status-delivered',
    FILTER_DESC:
      'Diese Lieferungen wurden von einem Abnehmer signiert und "als Geliefert" bestätigt.',
    STRING: 'Geliefert',
    SUPPORT_DESC:
      'Sobald ein Abnehmer die Lieferung bestätigt und damit signiert hat, wechselt die Lieferung in ' +
      'diesen Status. Nun wird die Lieferung in allen Statistiken erfasst und kann in einer ' +
      'Rechnung referenziert werden.',
  },
  IMPORTED: {
    API_KEY: 'imported',
    CLASS: 'delivery-note-status-imported',
    STRING: 'Importiert',
  },
  IN_TRANSPORT: {
    API_KEY: 'inTransport',
    CLASS: 'delivery-note-status-in-transport',
    FILTER_DESC: 'Diese Lieferungen wurden von einem Fahrer angenommen.',
    STRING: 'In Transport',
    SUPPORT_DESC:
      'Sobald eine Lieferung von einem Fahrer angenommen wurde, gilt sie als In Transport. Ab ' +
      'hier ist sie auch für Mitarbeiter des Abnehmers einsehbar.',
  },
  READY_FOR_OUTPUT: {
    API_KEY: 'readyForOutput',
    CLASS: 'delivery-note-status-ready-for-output',
    FILTER_DESC:
      'Diese Lieferungen wurden durch den Lieferanten erstellt, aber noch nicht von einem Fahrer angenommen. ' +
      'Sie werden automatisch dem Mitarbeiter mit dem passenden Kennzeichen zur Annahme zugestellt.',
    STRING: 'Ausgabe',
    SUPPORT_DESC:
      'Diese Lieferung wurde durch den Lieferanten erstellt, aber noch nicht von einem Fahrer ' +
      'angenommen. Sie wird automatisch dem Mitarbeiter mit dem passenden Kennzeichen zur Annahme ' +
      'zugestellt.',
  },
  SETTLED: {
    // deprecated: only used in old dlns
    API_KEY: 'settled',
  },
} as const;

export const COMBINED_STATE = {
  DECLINED: {
    DESCRIPTION: 'Diese Lieferung wurde abgelehnt.',
    KEY: 'declined',
  },
  OTHER: {
    KEY: 'other',
  },
  SIGNATURE_NEEDED: {
    DESCRIPTION: 'Diese Lieferung muss erneut signiert werden.',
    KEY: 'signature-needed',
  },
} as const;

export const PROCESS_ROLE = {
  CARRIER: {
    KEY: 'carrier',
    STRING: 'Spediteur',
  },
  RECIPIENT: {
    KEY: 'recipient',
    STRING: 'Abnehmer',
  },
  SUPPLIER: {
    KEY: 'supplier',
    STRING: 'Lieferant',
  },
} as const;

export const CONTRACT_ROLE = {
  BUYER: {
    KEY: 'buyer',
    STRING: 'Auftraggeber',
  },
  SELLER: {
    KEY: 'seller',
    STRING: 'Auftragnehmer',
  },
} as const;

export const PROCESS_CATEGORY = {
  CARRIER: {
    KEY: 'carrier',
    STRING: 'Spedition',
  },
  INTERNAL_DELIVERY: {
    KEY: 'internal-delivery',
    STRING: 'Interne Lieferung',
  },
  NONE: {
    KEY: 'none',
    STRING: 'Nicht in der Lieferung involviert',
  },
  RECIPIENT: {
    KEY: 'recipient',
    STRING: 'Eingehende Lieferung',
  },
  SUPPLIER: {
    KEY: 'supplier',
    STRING: 'Ausgehende Lieferung',
  },
} as const;
