import {
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  type UseSuspenseInfiniteQueryOptions,
} from '@tanstack/react-query';

const defaultInfiniteQueryOptions: { initialPageParam: number } & Partial<
  UseSuspenseInfiniteQueryOptions<any>
> = {
  initialPageParam: 0,
};

type PageData<T> = {
  data: T[];
  hasNextPage: boolean;
  totalCount: number;
};

type QueryParams = Record<string, any>;

export type InfiniteQueryOptionsType<
  TItem,
  TError extends Error,
> = UseInfiniteQueryOptions<PageData<TItem>, TError, PageData<TItem>>;

/**
 * Creates an infinite query hook for paginated data fetching based on React Query useInfiniteQuery.
 *
 * @template TItem - The type of items being fetched
 * @template TError - The type of error that can occur, defaults to Error
 * @param queryParams - Query parameters including pagination limit
 * @param options - Query options including queryKey and queryFn
 * @returns A react-query infinite query hook with pagination handling
 */
export const createInfiniteQuery = <
  TItem = unknown,
  TError extends Error = Error,
>(
  queryParams: QueryParams,
  { queryKey, queryFn, ...rest }: InfiniteQueryOptionsType<TItem, TError>,
) => {
  const infiniteQueryOptions: InfiniteQueryOptionsType<TItem, TError> = {
    queryFn,
    queryKey,
    ...defaultInfiniteQueryOptions,
    ...rest,
    getNextPageParam(lastPage, pages) {
      return lastPage.hasNextPage
        ? pages.length * queryParams.limit
        : undefined;
    },
    getPreviousPageParam(_, pages) {
      return pages.length <= 1
        ? undefined
        : pages.length - 2 * queryParams.limit;
    },
    select: (data) => ({
      data: data.pages.flatMap(({ data }) => data),
      hasNextPage: data.pages.at(-1)?.hasNextPage ?? false,
      totalCount: data.pages[0]?.totalCount,
    }),
  };

  return useInfiniteQuery(infiniteQueryOptions);
};
