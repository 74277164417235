import packageJson from '../../package.json';

export type Environment = 'development' | 'beta' | 'staging' | 'production';

export const apiUrl: string = import.meta.env.VITE_API_URL;
export const contactPageUrl: string = import.meta.env.VITE_CONTACT_PAGE_URL;
export const environment: Environment = import.meta.env.VITE_ENV;
export const gitHash: string = import.meta.env.VITE_GIT_HASH;
export const muiLicenseKey: string = import.meta.env.VITE_MUI_LICENSE_KEY;
export const redirectUrl: string = import.meta.env.VITE_REDIRECT_URL;
export const sentryDsn: string = import.meta.env.VITE_SENTRY_DSN;
export const sgwUrl: string = import.meta.env.VITE_SGW_URL;
export const { version } = packageJson;
