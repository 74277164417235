import {
  keepPreviousData,
  useQuery,
  useQueryClient,
  type UseQueryResult,
} from '@tanstack/react-query';
import ms from 'ms';
import { useEffect } from 'react';
import snakecaseKeys from 'snakecase-keys';

import { ENDPOINT } from '~/constants/endpoints';
import {
  PAGINATION_PAGE_SIZE_DEFAULT,
  type PaginationSortDirection,
} from '~/constants/pagination';

import { type UUID } from '~/types/common';

import { vestigasApi } from '~/services/kyClient';

import {
  createInfiniteQuery,
  type InfiniteQueryOptionsType,
} from '~/utils/createInfiniteQuery';
import Log from '~/utils/logging';
import { toSnakeCase } from '~/utils/string';

import { queryKeysOrganizationalUnit } from './queryKeys';

export type OrganizationalUnitsQueryParams = {
  filterCompanyAccount?: string;
  filterNotParentOu?: UUID;
  filterParentOu?: UUID;
  limit: number;
  offset: number;
  orderBy: string;
  returnTotalCount: boolean;
  searchString?: string;
  sort: PaginationSortDirection;
};

const defaultQueryParams: OrganizationalUnitsQueryParams = {
  filterCompanyAccount: undefined,
  filterNotParentOu: undefined,
  filterParentOu: undefined,
  limit: PAGINATION_PAGE_SIZE_DEFAULT,
  offset: 0,
  orderBy: 'name',
  returnTotalCount: true,
  searchString: undefined,
  sort: 'ASC',
};

export type OrganizationalUnitListItem = {
  companyAccountId: UUID;
  createdOn: string;
  id: UUID;
  modifiedOn: string;
  name: string;
};

type OrganizationalUnitsResponse = {
  data: readonly OrganizationalUnitListItem[];
  hasNextPage: boolean;
  paginatedCount: number;
  totalCount: number;
};

export const getOrganizationalUnitsQueryOptions = ({
  queryParams,
  options,
}: {
  queryParams: Partial<OrganizationalUnitsQueryParams>;
  options?: Parameters<typeof useQuery>[0];
}) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.sort = qp.sort?.toUpperCase();

  return {
    queryFn: async () => fetchOrganizationalUnits(qp),
    queryKey: queryKeysOrganizationalUnit.getAll(qp),
    staleTime: ms('60s'),
    ...options,
  };
};

/**
 * Fetches all organizational units from the API.
 * @param queryParams - The query parameters for fetching organizational units.
 * @returns The organizational units data.
 * @throws Error if the API request fails.
 * @see https://app.dev.vestigas.com/redoc#tag/Access-Control/operation/get_organizational_units_access_control_org_unit_test_all_get
 */
export const fetchOrganizationalUnits = async (
  queryParams: Partial<OrganizationalUnitsQueryParams>,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  qp.orderBy = toSnakeCase(qp.orderBy);
  qp.sort = qp.sort?.toUpperCase();

  for (const key of Object.keys(qp)) {
    if (qp[key] === undefined) {
      delete qp[key];
    }
  }

  try {
    const response = await vestigasApi
      .get(ENDPOINT.ORGANIZATIONAL_UNIT.GET_ALL(), {
        searchParams: snakecaseKeys(qp), // TODO: vestigasApi should convert search params to snake_case
      })
      .json<OrganizationalUnitsResponse>();

    return (
      response ?? {
        data: [],
        hasNextPage: false,
        paginatedCount: 0,
        totalCount: 0,
      }
    );
  } catch (error) {
    Log.error('Error fetching organizational units', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for all organizational units with given query parameters.
 * Handles pagination and prefetches the next page of organizational units for better performance.
 *
 * @param {Object} queryParams - The query parameters for fetching organizational units.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult} The result of the useQuery hook.
 */
export const useQueryOrganizationalUnits = (
  queryParams: Partial<OrganizationalUnitsQueryParams>,
  options: Parameters<typeof useQuery>[0],
): UseQueryResult<OrganizationalUnitsResponse | undefined> => {
  const queryClient = useQueryClient();

  useEffect(() => {
    if (queryParams?.searchString?.length > 0) {
      // Only prefetch if the user is not searching, to prevent firing lots of requests for costly queries.
      return;
    }

    queryClient.prefetchQuery(
      getOrganizationalUnitsQueryOptions({
        options,
        queryParams: {
          ...queryParams,
          offset: queryParams.offset + queryParams.limit,
        },
      }),
    );
  }, [queryParams, options, queryClient]);

  return useQuery({
    ...getOrganizationalUnitsQueryOptions({ options, queryParams }),
    placeholderData: keepPreviousData,
  });
};

/**
 * React Query based custom hook for getting the data for all organizational units with infinite scrolling.
 * Uses useInfiniteQuery to handle loading more data as the user scrolls.
 *
 * @param {Object} queryParams - The query parameters for fetching organizational units.
 * @param {Object} options - Additional options for the useInfiniteQuery hook.
 * @returns {UseInfiniteQueryResult} The result of the useInfiniteQuery hook.
 */
export const useQueryOrganizationalUnitsInfinite = (
  queryParams: Partial<OrganizationalUnitsQueryParams>,
  options: InfiniteQueryOptionsType<
    OrganizationalUnitsResponse | undefined,
    Error
  >,
) => {
  const qp = {
    ...defaultQueryParams,
    ...queryParams,
  };

  const infiniteQueryOptions = {
    queryFn: async ({ pageParam }) =>
      fetchOrganizationalUnits({
        ...qp,
        offset: pageParam,
      }),
    queryKey: queryKeysOrganizationalUnit.getAllInfinite(queryParams),
    ...options,
  };

  return createInfiniteQuery<OrganizationalUnitsResponse | undefined>(
    qp,
    infiniteQueryOptions,
  );
};
