import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import Log from '~/utils/logging';

const i18nMap = {
  arrived: 'Übergabe',
  cancelled: 'Storniert',
  default: 'Nicht gefunden',
  delivered: 'Geliefert',
  imported: 'Importiert',
  inTransport: 'In Transport',
  readyForOutput: 'Ausgabe',
  settled: 'Geliefert', // Deprecated: only used in old dlns
} as const;

export const calculateProcessState = (assetMain: any) => {
  if (!assetMain?.processState) {
    return i18nMap.default;
  }

  const processState = assetMain?.processState;

  if (processState === 'settled') {
    // Move status 'settled' away from process state
    return i18nMap.delivered;
  }

  const status = i18nMap[processState];

  if (!status) {
    Log.error(
      null,
      new EnumValueNotFoundException(`Invalid process state: ${processState}`),
    );

    return i18nMap.default;
  }

  return status;
};
