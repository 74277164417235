import { ComponentTestIds } from '~/constants/test-ids';

import { Spinner } from '~/ui/atoms/Spinner';

export const LoadingState = ({ title = 'Lade Daten' }) => (
  <div
    data-testid={ComponentTestIds.TABLE.LOADING}
    className="flex h-full flex-col items-center justify-center"
  >
    <Spinner
      title={title}
      className="h-auto w-auto rounded-full bg-white/85 px-4 py-3 shadow-sm backdrop-blur-sm"
    />
  </div>
);
