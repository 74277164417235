import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import ms from 'ms';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasSyncGateway } from '~/services/kyClient';

import DeliveryNoteAction from '~/models/deliveries/DeliveryNoteAction';

import Log from '~/utils/logging';

import { queryKeysDeliveryNote } from './queryKeys';

type SyncGatewayDocument = {
  assetId?: string;
  body?: {
    header?: {
      id: string;
    };
  };
  documentType: string;
  id: string;
  rev: string;
};

type SyncGatewayResponse = {
  lastSeq: number;
  results: Array<{
    doc: SyncGatewayDocument;
    seq: string;
  }>;
};

type DeliveryNoteChange = {
  dlns: Array<{
    dlnNr: string;
    id: string;
  }>;
  message: string;
};

type ProcessedChanges = {
  assetMains: SyncGatewayDocument[];
  dlnChanges: DeliveryNoteChange[];
  lastSequence: number;
};

/**
 * Processes the sync gateway response and extracts the relevant information.
 * Filters and groups delivery note changes by message type and tracks the last sequence number.
 *
 * @param data - The raw response from the sync gateway containing change results
 * @returns ProcessedChanges object containing filtered asset changes and last sequence number
 * @throws Error if processing fails
 */
const selectSyncGatewayChanges = (
  data: SyncGatewayResponse,
): ProcessedChanges => {
  try {
    const dlnChanges = data.results?.reduce<DeliveryNoteChange[]>(
      (changes, item) => {
        if (!item) {
          return changes;
        }

        const isAssetChain = item.doc?.documentType === 'asset_chain';
        const isLatestRevision = item.doc?.rev?.startsWith('1-');
        const isCompleteChange = !item.seq?.toString().includes(':');

        if (isAssetChain && isLatestRevision && isCompleteChange) {
          try {
            const message = new DeliveryNoteAction(item.doc).action;
            const id = item.doc.assetId ?? item.doc.id;
            const dlnNr = item.doc.body?.header?.id;

            if (!dlnNr) {
              return changes;
            }

            const existingChange = changes.find(
              (change) => Boolean(change.message) && change.message === message,
            );

            const changedAsset = { dlnNr, id };

            if (existingChange) {
              existingChange.dlns.push(changedAsset);
            } else {
              changes.push({
                dlns: [changedAsset],
                message,
              });
            }
          } catch (error) {
            Log.error('Error processing delivery note action', error, item.doc);
          }
        }

        return changes;
      },
      [],
    );

    return {
      dlnChanges,
      lastSequence: data.lastSeq,
    };
  } catch (error) {
    Log.error('Error processing sync gateway changes', error);

    throw new Error('Failed to process sync gateway changes');
  }
};

/**
 * Fetches delivery note changes from the sync gateway since a given timestamp.
 *
 * @param changesSequenceNumber - Optional timestamp to fetch changes since
 * @params signal - AbortSignal to cancel the request
 * @returns Promise resolving to the sync gateway response
 * @throws Error if the fetch fails or response format is invalid
 */
const fetchSyncGatewayChanges = async (
  changesSequenceNumber?: number,
  signal?: AbortSignal,
): Promise<SyncGatewayResponse> => {
  try {
    const response = await vestigasSyncGateway
      .get(
        ENDPOINT.DELIVERY_NOTE.GET_SYNC_GATEWAY_CHANGES(changesSequenceNumber),
        {
          signal,
        },
      )
      .json<SyncGatewayResponse>();

    if (!response || !Array.isArray(response.results)) {
      throw new Error('Invalid response format from sync gateway');
    }

    return response;
  } catch (error) {
    if (error instanceof DOMException && error.name === 'AbortError') {
      // Return an empty response structure for aborted requests.
      return {
        lastSeq: changesSequenceNumber ?? 0,
        results: [],
      };
    }

    Log.error('Error fetching delivery note updates:', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom React Query hook for polling delivery note updates from the Vestigas sync gateway.
 *
 * @param changesSequenceNumber - sequence number to fetch changes newer than this sequence
 * @param options - Additional React Query options to customize the query behavior
 * @returns React Query result object containing the processed changes data
 */
export const useQueryPollDeliveryNoteUpdates = (
  changesSequenceNumber: number | undefined,
  options?: Omit<UseQueryOptions<ProcessedChanges>, 'queryKey' | 'queryFn'>,
) =>
  useQuery({
    enabled: Boolean(changesSequenceNumber),
    gcTime: 0,
    queryFn: async ({ signal }) =>
      fetchSyncGatewayChanges(changesSequenceNumber, signal),
    queryKey: queryKeysDeliveryNote.getSyncGatewayChanges(
      changesSequenceNumber,
    ),
    refetchInterval: Infinity, // Never refetch - we are using a longpoll connection that remains open
    refetchIntervalInBackground: false,
    select: selectSyncGatewayChanges,
    staleTime: Infinity,
    ...options,
  });
