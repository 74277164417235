import { Checkbox } from '@mui/material';

import { useQueryCompanyLogo } from '~/data/company';

import DataExchangeCompany from '~/models/dataExchanges/DataExchangeCompany';

import { LightTooltipWide } from '~/utils/componentUtils';

import { withErrorBoundary } from '~/ui/atoms';
import { Spinner } from '~/ui/atoms/Spinner';

import { DataExchangeStatusIcon } from './DataExchangeStatusIcon';
import { DataExchangeStatusTooltip } from './DataExchangeStatusTooltip';

export const DataExchangeTile = withErrorBoundary(
  ({
    dataExchange,
    onChangeRequestInformationSelection,
    requestInformationSelection,
    requestInformationSelectionList,
  }) => {
    const {
      data: logo,
      isLoading,
      isError,
    } = useQueryCompanyLogo(dataExchange.senderId);

    const getLogo = () => {
      if (isLoading) {
        return <Spinner />;
      }

      if (isError) {
        return 'Logo konnte nicht geladen werden';
      }

      if (!logo) {
        return null;
      }

      return <img className="max-h-12 max-w-48" src={logo} />;
    };

    const getFlag = () => {
      if (!dataExchange.currentIssues) {
        return null;
      }

      return (
        <DataExchangeStatusTooltip
          status={DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY}
        >
          <div className="-t-4 r-0 flag-error absolute flex h-8 w-6 items-center justify-center">
            <DataExchangeStatusIcon
              status={DataExchangeCompany.STATUS.CURRENT_ISSUES.KEY}
            />
          </div>
        </DataExchangeStatusTooltip>
      );
    };

    const getFurtherInformation = () => {
      if (!dataExchange.furtherInformation) {
        return null;
      }

      return (
        <div className="mt-4 text-gray-400">
          {dataExchange.furtherInformation}
        </div>
      );
    };

    const getAdditionalData = () => {
      const rows = [];

      if (dataExchange.activeCustomerIds) {
        rows.push({
          title: 'Kundennummern:',
          value: dataExchange.activeCustomerIds,
        });
      }

      if (dataExchange.knownIssues) {
        rows.push({
          title: 'Bekannte Probleme:',
          value: dataExchange.knownIssues,
        });
      }

      if (rows.length === 0) {
        return null;
      }

      return rows.map((row) => (
        <div key={row.value} className="mt-4 border-t pt-4">
          <div className="mb-2 font-bold">{row.title}</div>
          <div>{row.value}</div>
        </div>
      ));
    };

    const getDocumentStatusColorClass = (status) => {
      if (
        status === DataExchangeCompany.STATUS.ACTIVATED.KEY ||
        status === DataExchangeCompany.STATUS.LIVE.KEY ||
        status === DataExchangeCompany.STATUS.QR_CODE.KEY
      ) {
        return 'text-successDark';
      }

      return null;
    };

    return (
      <div className="relative">
        {getFlag()}
        <div className="flex justify-between">
          <div className="mb-4 flex h-12 items-center pr-8">{getLogo()}</div>
          {requestInformationSelection && (
            <Checkbox
              checked={requestInformationSelectionList.includes(
                dataExchange.senderId,
              )}
              onChange={() =>
                onChangeRequestInformationSelection(dataExchange.senderId)
              }
            />
          )}
        </div>
        <LightTooltipWide
          title={
            <div>
              <div className="font-bold">{dataExchange.name}</div>
              <div>{dataExchange.address}</div>
              <div className="mt-2">VESTIGAS-ID:</div>
              <div className="font-bold">{dataExchange.senderId}</div>
            </div>
          }
          enterDelay={1000}
          enterNextDelay={1000}
        >
          <div className="mb-2 truncate text-gray-400">{dataExchange.name}</div>
        </LightTooltipWide>
        <div className="mb-4 h-px bg-gray-100" />
        <div className="mb-2 flex gap-2 rounded-lg bg-gray-100 p-3">
          <span>Lieferungen</span>
          <DataExchangeStatusTooltip status={dataExchange.deliveryNoteStatus}>
            <span
              className={
                'font-bold ' +
                getDocumentStatusColorClass(dataExchange.deliveryNoteStatus)
              }
            >
              {DataExchangeCompany.getStatusAbbreviatedLabel(
                dataExchange.deliveryNoteStatus,
              )}
            </span>
          </DataExchangeStatusTooltip>
        </div>
        <div className="mb-2 flex gap-2 rounded-lg bg-gray-100 p-3">
          <span>Rechnungen</span>
          <DataExchangeStatusTooltip status={dataExchange.invoiceStatus}>
            <span
              className={
                'font-bold ' +
                getDocumentStatusColorClass(dataExchange.invoiceStatus)
              }
            >
              {DataExchangeCompany.getStatusAbbreviatedLabel(
                dataExchange.invoiceStatus,
              )}
            </span>
          </DataExchangeStatusTooltip>
        </div>
        {getFurtherInformation()}
        {getAdditionalData()}
      </div>
    );
  },
  'Daten konnte nicht geladen werden.',
);

DataExchangeTile.displayName = 'DataExchangeTile';
