export const CreateDeliveryOutlinedIcon = ({
  className,
  style,
}: ComponentStyling) => (
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 12.6586C3.25476 12.3952 2.5771 11.9887 2 11.4722V17H4C4 18.66 5.34 20 7 20C8.66 20 10 18.66 10 17H16C16 18.66 17.34 20 19 20C20.66 20 22 18.66 22 17H24V12L21 8H18V4H11.1973C11.5487 4.60738 11.7966 5.28208 11.917 6H16V15H9.24C8.69 14.39 7.89 14 7 14C6.11 14 5.31 14.39 4.76 15H4V12.6586ZM20.5 17C20.5 17.83 19.83 18.5 19 18.5C18.17 18.5 17.5 17.83 17.5 17C17.5 16.17 18.17 15.5 19 15.5C19.83 15.5 20.5 16.17 20.5 17ZM18 9.5H20.5L22.46 12H18V9.5ZM8.5 17C8.5 17.83 7.83 18.5 7 18.5C6.17 18.5 5.5 17.83 5.5 17C5.5 16.17 6.17 15.5 7 15.5C7.83 15.5 8.5 16.17 8.5 17Z"
      fill="currentColor"
    />
    <path
      d="M5.42857 11V7.57143H2V6.42857H5.42857V3H6.57143V6.42857H10V7.57143H6.57143V11H5.42857Z"
      fill="currentColor"
    />
  </svg>
);
