import { Skeleton, type SkeletonProps } from '@mui/material';

type P = {
  readonly height?: number;
  readonly variant?: SkeletonProps['variant'];
  readonly width?: SkeletonProps['width'];
} & ComponentStyling;

export const SkeletonGridCell = ({
  className,
  height = 14,
  style,
  variant = 'text',
  width,
}: P) => (
  <div className="flex h-full items-center">
    <Skeleton
      className={className}
      height={height}
      style={style}
      variant={variant}
      width={width}
    />
  </div>
);
