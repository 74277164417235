import { difference } from '~/utils/array';

import Address from './Address';
import PermissionGrant from './PermissionGrant';
import TradeContact from './TradeContact';
import UniversalCommunication from './UniversalCommunication';

export default class Company {
  constructor(legalOrganization, tradeContact, additionalDataInitiated) {
    this.id = legalOrganization?.id ?? '';
    this.name = legalOrganization?.name ?? '';
    this.companyAccount =
      legalOrganization?.company_account_id ??
      legalOrganization?.companyAccountId ??
      '';
    this.address = new Address(legalOrganization?.address);
    this.universalCommunication = new UniversalCommunication(
      legalOrganization?.universal_communication ??
        legalOrganization?.universalCommunication,
    );
    this.tradeContact = new TradeContact(tradeContact);

    this.organisationalGroups =
      legalOrganization?.org_units ?? legalOrganization?.orgUnits ?? []; // deprecated - use parentOrganizationalUnits instead
    this.parentOrganizationalUnits =
      legalOrganization?.parent_organizational_units ??
      legalOrganization?.parentOrganizationalUnits ??
      [];
    this.organisationalGroupPaths =
      legalOrganization?.org_unit_paths ??
      legalOrganization?.orgUnitPaths ??
      []; // deprecated - use parentOrganizationalUnitPaths instead
    this.parentOrganizationalUnitPaths =
      legalOrganization?.parentOrganizationalUnitPaths ?? [];

    this.permissionGrantsFrom =
      (
        legalOrganization?.permissions_from ??
        legalOrganization?.permissionsFrom
      )?.map(
        (permissionGrant) =>
          new PermissionGrant({
            ...permissionGrant,
            targetId: this.id,
            targetType: PermissionGrant.ENTITY_TYPE.COMPANY.KEY,
          }),
      ) ?? [];

    // This variable is used to track whether additional data such as permissions and paths are already initiated.
    // In the future, this data will not be provided anymore by the bulk endpoint and must be loaded separately when the user opens the respective master data form.
    this.additionalDataInitiated = additionalDataInitiated;

    // Workaround: Prevent the paths to be loaded when the GET /all endpoint is called as the paths are currently still provided and cause visual bugs.
    if (!this.additionalDataInitiated) {
      this.organisationalGroupPaths = [];
    }

    this.fixParserErrors();
  }

  fixParserErrors() {
    this.name = this.name.includes('Undifinied') ? '' : this.name;
  }

  getBackendFormat() {
    const id = this.id ? this.id : null;
    const companyAccount = this.companyAccount ? this.companyAccount : null;

    return {
      id,
      legal_organization: {
        address: this.address.getBackendFormat(),
        company_account_id: companyAccount,
        id,
        name: this.name,
      },
    };
  }

  static getDifferentValues(companyA, companyB) {
    const differentValues = [];

    if (companyA?.id !== companyB?.id) {
      differentValues.push('ID');
    }

    if (companyA?.name !== companyB?.name) {
      differentValues.push('Name');
    }

    if (
      Address.getDifferentValues(companyA?.address, companyB?.address).length >
      0
    ) {
      differentValues.push('Adresse');
    }

    // Difference check for universal communication is not needed currently as it can't be specified in company form.

    const differentOrganisationalGroups = difference(
      companyA?.organisationalGroups,
      companyB?.organisationalGroups,
    );
    if (
      differentOrganisationalGroups[0].length > 0 ||
      differentOrganisationalGroups[1].length > 0
    ) {
      differentValues.push('Organisations-Gruppen');
    }

    return differentValues;
  }
}
